<template>
    <div>

        <div v-if="accommodation.modalidad_tiempo === 'diario'">
            <strong class="price">{{ dailyPrice | convertAndFormatPrice }}</strong>
            <span class="rental-mode ml-2">{{ $t("reservation.per-day") }}</span>
        </div>

        <div v-if="accommodation.modalidad_tiempo === 'mensual'">
            <strong class="price">{{ monthlyPrice | convertAndFormatPrice }}</strong>
            <span class="rental-mode ml-2">{{ $t("reservation.per-month") }}</span>
        </div>

        <div v-if="accommodation.modalidad_tiempo === 'ambos'">
            <strong class="price">{{ monthlyPrice | convertAndFormatPrice }}</strong>
            <span class="rental-mode ml-2">{{ $t("reservation.per-month") }}</span>
            <br/>
            <span class="reservation-per-day">
            <span class="rental-mode">* {{ dailyPrice | convertAndFormatPrice }}</span>
            <span class="rental-mode ml-2">{{ $t("reservation.per-day") }}</span>
        </span>
        </div>

    </div>    
</template>

<script>
export default {

    name: "Price",
    
    props: {
        accommodation: Object,
        dailyPrice: Object,
        monthlyPrice: Object
    }

};
</script>

<style lang="scss" scoped>
.price {
    font-size: 1.3rem;
}

.rental-mode {
    font-size: 1.1rem;
}
</style>