<template>
	<div>
		
        <div class="banner-container">
			<div class="img">
				<img :src="image" alt="" />
			</div>

            <!-- Desktop search menu -->
            <div v-if="!isMobile" class="row">
                <div class="col column-left">
                    <div class="banner-container__search">
						<Search />
					</div>
				</div>

				<div class="col column-right">
					<div class="title">{{ $t("banner.title") }}</div>
					<div class="subtitle">{{ $t("banner.subtitle") }}</div>
				</div>
			</div>
		</div>
        
        <!-- Mobile search menu -->
		<div v-if="isMobile" class="mobile-items">
			<Search />
            <hr class="mt-5">
		</div>
        
	</div>
</template>

<script>
import deviceMixin from "@/mixins/deviceMixin";
import Search from "@/components/Banner/Search";
import bg1 from "../../assets/bg1.jpg";
import bg2 from "../../assets/bg2.jpg";
import bg3 from "../../assets/bg3.jpg";

export default {
	name: "Banner",
	components: {
		Search,
	},
    mixins: [ 
        deviceMixin
    ],
	data() {
		return {
			image: "",
		};
	},
	created() {
		const imgs = [bg1, bg2, bg3];
		let rnd = Math.floor(Math.random() * 3);
		this.image = imgs[rnd];
	},
};
</script>

<style scoped lang="scss">
.banner-container {
	background-size: cover;
	height: 550px;
	position: relative;

	.img {
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			height: 100%;
		}
	}

	@media (max-width: 600px) {
		.img {
			display: flex;
			justify-content: flex-end;
			img {
				width: 1200px;
			}
		}
	}

	&__search {
		background-color: white;
		border-radius: 8px;
		padding: 20px;
		width: 100%;
	}

	.row {
		width: 100%;
		padding: 0;
		margin: 0;
		height: 100%;
	}

	.column-left {
        width: 50%;
        padding-left: 5%;
        padding-right: 5%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		height: 100%;
        padding: 0 130px;
		@media (max-width: 767px) {
			display: none;
		}
        @media (max-width: 992px) {
			padding: 0 10px;
		}
	}

	.column-right {
		padding: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		top: 0;
		width: 50%;
		right: 0;
		height: 100%;

		@media (max-width: 540px) {
			width: 100%;
		}
	}

	.title {
        backdrop-filter: brightness(0.7);
		font-weight: 900;
		font-size: 2.5em;
		color: white;
        padding: 10px; 

		@media (max-width: 500px) {
			font-size: 2em;
		}
	}

	.subtitle {
        backdrop-filter: brightness(0.7);
		font-weight: 400;
		color: white;
		font-size: 2em;
        padding: 10px; 

		@media (max-width: 500px) {
			font-size: 1.5em;
		}
	}

	@media (max-width: 767px) {
		height: 500px;
	}
	@media (max-width: 500px) {
		height: 300px;
	}
}

.mobile-items {
    padding: 15px;
    z-index: 1;
}
</style>