<template>
    <div class="container">
        <div class="top-info">{{$t('wizard.s9.your-commitment')}}</div>
        <hr />

        <div>{{$t('wizard.s9.commitment-info')}}:</div>

        <!-- <label class="toggleS mt-3">
            <input
                class="toggleS__input"
                type="checkbox"
                id="checkbox.r1"
                v-model="accommodation.caracteristicas.r1"
            />
            <span class="toggleS__label">
                <span class="toggleS__text">
                    <span v-html="$t('wizard.s9.text1')"></span>
                </span>
            </span>
        </label>
        <hr/> -->

        <label class="toggleS mt-3">
            <input
                class="toggleS__input"
                type="checkbox"
                id="checkbox.r2"
                v-model="accommodation.caracteristicas.r2"
            />
            <span class="toggleS__label">
                <span class="toggleS__text">
                    <span v-html="$t('wizard.s9.text2')"></span>
                </span>
            </span>
        </label>
        <hr/>

        <label class="toggleS mt-3">
            <input
                class="toggleS__input"
                type="checkbox"
                id="checkbox.r3"
                v-model="accommodation.caracteristicas.r3"
            />
            <span class="toggleS__label">
                <span class="toggleS__text">
                    <span v-html="$t('wizard.s9.text3')"></span>
                </span>
            </span>
        </label>
        <hr/>

        <label class="toggleS mt-3">
            <input
                class="toggleS__input"
                type="checkbox"
                id="checkbox.r4"
                v-model="accommodation.caracteristicas.r4"
            />
            <span class="toggleS__label">
                <span class="toggleS__text">
                    <a target="_blank" href='https://houseandflats.com/tyc'>
                        <span v-html="$t('wizard.s9.text5')" />
                    </a>
                </span>
            </span>
        </label>
        <hr/>
        <FooterWizard prev finish :validate="validate" />
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FooterWizard from './FooterWizard';
export default {
    name: "Step9Wizard",
    components: {
        FooterWizard
    },    
    data() {
        return {
            submitted: false,
            accommodation: {
                caracteristicas: {
                    r1: true,
                    r2: false,
                    r3: false,
                    r4: false,
                }
            }
        };
    },
    methods: {
        save() {            
            this.$emit('save');           
        },
        prevStep() {
            this.$emit('prev');
        }
    },
    computed: {
        validate: function() {
            let errors = 0;
            Object.keys(this.accommodation.caracteristicas).forEach((item) => {
                if(this.accommodation.caracteristicas[item] === false) {
                    errors++;
                }
            });
            return errors > 0 ? true : false;
        }
    },
    created() {
        window.scrollTo(0, 0);
    }
};
</script>

<style scoped lang="scss">
.top-info {
    padding-top: 10px;
}
</style>