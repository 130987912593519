<template>
    <div class="article-container">
        <a class="article-link" :href="link">
            <b-card :img-src="image" img-alt img-top tag="article">
                <div class="article-title">{{title}}</div>
                <div class="article-excerpt" v-html="descrip"></div>
                <div class="article-read-more">{{$t('blog.read-more')}}</div>
            </b-card>
        </a>
    </div>
</template>

<script>
import router from "@/router";
export default {
    props: ["link", "id", "title", "descrip", "image"],
    methods: {
        external_link(id) {
            router.push({ path: "blog/" + id });
        }
    }
};
</script>

<style scoped lang="scss">
.article-container {
    padding: 10px;
    height: 100%;
}

.article-link {
    text-decoration: none;
}

.article-excerpt {
    //font-weight: 100;
    color: #484848;
    padding-top: 20px;
}

.article-title {
    color: $hf-sec-pine;
    font-weight: 800;
    text-transform: uppercase;
    text-decoration: none;
}

.article-read-more {
    //font-weight: 100;
    color: $hf-sec-pine;
}

.card {
    img {
        object-fit: cover;
        height: 200px;
    }
}
</style>
