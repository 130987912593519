var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{staticClass:"price-details-modal",attrs:{"id":"modal-price-details","size":"md","ok-only":"","hide-header":""},on:{"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 text-center"},[_c('b-button',{staticClass:"ok-button",on:{"click":_vm.handleHidden}},[_vm._v(_vm._s(_vm.$t("simulator.accept")))])],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"price-details-modal__content"},[_c('h5',{staticClass:"price-details-modal__title"},[_vm._v(_vm._s(_vm.$t("simulator.payment-details-title")))]),_c('div',[(_vm.simulation.totalDays >= 30)?_c('p',[_vm._v("\n                "+_vm._s(_vm.$t('simulator.payment-details-message-monthly', {
                        finalPrice: _vm.finalPrice,
                        rentalWarranty: _vm.rentalWarranty,
                        rentalCommission: _vm.rentalCommission,
                    }))+"\n            ")]):_c('p',[_vm._v("\n                "+_vm._s(_vm.$t('simulator.payment-details-message-daily', {
                        finalPrice: _vm.finalPrice,
                        rentalStay: _vm.rentalStay,
                        rentalCommission: _vm.rentalCommission,
                    }))+"\n            ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }