<template>
    <div class="container">
        <div class="title">{{ $t('testimonials.customer-testimonials') }}</div>

        <Owl
            v-if="testimonialsData.length"
            :responsive="{0:{items:1}}"
            :autoplay="true"
            :autoplayTimeout="20000"
            :nav="false"
            :dots="true"
            
        >
            <div v-for="person in testimonialsData" :key="person.id">
                <Testimonial v-bind:data="person" />

                <div class="contact">
                    {{$t('testimonials.only-have-to')}}
                    <router-link :to="'/contacto'">
                        <span class="testimonials__link">{{$t('testimonials.contact')}}</span>
                    </router-link>
                    {{$t('testimonials.if-needed')}}
                </div>
            </div>
            
        </Owl>
    </div>
</template>

<script>
import Owl from "vue-owl-carousel";
import Testimonial from "./Testimonial";
import testimonialsData from "./testimonials-data.json";

export default {
    name: "Testimonials",
    data() {
        return {
            testimonialsData
        };
    },
    components: {
        Owl,
        Testimonial
    }
};
</script>


<style scoped lang="scss">
.container {
    margin-top: 0px;
    padding-bottom: 0px;

    &__link {
        color: $hf-sec-pine;
    }
    

    .contact {
        text-align: left;
        letter-spacing: 0;
        //font-weight: 50;
        color: #707070;
        padding: 10px 15px;
    }
}

.title {
    text-align: left;
    font-weight: 800;
    color: $hf-sec-pine;
    padding: 20px 0 20px 10px;
    font-size: 2rem;
}

</style>

<style lang="scss">
    button.owl-dot {
        margin: 10px 0 0 0 !important;
    }
</style>