<template>
    <div class="guests-container">

        <span class="label"> {{ $t("reservation.qty-guests") }} </span>

        <select class="form-control mobile-only" v-model="guest">
            <option v-for="n in 20" :key="n" :value="n">{{ n }}</option>
        </select>

        <b-dropdown class="desktop-only flex-fill" v-model="guest">
            <template v-slot:button-content>
                {{ guest }}
            </template>
            <b-dropdown-item v-for="n in 20" :key="n" @click="guest = n">{{ n }}</b-dropdown-item>
        </b-dropdown>

    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {

    name: "Guests",

    data() {
        return {
            guest: 1
        };
    },
    
    methods: {
    },
    
};
</script>

<style lang="scss" scoped>

.guests-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
}

.label {
    text-align: center;
    white-space: nowrap;
    margin-right: 20px;
}

.form-control {
    flex-grow: 1;
    text-align: right;
}

</style>

<style lang="scss" >

.btn.dropdown-toggle {
    background-color: white;
    color: $grey-75;
    border-color: $grey-10 !important;
}

</style>