<template>
    <div>
        <div class="row mt-5 p-5 shadow content-info">
            <div :class="data.iconClass + ' col-12 '">
                <i :class="data.icon" />
                <span v-if="language === 'es'">{{data.text.titulo.es}}</span>
                <span v-else>{{data.text.titulo.en}}</span>
            </div>
            <div class="col-12 mt-4" :class="data.class">
                <p v-if="language === 'es'">{{data.text.message.es}}</p>
                <p v-else>{{data.text.message.en}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["data", "language"],
    data() {
        return {};
    }
};
</script>

<style scoped>
.content-info {
    background-color: #ffffff;
    border-radius: 3px;
    transition: max-height 4s;
    line-height: 25px;
    margin: 200px 3px 3px 3px !important;
}
.danger {
    color: #e66465;
}
.suceso {
    color: #2b7f3e;

}
.svg-danger svg {
    font-size: 45px;
}

.svg-danger span {
    margin-left: 20px;
    font-size: 20px;
    position: absolute;
    top: 10px;
}

.suc {
    color: #2b7f3e;
}
.svg-suc svg {
    font-size: 45px;
}

.svg-suc span {
    margin-left: 20px;
    font-size: 20px;
    position: absolute;
    top: 10px;
}

.hyf-theme {
    color: #003D29;
}
</style>