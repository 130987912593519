<template>
    <!-- User and password form -->
    <div class="login-form">

        <!-- Email -->
        <ValidatedInput
            fieldName="email"
            v-model="formData.email"
            :label="$t('login.email')"
            :placeholder="$t('login.email-placeholder')"
            :validationObject="$v.formData.email"
            :apiError="getApiError('email')"
            @clear-error="clearFieldError"
        />

        <!-- Password -->
        <ValidatedInput
            type="password"
            fieldName="password"
            v-model="formData.password"
            :label="$t('login.password')"
            :placeholder="$t('login.password-placeholder')"
            :validationObject="$v.formData.password"
            :apiError="getApiError('password')"
            @clear-error="clearFieldError"
        />
        
        <!-- Forgot password -->
        <div class="forgot-password">
            <router-link :to="{ name: 'recuperar' }">{{$t('login.recovery')}}</router-link>
        </div>

        <!-- Submit button -->
        <div class="continue-button-div">
            <Button @click="handleSubmit" class="new-btn" variant="sec-pine">
                {{ $t('login.sign-in') }}
            </Button>
        </div>
        
        <!-- Register link -->
        <div class="register-link">
            {{$t('login.no-account')}}
            <router-link :to="{ name: 'register', params: { step: 1 } }">{{$t('login.register')}}</router-link>
        </div>

        <GoogleButton />

    </div>
</template>

<script>
import { mapActions } from "vuex";
import router from "@/router";

import GoogleButton from "@/pages/Registration/GoogleButton.vue";
import Button from "@/components/Ui/Button.vue";
import { validations } from "@/components/ValidatedFields/loginRules";
import ValidatedInput from "@/components/ValidatedFields/ValidatedInput.vue";
import {askMissingUserData, getKeyByValue} from "@/Utils/Utils";
import { redirectToSavedUrl } from "@/Utils/Utils";


export default {
    name: "Login",
	components: {
        ValidatedInput,
        Button,
        GoogleButton
	},
    validations: validations,
	data() {
		return {
            error: {
                // We need to define the error structure to keep reactivity working
                correo: '',
                hash: '',
            },
            formData: {
                email: '',
                password: ''
            },
            // Api keys are different from the front keys, so I map them
            fieldErrorMapping: {
                email: 'correo',
                password: 'hash'
            }
		};
	},
	methods: {
		...mapActions([
			"login",
			"createSession",
		]),

        getApiError(fieldName) {
            const apiFieldName = this.fieldErrorMapping[fieldName];
            return this.error[apiFieldName] || '';
        },

        clearFieldError(fieldName) {
            const apiFieldName = this.fieldErrorMapping[fieldName];
            if (apiFieldName) {
                this.error[apiFieldName] = '';
            }
        },
        
		async handleSubmit() {
            this.$v.$touch();

            // Don't submit if there are errors
            if (this.$v.$invalid) {
                return;
            }
            
			let data = {
				correo: this.formData.email,
				password: this.formData.password
			};

            try {
                const response = await this.login(data);
                await this.createSession(response);
                if(!askMissingUserData(response, this.$router)) {
                    redirectToSavedUrl(this.$router);
                }
            } catch (e) {
                this.handleError(e);
            }
		},

        handleError(error) {
            const errors = error.response.data;
            for (let item in errors) {
                if (errors.hasOwnProperty(item)) {
                    for(let i in errors[item]) {
                        this.error[item] = this.$t("validations.error-" + getKeyByValue(this.fieldErrorMapping, item) + "-" + errors[item][i]);
                    }
                }
            }
        }
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/new-design/styles";

.login-form {
    margin: auto;
    width: 100%;
    max-width: 400px;
    padding-top: 50px;
}

.forgot-password {
    padding-left: 10px;
}

.continue-button-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.validation-error {
    color: $hf-rufous;
    text-align: center;
    margin-bottom: 20px;
}

.register-link {
	text-align: center;
    padding-top: 50px;
}

</style>