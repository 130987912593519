<template>
	<div class="container">
		<div class="row justify-content-center mt-5">
			<div class="col-12 col-md-8">
				<select name="options" v-model="option" class="form-control">
					<option value disabled>Elige una opcion...</option>
					<option value="guests">Usuarios</option>
					<option value="hosts">Anfitrión - Todos</option>
					<option value="approved">Anfitrión - Alojamientos Aprobados</option>
					<option value="eliminated"> Anfitrión - Alojamientos Eliminados</option>
					<option value="waiting"> Anfitrión - Alojamientos Esperando</option>
					<option value="reservations">Reservas</option>
				</select>
			</div>
			<div class="col-12 mt-5 text-center">
				<button class="btn btn-primary" :disabled="option === '' || loading" @click="handleRequest"><b-spinner variant="light" label="Spinning" v-if="loading" style="width:20px; height:20px" /> Download</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default {
	data() {
		return {
			option: "",
			data: [],
			name: "",
			loading: false
		};
	},
	methods: {
		getAccommodations() {
			this.loading = true;
			axios
				.get(`${process.env.VUE_APP_API_URL}admin/get-accommodations`)
				.then(response => {
					this.exportToXlsx(response.data, "Anfitrion");
				});
		},

		getAccommodationsApproved() {
			this.loading = true;
			axios
				.get(
					`${process.env.VUE_APP_API_URL}admin/get-accommodations-approved`
				)
				.then(response => {
					this.exportToXlsx(response.data, "Anfitrion - Alojamientos Aprobados");
				});
		},

		getAccommodationsEliminated() {
			this.loading = true;
			axios
				.get(
					`${process.env.VUE_APP_API_URL}admin/get-accommodations-eliminated`
				)
				.then(response => {
					this.exportToXlsx(response.data, "Anfitrion - Alojamientos Eliminados");
				});
        },
        
        getAccommodationsWaiting() {
			this.loading = true;
			axios
				.get(
					`${process.env.VUE_APP_API_URL}admin/get-accommodations-waiting`
				)
				.then(response => {
					this.exportToXlsx(response.data, "Anfitrion - Alojamientos Aguardando");
				});
		},

		getReservations() {
			this.loading = true;
			axios
				.get(`${process.env.VUE_APP_API_URL}admin/get-reservations`)
				.then(response => {
                    this.exportToXlsx(response.data, "Reservas");
				});
		},
		getUsers() {
			this.loading = true;
			axios
				.get(`${process.env.VUE_APP_API_URL}admin/get-users`)
				.then(response => {
					this.exportToXlsx(response.data, "Usuarios");
				});
		},

		exportToXlsx(data, name) {
			const fileType =
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
			const fileExtension = ".xlsx";
			const fileName = name;
			const ws = XLSX.utils.json_to_sheet(data);
			const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
			const excelBuffer = XLSX.write(wb, {
				bookType: "xlsx",
				type: "array"
			});
			const fileRender = new Blob([excelBuffer], { type: fileType });
			FileSaver.saveAs(fileRender, fileName + fileExtension);
			this.loading = false;
        },
        
        handleRequest() {
            switch(this.option) {
				case 'guests':
                    this.getUsers();
                    break;
                case 'hosts':
                    this.getAccommodations();
                    break;
                case 'approved':
                    this.getAccommodationsApproved();
                    break;
                case 'eliminated':
                    this.getAccommodationsEliminated();
                    break;
                case 'waiting':
                    this.getAccommodationsWaiting();
                    break;
                case 'reservations':
                    this.getReservations();
                    break;
                default:
                    alert('Elija una opcion valida...');
            }
        }
	},
	created() {
		axios
			.get(`${process.env.VUE_APP_API_URL}admin/get-accommodations-approved`)
			.then(response => {
				console.log(response)
			});
	}
};
</script>