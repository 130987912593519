<template>
    <div v-html="faqContent"></div>
</template>

<script>

export default {
    data() {
        return {
            faqContent: '',
        };
    },
    mounted() {
        this.loadFaqContent();
    },
    methods: {
        loadFaqContent() {
            const lang = this.$i18n.locale;
            const faqFileName = `faq-${lang}.html`;

            this.faqContent = require(`./FaqContent/${faqFileName}`).default;
        },
    },
    watch: {
        '$i18n.locale': 'loadFaqContent',
    },
};
</script>

<style lang="scss" scoped>
.container {
    padding: 50px 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding-top: 20px;
        color: #003D29;
        font-weight: bold;
    }

    table {
        text-align: center;
        margin: 30px auto;
        th {
            background-color: rgb(211, 211, 211);
        }
    }
}
</style>