<template>
    <div class="container">
        
        <h1>{{$t('dashboard-side-menu.change-password')}}</h1>

        <!-- Actual password -->
        <div class="form-group">
            
            <!-- Label -->
            {{$t('change-password.current-password')}}
            
            <!-- Input field -->
            <input
                v-model="$v.user.contrasena.$model"
                type="password"
                class="form-control"
                :class="{'is-invalid': $v.user.contrasena.$error}"
            />
            
            <!-- Error messages -->
            <p
                class="text-danger error"
                v-if="$v.user.contrasena.$error && !$v.user.contrasena.required"
            >{{$t('change-password.field-required')}}</p>
        </div>

        <!-- New password -->
        <div class="form-group">
            
            <!-- Label -->
            {{$t('change-password.new-password')}}
            
            <!-- Input field -->
            <input
                v-model="$v.user.contrasenan.$model"
                type="password"
                class="form-control"
                :class="{'is-invalid': $v.user.contrasenan.$error}"
            />

            <!-- Error messages -->
            <p
                class="text-danger error"
                v-if="$v.user.contrasenan.$error && !$v.user.contrasenan.required"
            >{{$t('change-password.field-required')}}</p>
            <p
                class="text-danger error"
                v-if="$v.user.contrasenan.$error && !$v.user.contrasenan.strongPassword && $v.user.contrasenan.required"
            >{{$t('change-password.field-validation')}}</p>
        </div>

        <!-- Repeat password -->
        <div class="form-group">

            <!-- Label -->
            {{$t('change-password.repeat-new-password')}}
            
            <!-- Input field -->
            <input
                v-model="$v.user.contrasenar.$model"
                type="password"
                class="form-control"
                :class="{'is-invalid': $v.user.contrasenar.$error}"
            />

            <!-- Error messages -->
            <p
                class="text-danger error"
                v-if="$v.user.contrasenar.$error && !$v.user.contrasenar.required"
            >{{$t('change-password.field-required')}}</p>

            <p
                class="text-danger error"
                v-if="$v.user.contrasenar.$error && !$v.user.contrasenar.strongPassword && $v.user.contrasenar.required "
            >{{$t('change-password.passwords-same')}}</p>
        </div>

        <div class="btn-save">
            <button
                :disabled="disableSubmit"
                @click="actualizarPassword(user)"
                class="btn"
                id="change-password-button"
                type="submit"
            >{{$t('change-password.save')}}</button>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import {
    required,
    email,
    sameAs,
    alphaNum,
    minValue,
    minLength,
    maxLength,
    alpha,
    and,
    numeric
} from "vuelidate/lib/validators";

export default {
    name: "CambiarContrasena",
    data() {
        return {
            user: {
                contrasena: "",
                contrasenan: "",
                contrasenar: ""
            }
        };
    },
    validations: {
        user: {
            contrasena: {
                required
            },
            contrasenan: {
                required,
                strongPassword(new_password) {
                    return (
                        /[a-z]/.test(new_password) && //checks for a-z
                        /[0-9]/.test(new_password) && //checks for 0-9
                        new_password.length >= 6 &&
                        new_password.length <= 10 &&
                        /[A-Z]/.test(new_password) //checks for MAYUS
                    );
                }
            },
            contrasenar: {
                required,
                sameAsPassword: sameAs("contrasenan")
            }
        }
    },
    methods: {
        ...mapActions(["actualizarPassword"])
    },
    computed: {
        disableSubmit() {
            return (
                !this.$v.user.contrasena.required ||
                !this.$v.user.contrasenan.required ||
                !this.$v.user.contrasenar.required ||
                !this.$v.user.contrasenar.sameAsPassword ||
                this.$v.user.contrasena.$error ||
                this.$v.user.contrasenar.$erron ||
                this.$v.user.contrasenar.$error
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    h1 {
        color: $hf-sec-pine;
        font-size: 1.8em;
        border-bottom: solid rgb(231, 231, 231) 0.5px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        text-align: right;
    }

    input {
        height: 48px;
    }

    .btn-save {
        margin-top: 10px;
        text-align: right;
        button {
            color: white;
            background-color: $hf-sec-pine;
        }
    }
}
</style>


