<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center mt-5" v-if="this.recuperar.send==true">
                <h5>{{$t('recover.mail-sent')}}</h5>
            </div>
            <div class="col-12 col-md-8 mt-5" v-else>
            <h3>{{$t('recover.recover-password')}}</h3>
            <form @submit.prevent="enter()">
                <label for="exampleInputEmail1">{{$t('recover.email')}}</label>
                
                <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    :placeholder="$t('recover.enter-email')"
                    v-model="recuperar.email"
                />
                <small id="emailHelp">{{$t('recover.valid-email')}}</small>
                <div>
                    <small
                        class="text-danger"
                        v-if="this.recuperar.error!=false"
                    >{{$t('recover.invalid-email')}}</small>
                </div>
                <div>
                    <button type="submit" class="btn btn-primary" id="recover-password-button" :disabled="loading">
                        <b-spinner variant="light" label="Spinning" v-if="loading" style="width:18px; height:18px" />
                        {{$t('recover.send')}}
                    </button>
                </div>
            </form>
        </div>
        </div>
        
        
    </div>
</template>
<script>
import axios from "axios";
import router from "@/router";
export default {
    data() {
        return {
            recuperar: {
                email: "",
                send: false,
                error: false
            },
            loading: false,
        };
    },
    methods: {
        enter() {
            this.loading = true;
            this.recuperar.error = false;
            axios
                .post(
                    process.env.VUE_APP_API_URL + "recovery/password",
                    this.recuperar
                )
                .then(res => {
                    this.recuperar.send = res.data.send;
                    this.recuperar.error = false;
                    setTimeout(function() {
                        this.loading = false;
                        router.push({ name: "home" });
                    }, 3000);
                })
                .catch(error => {
                    this.loading = false;
                    this.recuperar.error = error.response.data.email[0];
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    .btn-primary {
        margin-top: 20px;

        &:disabled {
            background: #a6a6a6;
        }
    }
}
</style>