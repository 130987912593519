<template>
    <div class="testimonial">
        <div class="info col-xs-12 col-md-9">
            <div class="title">{{data.name}}</div>
            <div class="subtitle">{{ $t(data.subtitle) }}</div>
            <div class="content">{{ $t(data.info) }}</div>
        </div>
        <div class="image col-xs-12 col-md-3">
            <div class="image-container">
                <img :src="require(`@/assets/testimonials/${data.image}`)" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Testimonial",
    props: {
        data: Object
    }
};
</script>

<style scoped lang="scss">
.testimonial {
    display: flex;

    @media (max-width: 992px) {
        flex-direction: column-reverse;
        align-items: center;
    }

    .title {
        text-align: left;
        letter-spacing: 0;
        font-weight: 600;
        color: $hf-sec-pine;
        opacity: 1;
        font-size: 1.7rem;

        @media (max-width: 992px) {
            font-size: 22px;
            text-align: center;
            padding-top: 20px;
        }
    }

    .subtitle {
        text-align: left;
        letter-spacing: 0;
        //font-weight: 100;
        color: $hf-sec-pine;
        opacity: 1;
        font-size: 24px;
        @media (max-width: 992px) {
            font-size: 15px;
            text-align: center;
            padding-top: 10px;
        }
    }

    .content {
        text-align: left;
        color: #484848;
        //font-weight: 200;
        line-height: 1.5em;
        padding: 10px 0;
    }

    .image-container {
        width: 180px;
        height: 180px;
        overflow: hidden;
        border-radius: 50%;
        margin: auto;
    }
}
</style>