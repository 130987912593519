<template>
    <div class="spinner-container" :class="{'backdrop' : backdrop }">
        <div class="spinners">
            <b-spinner v-if="type == 'grow'" type='grow' />
            <b-spinner v-if="type == 'grow'" type='grow' />
            <b-spinner v-if="type == 'grow'" type='grow' />
            <b-spinner v-if="type == 'default'" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'tinySpinner',
    props: {
        backdrop: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: 'grow'
        }
    }
}
</script>

<style lang="scss" scoped>
.spinner-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}

.backdrop {
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 4;
}
</style>