<template>
	<div class="input-file-ics">
		<div>
			<input type="file" class="input" @change="handleChange" :id="accommodation.idalojamiento" />
			<div class="fake-button">Upload ICS</div>
		</div>
	</div>
</template>

<script>
export default {
    name: "UploaderIcs",
    props: ['accommodation'],
	methods: {
		handleChange({ target }) {
			if (target.files.length > 0) {
				const name = target.files[0].name;
				const ext = name.substr(name.length - 3);
				if (ext != "ics") {
                    alert("Formato invalido!");
                    this.icalendar = ''
					return;
				}

                const fr = new FileReader();

                const that = this;
                
				fr.onload = function () {
                    that.$emit('icsfile', {
                        ical: fr.result,
                        id: that.accommodation.idalojamiento,
                        name: JSON.parse(that.accommodation.nombre)[that.$i18n.locale]
                    });
                    console.log('TRUE')
				};

				fr.readAsText(target.files[0]);
			}
        },
        clearInput: function()
        {
            document.getElementById(this.accommodation.idalojamiento).value = ""
        }
	},
};
</script>

<style lang="scss" scoped>
.input-file-ics {
	width: 150px;
	overflow: hidden;
	text-align: center;
	position: relative;
	z-index: 100;
	background: transparent;

	.input {
		opacity: 0;
		position: absolute;
		left: 0;
		top: 0;
	}

	.fake-button {
		border: 1px solid #9e9e9e;
		border-radius: 5px;
		padding: 3px 8px;
		margin: 0 5px !important;
		background: #efefef;
        z-index: -1;
	}
}
</style>