<template>
        <div v-html="tycContent"></div>
</template>

<script>

export default {
    data() {
        return {
            tycContent: '',
        };
    },
    mounted() {
        this.loadTycContent();
    },
    methods: {
        loadTycContent() {
            const lang = this.$i18n.locale;
            const tycFileName = `tyc-${lang}.html`;

            this.tycContent = require(`./TycContent/${tycFileName}`).default;
        },
    },
    watch: {
        '$i18n.locale': 'loadTycContent',
    },
};
</script>


<style lang="scss" scoped>
.terms-container {
    padding: 50px 0;
    h2,
    h3 {
        padding-top: 20px;
        color: #003D29;
        font-weight: bold;
    }

    table {
        text-align: center;
        margin: 30px auto;
        th {
            background-color: rgb(211, 211, 211);
        }
    }
}
</style>