export const locationsList = [
    // Argentina
    {
        comuna: "Buenos Aires, Toda la ciudad",
        long_name: "Buenos Aires, Toda la ciudad",
        country: "Argentina",
        latitude: -34.6036844,
        longitude: -58.3815591,
        northeast: {
            lat: -34.52654636106313,
            lng: -58.33514472475672
        },
        southwest: {
            lat: -34.70510112811848,
            lng: -58.53145220760162
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Buenos Aires, Almagro",
        long_name: "Buenos Aires, Almagro",
        country: "Argentina",
        latitude: -34.6114447,
        longitude: -58.4209936,
        northeast: {
            lat: -34.59770274704947,
            lng: -58.4119189485255
        },
        southwest: {
            lat: -34.62207434590157,
            lng: -58.43333402722834
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Belgrano",
        long_name: "Buenos Aires, Belgrano",
        country: "Argentina",
        latitude: -34.5620844,
        longitude: -58.4566864,
        northeast: {
            lat: -34.5359311016451,
            lng: -58.42820373959617
        },
        southwest: {
            lat: -34.57500839276119,
            lng: -58.47336842447922
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Boedo",
        long_name: "Buenos Aires, Boedo",
        country: "Argentina",
        latitude: -34.6305379,
        longitude: -58.4192214,
        northeast: {
            lat: -34.62064223061657,
            lng: -58.41048865654462
        },
        southwest: {
            lat: -34.64020022426429,
            lng: -58.42779529821492
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Caballito",
        long_name: "Buenos Aires, Caballito",
        country: "Argentina",
        latitude: -34.6159245,
        longitude: -58.4406027,
        northeast: {
            lat: -34.60267336034419,
            lng: -58.42675582585225
        },
        southwest: {
            lat: -34.63063906647759,
            lng: -58.46270463067378
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Chacarita",
        long_name: "Buenos Aires, Chacarita",
        country: "Argentina",
        latitude: -34.5859985,
        longitude: -58.45443629999999,
        northeast: {
            lat: -34.57829453869661,
            lng: -58.43849764188068
        },
        southwest: {
            lat: -34.59783466793689,
            lng: -58.4669576235153
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Colegiales",
        long_name: "Buenos Aires, Colegiales",
        country: "Argentina",
        latitude: -34.5760225,
        longitude: -58.4483554,
        northeast: {
            lat: -34.5661081700822,
            lng: -58.43993891105978
        },
        southwest: {
            lat: -34.58319172322628,
            lng: -58.46335554961188
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Flores",
        long_name: "Buenos Aires, Flores",
        country: "Argentina",
        latitude: -34.6374837,
        longitude: -58.4601452,
        northeast: {
            lat: -34.61388557303465,
            lng: -58.43742236310418
        },
        southwest: {
            lat: -34.65684135871686,
            lng: -58.47780721653619
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, La Boca",
        long_name: "Buenos Aires, La Boca",
        country: "Argentina",
        latitude: -34.6344961,
        longitude: -58.36313370000001,
        northeast: {
            lat: -34.62500414702805,
            lng: -58.3527324774575
        },
        southwest: {
            lat: -34.65017266519008,
            lng: -58.37065825565539
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Nuñez",
        long_name: "Buenos Aires, Nuñez",
        country: "Argentina",
        latitude: -34.5447906,
        longitude: -58.4598789,
        northeast: {
            lat: -34.52654636106313,
            lng: -58.44643338341963
        },
        southwest: {
            lat: -34.55892837545181,
            lng: -58.47575577415701
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Palermo",
        long_name: "Buenos Aires, Palermo",
        country: "Argentina",
        latitude: -34.5780655,
        longitude: -58.4265317,
        northeast: {
            lat: -34.5490119771075,
            lng: -58.39298649435829
        },
        southwest: {
            lat: -34.59785472473616,
            lng: -58.44904437613813
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Parque Patricios",
        long_name: "Buenos Aires, Parque Patricios",
        country: "Argentina",
        latitude: -34.6362319,
        longitude: -58.4004841,
        northeast: {
            lat: -34.62725286228006,
            lng: -58.38988142140611
        },
        southwest: {
            lat: -34.64859488218676,
            lng: -58.41343650917931
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Puerto Madero",
        long_name: "Buenos Aires, Puerto Madero",
        country: "Argentina",
        latitude: -34.6177194,
        longitude: -58.3620561,
        northeast: {
            lat: -34.59467401608841,
            lng: -58.33514472475672
        },
        southwest: {
            lat: -34.63257327938826,
            lng: -58.36939299381784
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Recoleta",
        long_name: "Buenos Aires, Recoleta",
        country: "Argentina",
        latitude: -34.5873825,
        longitude: -58.39727620000001,
        northeast: {
            lat: -34.56989504893882,
            lng: -58.3766853295508
        },
        southwest: {
            lat: -34.59976039338088,
            lng: -58.41600042107622
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Retiro",
        long_name: "Buenos Aires, Retiro",
        country: "Argentina",
        latitude: -34.5896061,
        longitude: -58.3802473,
        northeast: {
            lat: -34.57950283306921,
            lng: -58.36566692499179
        },
        southwest: {
            lat: -34.59929285922635,
            lng: -58.38790973629558
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Saavedra",
        long_name: "Buenos Aires, Saavedra",
        country: "Argentina",
        latitude: -34.5545188,
        longitude: -58.4915986,
        northeast: {
            lat: -34.53881614175945,
            lng: -58.46818929604658
        },
        southwest: {
            lat: -34.56988232735495,
            lng: -58.51013033385347
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, San Telmo",
        long_name: "Buenos Aires, San Telmo",
        country: "Argentina",
        latitude: -34.6218351,
        longitude: -58.3713942,
        northeast: {
            lat: -34.61562665028076,
            lng: -58.36309091893586
        },
        southwest: {
            lat: -34.62949370416774,
            lng: -58.37742524370558
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Villa Crespo",
        long_name: "Buenos Aires, Villa Crespo",
        country: "Argentina",
        latitude: -34.5947209,
        longitude: -58.4442897,
        northeast: {
            lat: -34.58869051507484,
            lng: -58.42336245346457
        },
        southwest: {
            lat: -34.60761551591774,
            lng: -58.45893473989321
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Buenos Aires, Villa Urquiza",
        long_name: "Buenos Aires, Villa Urquiza",
        country: "Argentina",
        latitude: -34.5703983,
        longitude: -58.49138680000001,
        northeast: {
            lat: -34.55910422895678,
            lng: -58.46808126935032
        },
        southwest: {
            lat: -34.58481817124689,
            lng: -58.50941221855233
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Córdoba",
        long_name: "Córdoba",
        country: "Argentina",
        latitude: -31.42008329999999,
        longitude: -64.1887761,
        northeast: {
            lat: -31.30646103489714,
            lng: -64.06909932034236
        },
        southwest: {
            lat: -31.49244535901042,
            lng: -64.3193253509635
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Mendoza",
        long_name: "Mendoza",
        country: "Argentina",
        latitude: -32.8894587,
        longitude: -68.8458386,
        northeast: {
            lat: -32.8159953407433,
            lng: -68.6989778112199
        },
        southwest: {
            lat: -32.95121452510103,
            lng: -68.94840178772135
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Neuquén",
        long_name: "Neuquén",
        country: "Argentina",
        latitude: -38.9516784,
        longitude: -68.0591888,
        northeast: {
            lat: -38.89328005153885,
            lng: -68.00814816027368
        },
        southwest: {
            lat: -38.98918905814746,
            lng: -68.22265341538633
        },
        polygon: {
            draw: false
        }
    },
    // Chile
    {
        comuna: "Concepción",
        long_name: "Concepción",
        country: "Chile",
        latitude: -36.8201352,
        longitude: -73.0443904,
        northeast: {
            lat: -36.76354254601518,
            lng: -72.98195795982652
        },
        southwest: {
            lat: -36.88854165008797,
            lng: -73.08413320087756
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Santiago de Chile, Toda la ciudad",
        long_name: "Santiago de Chile, Toda la ciudad",
        country: "Chile",
        latitude: -33.4488897,
        longitude: -70.6692655,
        northeast: {
            lat: -33.27138735393422,
            lng: -70.42678074132388
        },
        southwest: {
            lat: -33.67418853776274,
            lng: -70.83288189033638
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Valparaíso",
        long_name: "Valparaíso",
        country: "Chile",
        latitude: -33.047238,
        longitude: -71.61268849999999,
        northeast: {
            lat: -33.01781650321404,
            lng: -71.55976843955561
        },
        southwest: {
            lat: -33.08258438867568,
            lng: -71.66232867881529
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Viña del Mar",
        long_name: "Viña del Mar",
        country: "Chile",
        latitude: -33.0153481,
        longitude: -71.55002759999999,
        northeast: {
            lat: -32.94845543653869,
            lng: -71.47230798146632
        },
        southwest: {
            lat: -33.06260243153779,
            lng: -71.58820312328348
        },
        polygon: {
            draw: false
        }
    },
    // España
    {
        comuna: "Alicante (Alacant)",
        long_name: "Alicante (Alacant)",
        country: "España",
        latitude: 38.3457685,
        longitude: -0.4909444,
        northeast: {
            lat: 38.39093284211179,
            lng: -0.4034190600039416
        },
        southwest: {
            lat: 38.32474877136838,
            lng: -0.5416292294459696
        },
        polygon: {
            draw: false
        }
    },
    // Barcelona
    {
        comuna: "Barcelona, Toda la ciudad",
        long_name: "Barcelona, Toda la ciudad",
        country: "España",
        latitude: 41.3873974,
        longitude: 2.168568,
        northeast: {
            lat: 41.4682974272428,
            lng: 2.22804492421789
        },
        southwest: {
            lat: 41.31703848925413,
            lng: 2.052333262952554
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Barcelona, Barrio Gòtico",
        long_name: "Barcelona, Barrio Gòtico",
        country: "España",
        latitude: 41.3819247,
        longitude: 2.1773052,
        northeast: {
            lat: 41.3884704503044,
            lng: 2.18499288523822
        },
        southwest: {
            lat: 41.37431452544303,
            lng: 2.169963258058956
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Barcelona, Eixample",
        long_name: "Barcelona, Eixample",
        country: "España",
        latitude: 41.3922586,
        longitude: 2.1650273,
        northeast: {
            lat: 41.41203643993794,
            lng: 2.186895379836445
        },
        southwest: {
            lat: 41.37497616158768,
            lng: 2.142302787122405
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Barcelona, El Poble-sec",
        long_name: "Barcelona, El Poble-sec",
        country: "España",
        latitude: 41.3725602,
        longitude: 2.1675332,
        northeast: {
            lat: 41.37500872056058,
            lng: 2.176302253314217
        },
        southwest: {
            lat: 41.36874607379767,
            lng: 2.153527809502968
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Barcelona, Les Corts",
        long_name: "Barcelona, Les Corts",
        country: "España",
        latitude: 41.38447,
        longitude: 2.1107283,
        northeast: {
            lat: 41.4011141071795,
            lng: 2.14434435000822
        },
        southwest: {
            lat: 41.3756736761727,
            lng: 2.097791852862739
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Barcelona, Sant Gervasi-La Bonanova",
        long_name: "Barcelona, Sant Gervasi-La Bonanova",
        country: "España",
        latitude: 41.411538,
        longitude: 2.1323095,
        northeast: {
            lat: 41.41993314975943,
            lng: 2.140734849506438
        },
        southwest: {
            lat: 41.39934117635848,
            lng: 2.118825718778936
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Barcelona, Sant Martí",
        long_name: "Barcelona, Sant Martí",
        country: "España",
        latitude: 41.4100098,
        longitude: 2.2029804,
        northeast: {
            lat: 41.43017620218451,
            lng: 2.22804492421789
        },
        southwest: {
            lat: 41.38335232726796,
            lng: 2.175467670142995
        },
        polygon: {
            draw: true
        }
    },
    
    {
        comuna: "Bilbao",
        long_name: "Bilbao",
        country: "España",
        latitude: 43.2630126,
        longitude: -2.9349852,
        northeast: {
            lat: 43.28914596491534,
            lng: -2.889762865922365
        },
        southwest: {
            lat: 43.23750095589353,
            lng: -2.977365936169626
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Burgos",
        long_name: "Burgos",
        country: "España",
        latitude: 42.3504659,
        longitude: -3.689354499999999,
        northeast: {
            lat: 42.37213414784006,
            lng: -3.636750412665408
        },
        southwest: {
            lat: 42.31611786603707,
            lng: -3.752635747130636
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Cartagena",
        long_name: "Cartagena",
        country: "España",
        latitude: 37.6253022,
        longitude: -0.9972219,
        northeast: {
            lat: 37.63971015232394,
            lng: -0.9642879391170617
        },
        southwest: {
            lat: 37.5951130761385,
            lng: -1.012726866200791
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Huelva",
        long_name: "Huelva",
        country: "España",
        latitude: 37.2619585,
        longitude: -6.9427327,
        northeast: {
            lat: 37.2913206816194,
            lng: -6.916788935877258
        },
        southwest: {
            lat: 37.1,
            lng: -7.5
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Logroño",
        long_name: "Logroño",
        country: "España",
        latitude: 42.4627195,
        longitude: -2.4449852,
        northeast: {
            lat: 42.48168460944743,
            lng: -2.411083724058472
        },
        southwest: {
            lat: 42.43890102668015,
            lng: -2.485641679308714
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Lugo",
        long_name: "Lugo",
        country: "España",
        latitude: 43.0097384,
        longitude: -7.5567582,
        northeast: {
            lat: 43.03479856030992,
            lng: -7.536812134553496
        },
        southwest: {
            lat: 42.9897686530195,
            lng: -7.57618807393249
        },
        polygon: {
            draw: false
        }
    },
    // Madrid
    {
        comuna: "Madrid, Toda la ciudad",
        long_name: "Madrid, Toda la ciudad",
        country: "España",
        latitude: 40.4167754,
        longitude: -3.7037902,
        northeast: {
            lat: 40.56384473341083,
            lng: -3.524911528167741
        },
        southwest: {
            lat: 40.31206394740597,
            lng: -3.834161809872056
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Madrid, Centro",
        long_name: "Madrid, Centro",
        country: "España",
        latitude: 40.4167234,
        longitude: -3.7033851,
        northeast: {
            lat: 40.43061606023851,
            lng: -3.690288963431012
        },
        southwest: {
            lat: 40.40504105097957,
            lng: -3.72287807086027
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Madrid, Chamartín",
        long_name: "Madrid, Chamartín",
        country: "España",
        latitude: 40.4615174,
        longitude: -3.6865844,
        northeast: {
            lat: 40.48406098931166,
            lng: -3.660459748722928
        },
        southwest: {
            lat: 40.43740197218633,
            lng: -3.691642947174351
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Madrid, Chamberí",
        long_name: "Madrid, Chamberí",
        country: "España",
        latitude: 40.4344286,
        longitude: -3.7131782,
        northeast: {
            lat: 40.44714298465772,
            lng: -3.688332051908287
        },
        southwest: {
            lat: 40.42525499283333,
            lng: -3.719855724175303
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Madrid, Retiro",
        long_name: "Madrid, Retiro",
        country: "España",
        latitude: 40.4113349,
        longitude: -3.6749081,
        northeast: {
            lat: 40.42170803907248,
            lng: -3.658618015710902
        },
        southwest: {
            lat: 40.39435987342883,
            lng: -3.693576918675043
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Madrid, Salamanca",
        long_name: "Madrid, Salamanca",
        country: "España",
        latitude: 40.4279488,
        longitude: -3.68675,
        northeast: {
            lat: 40.44464290492917,
            lng: -3.658618015710902
        },
        southwest: {
            lat: 40.41863194812891,
            lng: -3.692713960679216
        },
        polygon: {
            draw: true
        }
    },
    
    {
        comuna: "Málaga",
        long_name: "Málaga",
        country: "España",
        latitude: 36.721213,
        longitude: -4.4216879,
        northeast: {
            lat: 36.75755262609146,
            lng: -4.339496540648535
        },
        southwest: {
            lat: 36.67889138816003,
            lng: -4.559037314171312
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Oviedo",
        long_name: "Oviedo",
        country: "España",
        latitude: 43.3622522,
        longitude: -5.8485461,
        northeast: {
            lat: 43.39203855924637,
            lng: -5.812127239770163
        },
        southwest: {
            lat: 43.34693506623958,
            lng: -5.885188444013071
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Pamplona",
        long_name: "Pamplona",
        country: "España",
        latitude: 42.812526,
        longitude: -1.6457745,
        northeast: {
            lat: 42.83811756569991,
            lng: -1.604659160572587
        },
        southwest: {
            lat: 42.79341437009327,
            lng: -1.695384048112043
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Salamanca",
        long_name: "Salamanca",
        country: "España",
        latitude: 40.9701039,
        longitude: -5.663539699999999,
        northeast: {
            lat: 40.98509709418802,
            lng: -5.631203931940572
        },
        southwest: {
            lat: 40.94177994596159,
            lng: -5.707220262223757
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "San Sebastián",
        long_name: "San Sebastián",
        country: "España",
        latitude: 43.318334,
        longitude: -1.9812313,
        northeast: {
            lat: 43.32871430102026,
            lng: -1.924211465519547
        },
        southwest: {
            lat: 43.2858710377457,
            lng: -2.023554449750387
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Santander",
        long_name: "Santander",
        country: "España",
        latitude: 43.4636346,
        longitude: -3.8226402,
        northeast: {
            lat: 43.48639082374222,
            lng: -3.753610862903355
        },
        southwest: {
            lat: 43.43629802556797,
            lng: -3.868663249176218
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Sevilla",
        long_name: "Sevilla",
        country: "España",
        latitude: 37.3890924,
        longitude: -5.9844589,
        northeast: {
            lat: 37.43552116934161,
            lng: -5.888458652078246
        },
        southwest: {
            lat: 37.31522026882898,
            lng: -6.021657814478962
        },
        polygon: {
            draw: false
        }
    },
    // Valencia
    {
        comuna: "Valencia, Toda la ciudad",
        long_name: "Valencia, Toda la ciudad",
        country: "España",
        latitude: 39.4699075,
        longitude: -0.3762881,
        northeast: {
            lat: 39.5073224594516,
            lng: -0.2914778120622837
        },
        southwest: {
            lat: 39.30824799722067,
            lng: -0.4315448083676902
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Valencia, Ayora",
        long_name: "Valencia, Ayora",
        country: "España",
        latitude: 39.4665282,
        longitude: -0.3430688,
        northeast: {
            lat: 39.46972199221864,
            lng: -0.3353839679034252
        },
        southwest: {
            lat: 39.46176506550147,
            lng: -0.35129801636527
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Valencia, Camins al Grau",
        long_name: "Valencia, Camins al Grau",
        country: "España",
        latitude: 39.4626283,
        longitude: -0.3489496,
        northeast: {
            lat: 39.47147698763202,
            lng: -0.3353839679034252
        },
        southwest: {
            lat: 39.45377305841663,
            lng: -0.3609381645184877
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Valencia, Quatre Carreres",
        long_name: "Valencia, Quatre Carreres",
        country: "España",
        latitude: 39.44964909999999,
        longitude: -0.3607137,
        northeast: {
            lat: 39.46314689388979,
            lng: -0.3345368354468051
        },
        southwest: {
            lat: 39.42602901665821,
            lng: -0.3868677389683054
        },
        polygon: {
            draw: true
        }
    },
    {
        comuna: "Valencia, Poblats Maritims",
        long_name: "Valencia, Poblats Maritims",
        country: "España",
        latitude: 39.45232850000001,
        longitude: -0.3301342,
        northeast: {
            lat: 39.48589403033223,
            lng: -0.302151860409177
        },
        southwest: {
            lat: 39.42277194247347,
            lng: -0.3416658356009217
        },
        polygon: {
            draw: true
        }
    },
    // Perú
    {
        comuna: "Lima",
        long_name: "Lima",
        country: "Perú",
        latitude: -12.0463731,
        longitude: -77.042754,
        northeast: {
            lat: -11.79998745006646,
            lng: -76.78833965163773
        },
        southwest: {
            lat: -12.25328905340461,
            lng: -77.18721856505658
        },
        polygon: {
            draw: false
        }
    },
    // Portugal
    {
        comuna: "Braga",
        long_name: "Braga",
        country: "Portugal",
        latitude: 41.5454486,
        longitude: -8.426506999999999,
        northeast: {
            lat: 41.58414768853574,
            lng: -8.342179110418417
        },
        southwest: {
            lat: 41.51040203529377,
            lng: -8.480663286579226
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Covilhã",
        long_name: "Covilhã",
        country: "Portugal",
        latitude: 40.2805633,
        longitude: -7.504327199999999,
        northeast: {
            lat: 40.31497336750819,
            lng: -7.464640958209947
        },
        southwest: {
            lat: 40.256491553217,
            lng: -7.537834777061322
        },
        polygon: {
            draw: false
        }
    },
    {
        comuna: "Oporto",
        long_name: "Oporto",
        country: "Portugal",
        latitude: 41.1579438,
        longitude: -8.629105299999999,
        northeast: {
            lat: 41.18602848569317,
            lng: -8.552613373962807
        },
        southwest: {
            lat: 41.13835060908927,
            lng: -8.691293920826338
        },
        polygon: {
            draw: false
        }
    }

]