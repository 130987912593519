<template>
    <div class="container">
        <div class="top-info">{{$t('wizard.s4.features-info')}}:</div>
        <hr />
        <TopS4S7 :selected="4" :accommodationData="accommodationData" />
        <hr />

        <div class="row">
            <div class="col-md-4" v-for="(item, i) in s7Data" :key="`s7-${i}`">
                <div class="item">
                    <img :src="require(`@/assets/alojamiento/${item.icon}.svg`)" />
                    <div>{{$t(item.text)}}</div>
                    <button :id="`${item.field}`" type="button" @click="validar">
                        <i class="fas fa-chevron-down" />
                    </button>
                    <input
                        type="number"
                        name="number"
                        min="0"
                        max="100"
                        v-model="accommodation.caracteristicas[item.field]"
                    />
                    <button type="button" @click="accommodation.caracteristicas[item.field]++" :id="`${item.field}-more`">
                        <i class="fas fa-chevron-up" />
                    </button>
                </div>
            </div>
        </div>

        <div class="mt-4">{{$t('wizard.s7.about-us')}}</div>
        <textarea
            v-model="accommodation.caracteristicas.sobre_nosotros.value"
            :class="{'is-invalid': accommodation.caracteristicas.sobre_nosotros.error}"
            :placeholder="$t('wizard.s7.about-placeholder')"
            class="form-control about-us"
        />
        <p
            class="text-danger"
            v-if="accommodation.caracteristicas.sobre_nosotros.error"
        >{{$t('wizard.s7.field-required')}}</p>
        <hr/>
        <FooterWizard prev next />
    </div>
</template>

<script>
import s7Data from "./step7-data.json";
import TopS4S7 from "./TopS4S7";
import FooterWizard from './FooterWizard';
export default {
    name: "Step7Wizard",
    props: {
        data: {
            type: Object,
            default: null
        },
        accommodationData: {
            type: Object,
            default: () => { 
                return {
                    tipo: ''
                }
             }
        }
    },
    data() {
        return {
            s7Data,
            accommodation: {
                caracteristicas: {
                    hombres: 0,
                    mujeres: 0,
                    ninos: 0,
                    personal_servicio: 0,
                    tercera_edad: 0,
                    perros: 0,
                    gatos: 0,
                    otras_mascotas: 0,
                    sobre_nosotros: {
                        value: "",
                        required: true,
                        error: false,
                    }
                }
            }
        };
    },

    components: {
        TopS4S7,
        FooterWizard
    },

    methods: {
        validar(event) {
            const id = event.target.id;
            if (!this.accommodation.caracteristicas[id] <= 0) {
                this.accommodation.caracteristicas[id]--;
            }
        },
        validate() {
            let error = 0;
            this.accommodation.caracteristicas.sobre_nosotros.error = false;
            if(this.accommodation.caracteristicas.sobre_nosotros.value === "" || this.accommodation.caracteristicas.sobre_nosotros.value === null) {
                error++;
                this.accommodation.caracteristicas.sobre_nosotros.error = true;
            }
            return error;
        },
        nextStep() {
            if(!this.validate()) {
                let step = {};
                Object.keys(this.accommodation.caracteristicas).forEach((item) => {
                    if(item == 'sobre_nosotros') {
                        step[item] = this.accommodation.caracteristicas.sobre_nosotros.value;    
                    } else {
                        step[item] = this.accommodation.caracteristicas[item]; 
                    }
                })
                this.$emit('next', step);
            }
        },
        prevStep() {
            this.$emit('prev');
        }
    },

    created() {
        window.scrollTo(0, 0);
        if(this.data != null) {
            Object.keys(this.data).forEach((item) => {
                if(item == 'sobre_nosotros') {
                    let snosotros = JSON.parse(this.data[item]);
                    this.accommodation.caracteristicas[item].value = snosotros.es;
                }else{
                    this.accommodation.caracteristicas[item] = this.data[item];
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.top-info {
    padding-top: 10px;
}

.item {
    text-align: center;
    margin: 20px 0px;

    img {
        width: 40px;
    }
    button {
        width: 40px;
        border: none;
        background-color: white;
        outline: 0;
        margin: 0;
    }

    input {
        border: none;
        text-align: center;
        padding-left: 12px;
    }

    .fa-chevron-down {
        pointer-events: none;
    }
}

.about-us {
    height: 200px;
}
</style>