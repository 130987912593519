import axios from 'axios';
import * as types from './types';
import apiUrls from '@/constants/apiUrls';

export default {

    async fetchMadeReservations({ commit, state, dispatch }, { page, perPage, type }) {
        const url = `${apiUrls.reservations}?page=${page}&perPage=${perPage}&type=${type}`;

        const response = await axios.get(url);
        const madeReservations = response.data;

        dispatch('setMadeReservations', madeReservations);
    },

    setMadeReservations({ commit }, madeReservations) {
        commit(types.SET_MADE_RESERVATIONS, madeReservations);
    },
    
    async fetchReceivedReservations({ commit, state, dispatch }, { page, perPage }) {
        const url = `${apiUrls.getReceivedReservations}?page=${page}&perPage=${perPage}`;

        const response = await axios.get(url);
        const receivedReservations = response.data;

        dispatch('setReceivedReservations', receivedReservations);
    },
    
    setReceivedReservations({ commit }, receivedReservations) {
        commit(types.SET_RECEIVED_RESERVATIONS, receivedReservations);
    }

};
