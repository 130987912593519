const state = {
    checkInDate: null,
    checkOutDate: null,
    searchResults: {
        data: [],
        paginate: {
            total: 0,
            current_page: 1,
            per_page: 50,
        },
    },
    searchLocation: [],
    showMapOnMobile: false,
};

export default state;