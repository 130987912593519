<template>
	<div class="container">
		<div class="row justify-content-center mt-5" v-if="!cambiar.update">
			<div class="col-12 col-md-8" v-if="this.cambiar.token_check">
				<form @submit.prevent="sendForm()">
					<h3>{{$t('restore.set-new-password')}}</h3>
					<div class="form-group" :class="{ 'form-group--error': $v.cambiar.new_password.$error }">
						<label for="exampleInputEmail1">{{$t('restore.new-password')}}</label>
						<input
							type="password"
							class="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							v-model="$v.cambiar.new_password.$model"
							:class="{'is-invalid': $v.cambiar.new_password.$error}"
						/>
					</div>

					<p
						class="text-danger error"
						v-if="!$v.cambiar.new_password.required"
					>{{$t('restore.field-required')}}</p>

					<p
						class="text-danger error"
						v-if="!$v.cambiar.new_password.strongPassword"
					>{{$t('restore.field-validation')}}</p>

					<div class="form-group" :class="{ 'form-group--error': $v.cambiar.check_password.$error }">
						<label for="exampleInputEmail1">{{$t('restore.repeat-new-password')}}</label>
						<input
							type="password"
							class="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							v-model="$v.cambiar.check_password.$model"
							:class="{'is-invalid': $v.cambiar.check_password.$error}"
						/>
					</div>
					<p
						class="text-danger error"
						v-if="!$v.cambiar.check_password.required"
					>{{$t('restore.field-required')}}</p>
					<p
						class="text-danger error"
						v-if="!$v.cambiar.check_password.sameAsPassword"
					>{{$t('restore.passwords-same')}}</p>

					<small class="text-danger" v-if="this.cambiar.code_api !== false">{{this.cambiar.code_api}}</small>
					<small class="text-danger" v-if="this.cambiar.not_match==true">
						<label>{{$t('restore.passwords-same')}}</label>
					</small>
					<br />
					<button
						type="submit"
						class="btn btn-primary px-3"
						:disabled="!$v.cambiar.new_password.required ||!$v.cambiar.check_password.required ||!$v.cambiar.check_password.sameAsPassword || cambiar.sending || !$v.cambiar.new_password.strongPassword"
					>
						<b-spinner
							variant="light"
							label="Spinning"
							v-if="cambiar.sending"
							style="width:20px; height:20px; margin-right: 10px;"
						/>
						{{$t('restore.send')}}
					</button>
				</form>
			</div>
			<div class="col-12 col-md-8 mt-5" v-else style="height: 20rem">
				<h5 class="mt-5 pt-5 text-center" v-html="$t('restore.token-error-message')"></h5>
			</div>
		</div>
		<div class="row justify-content-center" v-else>
			<div class="col-12 col-md-8">
				<h5 class="mt-5 pt-5 text-center" v-html="$t('restore.password-updated')"></h5>
			</div>
		</div>
	</div>
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import axios from "axios";
export default {
	data() {
		return {
			cambiar: {
				new_password: "",
				check_password: "",
				token_check: true,
				not_match: false,
				update: false,
				code_api: false,
				sending: false,
				token: ""
			}
		};
	},
	validations: {
		cambiar: {
			new_password: {
				required,
				strongPassword(new_password) {
					return (
						/[a-z]/.test(new_password) && //checks for a-z
						/[0-9]/.test(new_password) && //checks for 0-9
						new_password.length >= 6 &&
						new_password.length <= 10 &&
						/[A-Z]/.test(new_password) //checks for MAYUS
					);
				}
			},
			check_password: {
				required,
				sameAsPassword: sameAs("new_password")
			}
		}
	},
	created() {
		window.scrollTo(0, 0);
		this.loadToken();
	},
	methods: {
		loadToken() {
			axios
				.get(
					process.env.VUE_APP_API_URL +
						"check/token/" +
						this.$route.params.token,
					{}
				)
				.then(res => {
					this.cambiar.token_check = res.data.disponible;
					this.cambiar.token = this.$route.params.token;
				})
				.catch(function(error) {});
		},
		sendForm() {
			this.cambiar.sending = true;
			this.cambiar.token = this.$route.params.token;
			if (this.cambiar.new_password !== this.cambiar.check_password) {
				this.cambiar.sending = false;
				this.cambiar.not_match = true;
				return false;
			} else {
				this.cambiar.not_match = false;
			}
			axios
				.post(
					process.env.VUE_APP_API_URL + "change/password",
					this.cambiar
				)
				.then(res => {
					this.cambiar.update = res.data.update;
					this.cambiar.sending = false;
					if (this.cambiar.update) {
						this.cambiar.code_api = res.data.code_api;
					}
				})
				.catch(function(error) {
					this.cambiar.sending = false;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.container {
	.error {
		display: none;
	}
	.form-group--error + .error {
		display: block;
	}

	.btn-primary {
		margin-top: 0;
		&:disabled {
			background: #8e8e8e;
		}
	}
}
</style>