import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';

export const registerValidations = () => ({
    formData: {
        first_name: { required, min_length: minLength(2), max_length: maxLength(30) },
        last_name: { required, min_length: minLength(2), max_length: maxLength(40) },
        email: { email },
        password: { required, min_length: minLength(6), max_length: maxLength(20) },
    }
});

export const countryValidations = () => ({
    formData: {
        interest_country: { required },
        user_country: { required },
    }
});
