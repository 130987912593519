<template>
    <div class="steps-container">
        <div class="d-none d-sm-block" v-for="(n, index) in size" :key="index" :class="n <= active ? 'activo' : ''" @click="goTo(n)">{{ n }}</div>
        <div class="d-block d-sm-none activo" v-if="active > 1" @click="goTo(active - 1)">{{active - 1}}</div>
        <div class="d-block d-sm-none activo">{{active}}</div>
        <div class="d-block d-sm-none" v-if="active < size">{{active + 1}}</div>
    </div>
</template>

<script>
export default {
    name: "Steps",
    props: ["size", "active"],
    methods: {
        goTo(step) {
            if(step <= this.active) {
                this.$emit('step', step);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.steps-container {
    background-color: $hf-sec-pine;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    div {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: inline-block;
        background: #fbfbfb;
        color: $hf-sec-pine;
        text-align: center;
        margin: 10px;
        font-size: 1.2em;
        border: solid 1px $hf-sec-pine;
        cursor: not-allowed;
    }

    .activo {
        background-color: $hf-sec-pine;
        color: white;
        cursor: pointer;
    }
}
</style>



