<template>
	<div class="coupon-form">
		<div class="effect" />
		<div class="content">
			<div class="coupon-header">Coupons</div>
			<div class="coupon-body">
				<label for="code">Codigo</label>
				<input
					class="form-control"
					type="text"
					id="code"
					v-model="coupon.code"
                    disabled
				/>

				<label for="percentage">Percentage</label>
				<input
					class="form-control"
					type="text"
					id="percentage"
					v-model="coupon.percentage"
				/>

                <label for="percentage">ID's Alojamientos</label>
				<multiselect
					v-model="coupon.selectedAccommodations"
					:options="options"
					:multiple="true"
					track-by="idalojamiento"
					:placeholder="$t('multiselect.placeholder')"
					label="idalojamiento"
					:selectLabel="$t('multiselect.pick')"
					:hide-selected="true"
				>
				</multiselect>

				<div class="radios">
					<div class="input">
						<input
							type="radio"
							name="status"
							:value="true"
							id="status_true"
							v-model="coupon.status"
						/>
						<label for="status_true">Activado</label>
					</div>
					<div class="input">
						<input
							type="radio"
							name="status"
							:value="false"
							id="status_false"
							v-model="coupon.status"
						/>
						<label for="status_false">Dehactivado</label>
					</div>
				</div>
			</div>

			<div class="coupon-controls">
				<button class="btn btn-coupons" @click="close">Cancelar</button>
				<button class="btn btn-coupons" @click="handleUpdate">
					Actualizar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import Multiselect from 'vue-multiselect'

export default {
    props: ["couponData"],
    components: {
        Multiselect
    },
	data() {
		return {
            coupon: {},
            options: [],
			url: process.env.VUE_APP_API_URL.substring(
				0,
				process.env.VUE_APP_API_URL.indexOf("/api/v1")
			),
		};
	},
	methods: {
		handleUpdate() {
			let c = { ...this.coupon };
			c.status = c.status === true ? 1 : 0;
			axios.put(`${this.url}/cupon/${this.coupon.id}`, c).then(() => {
				this.$emit("close", "update");
			});
        },
        getAccommodationIds() {
			axios.get(`${process.env.VUE_APP_API_URL}admin/get-accommodations/ids`).then((response) => {
				this.options = response.data;
			});
		},
        close(){
            this.$emit("close", "update");
        }
	},
	mounted() {
        this.getAccommodationIds();
        this.coupon = this.couponData;
		this.coupon.status = this.coupon.status === 1 ? true : false;
	},
};
</script>

<style lang="scss" scoped>
.coupon-form {
	position: fixed;
	width: 100%;
	height: 100vh;
	left: 0;
	top: 0;
	display: flex;
	z-index: 100000000;
	display: flex;
	justify-content: center;
	align-items: center;

	.effect {
		position: absolute;
		width: 100%;
		height: 100%;
		background: #000;
		z-index: 1;
		opacity: 0.5;
	}

	.content {
		position: relative;
		z-index: 2;
		background: #fff;
		border-radius: 5px;
		padding: 20px;
		min-width: 30%;
		@media (max-width: 600px) {
			min-width: 90%;
		}

		.coupon-header {
			border-bottom: 1px solid #e3e3e3;
			margin-bottom: 15px;
		}

		.coupon-body {
			display: flex;
			flex-direction: column;

			label {
				color: #838383;
				margin-left: 5px;
				font-size: 0.9em;
			}
		}

		.coupon-controls {
			display: flex;
			padding: 15px;
			display: flex;
			justify-content: flex-end;
			.btn-coupons {
				margin: 0 10px;
                border: solid 1px rgb(206, 212, 218);
                color: #525053;
			}
		}
	}
}
</style>