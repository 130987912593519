<template>
    <div>
        <div class="row mt-2">

            

        </div>

        <hr />

        <div class="row">

            <!-- Form fields -->
            <div class="col-md-12 col-lg-6">

                <!-- Currency selector -->
                <div class="form-group">
                    {{ $t('wizard.s2.price-message-' + (type.tipo === 'cowork' ? 'cowork' : type.tipo === 'camper' ? 'camper' :
                    'default')) }}
                </div>

                <div class="form-group" :class="{ 'error__validation': values.moneda.error }">
                    <select v-model="values.moneda.value" class="form-control">
                        <option v-for="moneda in currencyConversionTable" :value="moneda.moneda" :key="moneda.idtipo_cambio">
                            <div>{{ moneda.moneda }}</div>
                        </option>
                    </select>
                    <div class="text-danger required">{{ $t('wizard.s1.field-required') }}</div>
                </div>
                
                <!-- Monthly rental -->
                <div v-show="!modality.diario || modality.mensual" class="form-group">

                    <!-- Tooltip -->
                    {{ $t('wizard.s2.property-expense-long') }}
                    <button class="circular-button"
                        @click="cambiarTip('wizard.s2.property-expense', 'wizard.s2.property-expense-info')" 
                        @mouseover="cambiarTip('wizard.s2.property-expense', 'wizard.s2.property-expense-info')"
                        href="#tips"
                        v-smooth-scroll="{ duration: 1000, offset: -200 }"
                    >
                        <i class="fas fa-info" />
                    </button>

                    <!-- Rent amount -->
                    <div :class="{ 'error__validation': values.arriendo.error }">
                        <input type="text" inputmode="numeric" min="0" v-model="values.arriendo.value" class="form-control"
                            :class="{ 'is-invalid': values.arriendo.error }" />
                        <div class="text-danger required">{{ $t('wizard.s1.field-required') }}</div>
                    </div>

                    <!-- Services -->
                    {{ $t('wizard.s2.basic-services-long') }}
                    <button
                        @click="cambiarTip('wizard.s2.basic-services-long', 'wizard.s2.basic-services-info')"
                        @mouseover="cambiarTip('wizard.s2.basic-services-long', 'wizard.s2.basic-services-info')"
                        class="circular-button" href="#tips" v-smooth-scroll="{ duration: 1000, offset: -200 }">
                        <i class="fas fa-info" />
                    </button>

                    <input type="text" inputmode="numeric" min="0" v-model="values.arriendo_basicos.value" class="form-control" />

                    <!-- Expenses -->
                    {{ $t('wizard.s2.common-expenses-long') }}
                    <button
                        @click="cambiarTip('wizard.s2.common-expenses', 'wizard.s2.common-expenses-info')"
                        @mouseover="cambiarTip('wizard.s2.common-expenses', 'wizard.s2.common-expenses-info')"
                        class="circular-button" href="#tips" v-smooth-scroll="{ duration: 1000, offset: -200 }">
                        <i class="fas fa-info" />
                    </button>

                    <input type="text" inputmode="numeric" min="0" v-model="values.arriendo_comunes.value" class="form-control" />

                    <!-- Cleaning -->
                    {{ $t('wizard.s2.cleaning-long') }}
                    <button 
                        @click="cambiarTip('wizard.s2.cleaning', 'wizard.s2.cleaning-info')"
                        @mouseover="cambiarTip('wizard.s2.cleaning', 'wizard.s2.cleaning-info')"
                        class="circular-button"
                        href="#tips" v-smooth-scroll="{ duration: 1000, offset: -200 }">
                        <i class="fas fa-info" />
                    </button>

                    <input type="text" inputmode="numeric" min="0" v-model="values.arriendo_limpieza.value" class="form-control" />

                    <!-- Warranty -->
                    {{ $t('wizard.s2.warranty') }}
                    <button
                        @click="cambiarTip('wizard.s2.warranty', 'wizard.s2.warranty-info')"
                        @mouseover="cambiarTip('wizard.s2.warranty', 'wizard.s2.warranty-info')"
                        class="circular-button"
                        href="#tips" v-smooth-scroll="{ duration: 1000, offset: -200 }">
                        <i class="fas fa-info" />
                    </button>
                    
                    <div>
                        <input 
                            type="text" 
                            inputmode="numeric"
                            min="0" 
                            :value="total | priceFormatter(values.moneda.value)" 
                            class="form-control" 
                            disabled
                        />
                    </div>

                </div>


                <!-- Daily rental -->
                <div v-show="modality.diario" class="form-group">

                    <!-- Tooltip -->
                    {{ $t('wizard.s2.daily-lease') }}
                    <button class="circular-button"
                        @click="cambiarTip('wizard.s2.daily-lease', 'wizard.s2.daily-info')"
                        @mouseover="cambiarTip('wizard.s2.daily-lease', 'wizard.s2.daily-info')"
                        href="#tips" v-smooth-scroll="{ duration: 1000, offset: -200 }">
                        <i class="fas fa-info" />
                    </button>

                    <!-- Rent amount -->
                    <div :class="{ 'error__validation': values.arriendo_diario.error }">
                        <input type="text" inputmode="numeric" min="0" v-model="values.arriendo_diario.value" class="form-control"
                            :class="{ 'is-invalid': values.arriendo_diario.error }" />
                        <div class="text-danger required">{{ $t('wizard.s1.field-required') }}</div>
                    </div>

                </div>
            </div>

            <!-- Help guy -->
            <div  class="col-md-12 col-lg-6" id="tips">

                <div class="info-tips-container flash" :class="{ 'flashing': isFlashing }">
                    <div class="circular-portrait">
                        <div class="text-center mt-4">
                            <img src="@/assets/disfruta.png" />
                        </div>
                    </div>

                    <h4 class="mt-4">{{ titulo }}</h4>
                    <div :title="titulo" class="mt-4 text-center">{{ tip }}</div>
                </div>

                <b-card v-show="!modality.diario || modality.mensual" header-tag="header" footer-tag="footer" class="mt-3">
                    <b-card-text>
                        <div class="row">
                            <div class="col-6">{{ $t('wizard.s2.property-expense') }}</div>
                            <div class="col-6 text-right">
                                <b>{{ values.arriendo.value | priceFormatter(values.moneda.value) }}</b>
                            </div>
                        </div>
                        <hr />

                        <div class="row">
                            <div class="col-6">{{ $t('wizard.s2.basic-services') }}</div>
                            <div class="col-6 text-right">
                                <b>{{ values.arriendo_basicos.value | priceFormatter(values.moneda.value) }}</b>
                            </div>
                        </div>
                        <hr />

                        <div class="row">
                            <div class="col-6">{{ $t('wizard.s2.common-expenses') }}</div>
                            <div class="col-6 text-right">
                                <b>{{ values.arriendo_comunes.value | priceFormatter(values.moneda.value) }}</b>
                            </div>
                        </div>
                        <hr />

                        <div class="row">
                            <div class="col-6">{{ $t('wizard.s2.cleaning') }}</div>
                            <div class="col-6 text-right">
                                <b>{{ values.arriendo_limpieza.value | priceFormatter(values.moneda.value) }}</b>
                            </div>
                        </div>
                        <hr />

                        <div class="row">
                            <div class="col-6">{{ $t('wizard.s2.monthly-receive') }}:</div>
                            <div class="col-6 text-right">
                                <b>{{ total | priceFormatter(values.moneda.value) }}</b>

                            </div>
                        </div>

                    </b-card-text>
                    <em slot="footer">
                        <div class="row">
                            <div class="col-6">{{ $t('wizard.s2.warranty') }}</div>
                            <div  class="col-6 text-right">{{ total | priceFormatter(values.moneda.value) }}</div>
                        </div>
                    </em>
                </b-card>

                <div v-show="modality.diario" class="col-12 text-center mt-4 pt-4 pb-4 recibe">
                    {{ $t('wizard.s2.daily-receive') }} {{ values.arriendo_diario.value | priceFormatter(values.moneda.value) }}
                </div>
            </div>
        </div>

        <hr />

        <FooterWizard next prev />

    </div>
</template>


<script>
import FooterWizard from "./FooterWizard";
import { mapState } from "vuex";

export default {
    name: "Step2Wizard",
    props: {
        entero: {
            default: null
        },
        place: {
            default: null
        },
        modality: {
            default: null
        },
        data: {
            type: Object,
            default: null
        },
        type: {
            type: Object,
            default: () => { }
        }
    },
    components: {
        FooterWizard
    },
    data() {
        return {
            isFlashing: false,
            titulo: "",
            tip: "",
            storage: {
                titulo: "wizard.s2.tips-title",
                mensaje: "wizard.s2.info-tips"
            },
            values: {
                arriendo: {
                    value: '',
                    required: true,
                    error: false
                },
                arriendo_basicos: {
                    value: '',
                    required: false,
                    error: false
                },
                arriendo_comunes: {
                    value: '',
                    required: false,
                    error: false
                },
                arriendo_limpieza: {
                    value: '',
                    required: false,
                    error: false
                },
                garantia: {
                    value: '',
                    required: false,
                    error: false
                },
                arriendo_diario: {
                    value: '',
                    required: true,
                    error: false
                },
                arriendo_total: {
                    value: '',
                    required: false
                },
                moneda: {
                    value: '',
                    required: true,
                    error: false
                }
            }
        };
    },
    methods: {

        cambiarTip(titulo, mensaje) {
            this.storage.titulo = titulo;
            this.storage.mensaje = mensaje;
            this.titulo = this.$t(titulo);
            this.tip = this.$t(mensaje);

            // Flash efect
            this.isFlashing = true;
            setTimeout(() => {
                this.isFlashing = false;
            }, 1000)
        },

        clearError() {
            console.log('clearing errors');
            Object.keys(this.values).forEach(item => {
                this.values[item].error = false;
            });
        },
        validateForm() {
            this.clearError();
            let error = 0;
            
            if (this.modality.ambos) {
                // Check that all required fields are filled
                Object.keys(this.values).forEach(item => {
                    if (this.values[item].required) {
                        if (
                            this.values[item].value === null ||
                            this.values[item].value === "" ||
                            this.values[item].value < 1
                        ) {
                            this.values[item].error = true;
                            error++;
                        }
                    }
                });
            } else if (this.modality.mensual) {
                // Check that all monthly required fields are filled
                if (
                    this.values.arriendo.value === null ||
                    this.values.arriendo.value === "" ||
                    this.values.arriendo.value < 1
                ) {
                    this.values.arriendo.error = true;
                    error++;
                }
            } else {
                // Check that all daily required fields are filled
                if (
                    this.values.arriendo_diario.value === null ||
                    this.values.arriendo_diario.value === "" ||
                    this.values.arriendo_diario.value < 1
                ) {
                    this.values.arriendo_diario.error = true;
                    error++;
                }
            }
            
            if (this.values.moneda.value === "") {
                this.values.moneda.error = true;
                error++;
            }

            window.scrollTo(0, 0);

            return error;
        },
        nextStep() {
            let step = {};
            Object.keys(this.values).forEach(item => {
                step[item] = this.values[item].value;
            });
            
            if (this.validateForm() == 0) {
                this.$emit("next", step);
            }
        },
        prevStep() {
            this.$emit("prev");
        }
    },
    computed: {

        ...mapState('exchange', [
            "currencyConversionTable",
            "visitorCurrency"
        ]),

        title() {
            return this.$t;
        },

        total() {

            let arriendo =
                this.values.arriendo.value != null
                    ? Number(this.values.arriendo.value)
                    : 0;

            let basicos =
                this.values.arriendo_basicos.value != null
                    ? Number(this.values.arriendo_basicos.value)
                    : 0;

            let comunes =
                this.values.arriendo_comunes.value != null
                    ? Number(this.values.arriendo_comunes.value)
                    : 0;

            let limpieza =
                this.values.arriendo_limpieza.value != null
                    ? Number(this.values.arriendo_limpieza.value)
                    : 0;

            let total = arriendo + basicos + comunes + limpieza;

            this.values.arriendo_total.value = total;
            this.values.garantia.value = total;

            return total > 0 ? total : '' ;
        },
    },
    created() {
        window.scrollTo(0, 0);

        this.cambiarTip(this.storage.titulo, this.storage.mensaje);
        if (this.data != null) {
            Object.keys(this.data).forEach(item => {
                let value = parseInt(this.data[item]);
                this.values[item].value = value;
            });
        }
    },
    mounted() {
        
        this.$watch(this.$t, () => {
            this.cambiarTip(this.storage.titulo, this.storage.mensaje);
        });

        // Set the visitor currency as default
        this.values.moneda.value =  (this.data && this.data.moneda) ? this.data.moneda : this.visitorCurrency;
    }
};
</script>

<style lang="scss" scoped>

.flashing {
    animation: flashAnimation 0.7s alternate;
}

@keyframes flashAnimation {
    0%, 30%, 100% {
        background-color: #ffffff;
    }
    20%, 40% {
        background-color: #f2f2f2;
    }
}

.circular-button {
    background-color: white;
    border-radius: 50%;
    border: solid 1px rgb(196, 196, 196);
    width: 40px;
    height: 40px;
    outline: 0;
    margin: 15px 0 6px 5px;

    .fa-info {
        color: $hf-sec-pine;
    }

    &:hover,
    &:active {
        background-color: $hf-sec-pine;
        //margin-top: 0;

        .fa-info {
            color: #ffffff;
        }
    }
}

.form-control {
    height: 48px;

    &::placeholder {
        color: rgb(221, 221, 221);
    }
}

.type-info {
    padding-top: 16px;
}

.info-tips-container {
    border: solid 0.5px rgb(226, 226, 226);
    border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
}

.circular-portrait {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: auto;
    border: 1px solid $hf-sec-pine;
}

.circular-portrait img {
    width: 55%;
    height: auto;
}

.recibe {
    background-color: $hf-sec-pine;
    color: white;
}

.required {
    display: none;
}

.error__validation .required {
    display: block;
}
</style>