<template>
	<div class="modal-uploader" v-if="show">
		<div class="backdrop" />
		<div class="modal-main">
			<div class="component">
				<div class="modal-uploader-content">
					{{ message[$i18n.locale] }}
				</div>
				<div class="modal-uploader-controls" v-if="!justText">
					<button v-if="noCancel !== true" @click="cancel">Cancelar</button>
					<button @click="confirm">OK</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Modal",
	props: ["message", "show", "id", "noCancel", "justText"],
	methods: {
		cancel() {
			this.$emit("cancel", this.id);
		},
		confirm() {
			if (this.noCancel == true) {
				this.$emit("cancel", this.id);
			} else {
				this.$emit("confirm", this.id);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-uploader {
	position: fixed;
	width: 100%;
	height: 100vh;
	left: 0;
	top: 0;
	z-index: 1000;

	.backdrop {
		background: #000;
		opacity: 0.3;
		width: 100%;
		height: 100%;
		z-index: 1000;
	}
	.modal-main {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 0;
		top: 0;
		position: fixed;
		flex-direction: column;

		.component {
			background: #efefef;
			padding: 20px;
			border-radius: 7px;
			border: 2px solid #3e3e3e;
			box-shadow: 3px 3px 8px #6e6e6e;
			max-width: 90%;

			div {
				margin: 10px;
			}

			button {
				border: 1px solid #9e9e9e;
				border-radius: 5px;
				padding: 3px 8px;
				margin: 0 5px !important;
				background: #efefef;

				&:hover {
					background: $hf-sec-pine;
					color: #fff;
				}
			}

			.modal-uploader-controls {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}
</style>