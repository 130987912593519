<template>
    <div class="detail-article-container">
        <div class="article-image-container">
            <img :src="article.better_featured_image.media_details.sizes.medium_large.source_url" />
            <h1 class="article-title">{{article.title.rendered}}</h1>
            <div class="overlay" />
        </div>

        <div class="container article-info">
            <div v-html="article.content.rendered" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    methods: {
        ...mapActions(["getDetailArticle"])
    },
    computed: {
        ...mapState(["article"])
    },
    created() {
        this.getDetailArticle({ lang: this.$i18n.locale });
        window.scrollTo(0, 0);
    }
};
</script>

<style lang="scss" scoped>
.detail-article-container {
    .article-image-container {
        position: relative;

        .article-title {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            z-index: 10;
            text-shadow: 0px 0px 8px #000000;
            padding: 50px;
            text-align: center;
        }

        .overlay {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }

        img {
            width: 100%;
            height: 400px;
            object-fit: cover;
            object-position: 0 0;
        }
    }
    .article-info {
        padding: 50px;
    }
}
</style>