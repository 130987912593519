<template>
    <div>
        <ManageData />
    </div>
</template>

<script>
import ManageData from '../../components/Admin/ManageData/ManageData';
export default {
    components: {
        ManageData
    }
}
</script>