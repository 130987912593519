<template>
	<div class="container">
		<div class="detalle-alojamiento-accordion">
			<b-card no-body>
				<b-card-header header-tag="header" class="p-1" role="tab">
					<b-button
						block
						href="#"
						v-b-toggle.reglas
						variant="info"
						id="tab-general-rules-button"
						>{{ $t("accommodation.general-rules") }}</b-button
					>
				</b-card-header>
				<b-collapse
					visible
					id="reglas"
					accordion="reglas"
					role="tabpanel"
				>
					<span v-html="JSON.parse(data.reglas)[$i18n.locale]" />
				</b-collapse>
			</b-card>

			<b-card no-body>
				<b-card-header header-tag="header" class="p-1" role="tab">
					<b-button
						block
						href="#"
						v-b-toggle.comunes
						variant="info"
						id="tab-common-spaces-button"
						>{{ data.tipo != 'camper' ? $t("accommodation.common-spaces") : $t("accommodation.common-spaces-camper") }}</b-button
					>
				</b-card-header>
				<b-collapse id="comunes" role="tabpanel">
					<span
						v-html="
							JSON.parse(data.espacios_comunes)[$i18n.locale]
						"
				/></b-collapse>
			</b-card>

			<b-card no-body>
				<b-card-header header-tag="header" class="p-1" role="tab">
					<b-button
						block
						href="#"
						v-b-toggle.cocina
						variant="info"
						id="tab-kitchen-button"
						>{{ data.tipo == 'camper' ? $t("accommodation.kitchen-camper") : $t("accommodation.kitchen") }}</b-button
					>
				</b-card-header>
				<b-collapse id="cocina" role="tabpanel">
					<span v-html="JSON.parse(data.uso_cocina)[$i18n.locale]"
				/></b-collapse>
			</b-card>

			<b-card no-body>
				<b-card-header header-tag="header" class="p-1" role="tab">
					<b-button
						block
						href="#"
						v-b-toggle.alimpieza
						variant="info"
						id="tab-cleaning-button"
						>{{ $t("accommodation.cleaning") }}</b-button
					>
				</b-card-header>
				<b-collapse id="alimpieza" role="tabpanel">
					<span
						v-html="JSON.parse(data.aseo_limpieza)[$i18n.locale]"
					/>
				</b-collapse>
			</b-card>
		</div>
	</div>
</template>


<script>
export default {
	name: "DetalleAlojamientoAccordion",
	props: {
		data: {
			type: Object,
			default: () => {
				return {
					reglas: JSON.stringify({ en: "", es: "" }),
					espacios_comunes: JSON.stringify({ en: "", es: "" }),
					uso_cocina: JSON.stringify({ en: "", es: "" }),
					aseo_limpieza: JSON.stringify({ en: "", es: "" }),
				};
			},
		},
	},
};
</script>

<style scoped lang="scss">
.container {
	//margin-top: 50px;
    padding-left: 0;
    padding-right: 0;

	.collapse,
	.collapsing {
		padding: 20px;
	}

	.btn-info {
		outline: 0;
		border-color: $hf-sec-pine;
	}

	.btn.btn-info.btn-block {
		background-color: $hf-sec-pine;
		border: $hf-sec-pine;
	}
}
</style>