<template>
	<div class="container table">
		<CouponForm v-if="forms.create" @close="closeModal" />
		<CouponFormUpdate v-if="forms.update" :couponData="coupon" @close="closeModal" />
		<div class="row mb-3">
			<div class="col-12">Coupons</div>
		</div>
		<div class="row mb-3 justify-content-end">
			<div class="add" @click="handleCreate">
				<i class="fas fa-plus" />
			</div>
		</div>
		<div class="row title">
			<div class="col-3">Code</div>
			<div class="col-3">Percentagen</div>
			<div class="col-3">Status</div>
			<div class="col-3">Acciones</div>
		</div>
		<div class="row line" v-for="coupon in coupons" :key="coupon.id">
			<div class="col-3">
				{{ coupon.code }}
			</div>
			<div class="col-3">
				{{ coupon.percentage }}
			</div>
			<div class="col-3">
				{{ handleStatus(coupon.status) }}
			</div>
			<div class="col-3" @click="handleUpdate(coupon)">
				<i class="fas fa-edit" />
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

import CouponForm from "../../components/CouponForm/CouponForm";
import CouponFormUpdate from "../../components/CouponForm/CouponFormUpdate";

export default {
	components: {
		CouponForm,
		CouponFormUpdate,
	},
	data() {
		return {
			url: "",
			coupons: [],
			coupon: {
				code: "FRANCO",
				percentage: 10,
				status: 1,
				days_of_week: [7],
				quantity: -1,
            },
            forms: {
                create: false,
                update: false
            }
		};
	},
	methods: {
		handleStatus(value) {
			if (value === 1) {
				return "Valido";
			} else {
				return "Invalido";
			}
		},

		loadCoupons() {
			axios
				.get(`${this.url}/cupon`)
				.then((response) => {
                    const data = []
					response.data.forEach((item) => {
                        item.selectedAccommodations = item.alojamiento_ids;
                        data.push(item)
                    })
                    this.coupons = data;
				})
				.catch(({ response }) => {
					console.log(response);
				});
		},
		createCoupon() {
			axios.post(`${this.url}/cupon`, this.coupon).then(() => {
				this.loadCoupons();
			});
        },
        
        handleUpdate(payload) {
            this.coupon = {...this.coupon, ...payload };
            this.forms.update = true;
        },

        handleCreate() {
            this.forms.create = true;
        },
        closeModal(modal) {
            this.forms[modal] = false;
            this.loadCoupons();
        }
	},
	created() {
		let url = process.env.VUE_APP_API_URL.substring(
			0,
			process.env.VUE_APP_API_URL.indexOf("/api/v1")
		);
		this.url = url;
		this.loadCoupons();
	},
};
</script>

<style lang="scss" scoped>
.table {
	padding: 40px;

	.title,
	.line {
		padding: 10px;
		text-align: center;
	}

	.add {
		width: 40px;
		height: 40px;
		background: $hf-sec-pine;
		color: #fff;
		font-weight: bold;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5em;
		border-radius: 50%;
		box-shadow: 2px 2px 4px #888888;
	}
	.title {
		background: $hf-sec-pine;
		color: #fff;
		border-radius: 5px 5px 0 0;
		font-weight: bolder;
	}

	.line:nth-child(even) {
		background: #d6cae4a3;
	}
	.line:nth-child(odd) {
		background: rgb(235, 235, 235);
	}

	.line:last-child {
		border-radius: 0 0 5px 5px;
	}
}
</style>