<template>
    <div id="markerInfoWindow" @click="openAccommodation">
        <img :src="imageUrl" class="photo" />
        <div class="name">{{ accommodationName }}</div>
    </div>
</template>
  
<script>
export default {
    props: {
        accommodationId: Number,
        imageUrl: String,
        accommodationName: String,
    },
    methods: {
        openAccommodation() {
            window.open(`/alojamiento/${this.accommodationId}`, '_blank');
        }
    }
    
};
</script>

<style lang="scss">
#markerInfoWindow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    max-height: 600px;
    cursor: pointer;
}

#markerInfoWindow .photo {
    width: 100%;
    height: auto;
    object-fit: cover;
}

#markerInfoWindow .name {
    margin-top: 10px;
    font-size: 1rem;
    text-align: center;
    color: $grey-75;
}

.gm-style-iw {
    //width: 10vw !important;
}

</style>
