<template>
    <div class="container">
        <div class="top-info">{{$t('wizard.s4.features-info')}}:</div>
        <hr />
        <TopS4S7 :selected="2" :accommodationData="accommodationData" />
        <hr />

        <button v-if="!place.entero" @click="removeRoom" class="new-room-button mb-3 mr-3" id="more-rooms-button">
            <i class="fas fa-minus" />
        </button>

        <button v-if="!place.entero" @click="newRoom" class="new-room-button mb-3" id="less-rooms-button">
            <i class="fas fa-plus" />
        </button>

        <div v-if="data === null">
            <Room
                v-for="i in rooms" 
                :key="i" 
                :num="i"
                :title="$t('wizard.room.room')"
                :itens="$t('wizard.room')"
                :ref="'room_' + i"
            />
        </div>
        <div v-else>
            <Room
                v-for="(room, index) in this.data" 
                :key="room.idpieza" 
                :num="index + 1"
                :title="$t('wizard.room.room')"
                :itens="$t('wizard.room')"
                :ref="'room_update' + room.idpieza"
                :data="room"
            />
            <Room
                v-for="i in (rooms - 1)" 
                :key="i" 
                :num="i + data.length"
                :title="$t('wizard.room.room')"
                :itens="$t('wizard.room')"
                :ref="'room_' + i"
            />
        </div>

        <hr/>
        <FooterWizard prev next />
    </div>
</template>

<script>
import TopS4S7 from "./TopS4S7";
import Room from "./Room";
import FooterWizard from './FooterWizard';

export default {
    name: "Step5Wizard",
    props: {
        rooms: {
            type: Number,
            default: 0
        },
        data: {
            type: Array,
            default: null
        },
        place: {
            type: Object,
            default: false,
        },
        accommodationData: {
            type: Object,
            default: () => { 
                return {
                    tipo: ''
                }
             }
        }
    },
    data() {
        return {
            accommodation: {
                pieza: []
            },
            stepIndex: 0,
            i: 2,
        };
    },
    components: {
        TopS4S7,
        Room,
        FooterWizard
    },

    methods: {
        newRoom: function() {
            this.$emit('new-room');
        },
        removeRoom: function() {
           if(this.rooms > 1 && this.data == null) {
                delete this.$refs['room_' + this.rooms];
                this.$emit('remove-room');
           } else {
                delete this.$refs['room_' + (this.rooms - 1)];
                this.$emit('remove-room');
           }
        },
        nextStep() {
            this.accommodation.pieza = [];
            Object.keys(this.$refs).forEach((value, index) => {
                this.accommodation.pieza.push(this.$refs[value][0].room);
            })
            this.$emit('next', this.accommodation);
        },
        prevStep() {
            this.$emit('prev');
        }
    },

    created() {
        
    }
};
</script>

<style scoped lang="scss">
.top-info {
    padding-top: 10px;
}

.new-room-button {
    background-color: $hf-sec-pine;
    width: 40px;
    height: 40px;
    border: none;
    color: white;
    border-radius: 4px;
    outline: 0;
    margin: 0;
    cursor: pointer;
    &:hover {
        margin: 0;
    }
}
</style>
