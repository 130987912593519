import * as types from './types';
import {SET_AVAILABLE} from "./types";

export default {
    [types.SET_SIMULATION](state, simulation) {
        state.simulation = simulation;
    },
    [types.SET_ACCOMMODATION_ID](state, accommodationId) {
        state.accommodationId = accommodationId;
    },
    [types.SET_INIT_DATE](state, initDate) {
        state.initDate = initDate;
    },
    [types.SET_END_DATE](state, endDate) {
        state.endDate = endDate;
    },
    [types.SET_TOTAL_DAYS](state, totalDays) {
        state.totalDays = totalDays;
    },
    [types.SET_GUESTS](state, guests) {
        state.guests = guests;
    },
    [types.SET_AVAILABLE](state, available) {
        state.available = available;
    }
};