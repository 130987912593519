<template>
	<div class="highlight-content">
        <div class="highlight-title">
            {{$t('highlight.titles.title')}}
        </div>
        <div v-if="!loaded">
            <Loader />
        </div>
		<div class="main-content" v-if="loaded">
			<div class="component">
				<input
					type="number"
					class="form-control"
					:placeholder="placeholder"
                    v-model="id"
				/>
				<button class="btn btn-secondary" :disabled="id === null || id === ''" @click="addHighlight">Agregar</button>
			</div>

			<div class="accommodations">
				<div
					v-for="accommodation in accommodations"
					:key="accommodation.idalojamiento"
					class="acc-item"
				>
					<div class="highlight-carousel">
                        <CarouselAdmin
						:photos="accommodation.photos"
						:idAlojamiento="accommodation.idalojamiento"
						:externalReference="accommodation.external_reference"
					/>
                    </div>

					<div class="accommodation-info">
						<div>
							#{{accommodation.idalojamiento}} - {{ getJsonData(accommodation.nombre) }}
						</div>
						<div style="text-align: right">
							<button class="btn btn-danger" @click="removeHighlight(accommodation.idalojamiento)">Borrar</button>
						</div>
					</div>

					<!-- <button>
						<i class="far fa-trash-alt"></i>
					</button> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import CarouselAdmin from "@/components/Carousel/CarouselAdmin";
import Loader from '../../Loader/Loader'

export default {
	name: "Highlight",
	components: {
        CarouselAdmin,
        Loader
	},
	data() {
		return {
			placeholder: "",
			api: process.env.VUE_APP_API_URL,
			accommodations: [],
            loaded: false,
            id: null
		};
	},
	methods: {
		gethighlighted(api) {
			console.log("searching by highlighteds...");
			const url = `${api}accommodations/highlight/all`;
			axios.get(url).then((response) => {
				this.accommodations = response.data;
				this.loaded = true;
				console.log(this.accommodations);
			});
		},
		getJsonData(value) {
			return JSON.parse(value)[this.$i18n.locale];
        },
        removeHighlight(id) {
            this.loaded = false;
            const url = `${this.api}accommodations/highlight/delete/${id}`;
            axios.delete(url).then((response) => {
				this.accommodations = response.data;
				this.loaded = true;
			});
        },
        addHighlight() {
            this.loaded = false;
            const url = `${this.api}accommodations/highlight/add/${this.id}`;
            axios.put(url).then((response) => {
				this.accommodations = response.data;
                this.loaded = true;
                this.id = ''
			}).catch(() => {
                this.loaded = true;
                this.id = ''
                alert('Alojamiento no Encontrado')
            });
        }
	},
	created() {
		this.placeholder = this.$t("highlight.placeholder");
		this.gethighlighted(this.api);
		this.$watch(this.$t, () => {
			this.placeholder = this.$t("highlight.placeholder");
		});
	},
};
</script>

<style lang="scss" scoped>
.highlight-content {
	display: flex;
	width: 100%;
	min-height: 80vh;
	justify-content: center;
    align-items: center;
    flex-direction: column;

    .highlight-title{
        width: 90%;
        font-size: 2em;
        padding: 20px;
        border-bottom: 1px solid #e3e3e3;
        margin: 20px;
    }

	.main-content {
		width: 80%;
		display: flex;
		align-items: center;
		flex-direction: column;

		.component {
			display: flex;
			width: 100%;
			margin-bottom: 20px;

			input {
				margin: 0 10px;
			}
		}

		.accommodations {
			width: 95%;
			.acc-item {
				font-size: 0.9em;
				width: 100%;
				display: flex;
				flex-direction: row;
				border: 1px solid #efefef;
				border-radius: 10px;
				overflow: hidden;
				margin: 10px 0;
                

				&:hover {
					background-color: #efefef;
				}

                .highlight-carousel {
                    width: 400px;
                    height: 100%;
                }

				.accommodation-info {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					padding: 20px;
					width: 100%;
				}
			}
		}
	}
}

@media(max-width: 822px) {
		.highlight-content .main-content .accommodations .acc-item{
            flex-direction: column;
        }

        .highlight-content .main-content .accommodations .acc-item .accommodation-info{
            min-height: 150px;
        }

        .highlight-content .main-content .accommodations .acc-item .highlight-carousel{
            width: 100%;
        }
	}
</style>