<template>
    <div class="container padding-menu-footer">
        <h1 class="title">{{$t('privacy.title')}}</h1>
        <div v-html="$t('privacy.content')"></div>
    </div>
</template>

<script>
export default {
    created() {
        window.scrollTo(0, 0);
    }
};
</script>

<style scoped lang="scss">
.padding-menu-footer {
    padding-top: 50px;
    padding-bottom: 50px;
}

.title {
    margin-bottom: 50px;
}

h1 {
    text-align: center;
    font-weight: 900;
    color: #003D29;
    text-transform: uppercase;
}
</style>