<template>
	<div class="dashboard-search">
		<input class="dashboard-search-input" type="text" v-model="s" @input="handleChange" :placeholder="placeholder" />
	</div>
</template>

<script>
import _ from "lodash";

export default {
    name: "DashboardSearch",
    data() {
        return {
            text: {
                es: 'Filtrar por id, título o referencia externa',
                en: 'Filter by id, title or external reference'
            },
            placeholder: '',
            s: ''
        }
    },
	methods: {
		handleChange: _.debounce(function (e) {
			this.$emit('search', e.target.value)
		}, 500),
    },
    created() {
        this.placeholder = this.text[this.$i18n.locale];
        const urlParams = new URLSearchParams(window.location.search);
        this.s = urlParams.get('s');
        this.$watch(this.$t, () => {
            this.placeholder = this.$i18n.locale === "es" ? this.text.es : this.text.en;
        })
    }
};
</script>

<style lang="css" scoped>
    .dashboard-search{
        width: 100%;
        padding: 30px 0;
    }

    .dashboard-search-input {
        width: 100%;
        border: 1px solid #c2c2c2;
        padding: 15px 20px;
        font-size: 1.1em;
        border-radius: 50px;
        box-shadow: none;
        outline: none;
        text-align: center;
    }
</style>