<template>
    <div class="container">
        
        <div class="benefits__subtitle">{{ $t("benefits.world-benefits") }}</div>
        <div class="benefits__title">
            {{ $t('benefits.your-benefits') }}
            <span
                v-b-popover.hover.top="`${$t('benefits.popup-benefit')}`"
            >
                <i class="fas fa-info-circle" />
            </span>
        </div>
        
        <!-- Benefit box -->
        <div class="row">
            <Benefit
                class="my-2 px-2"
                v-for="(item, i) in benefitsData"
                :title="item.title"
                :image="item.image"
                :key="`B-${i}`"
            />
        </div>
        
    </div>
</template>

<script>
import Benefit from "./Benefit";
import benefitsData from "./benefits-data.json";

export default {
    name: "Benefits",
    components: {
        Benefit
    },
    data() {
        return {
            benefitsData
        };
    }
}; 
</script>

<style scoped lang="scss">
.container {
    
    margin-top: 50px;
    margin-bottom: 40px;
    
    .benefits {
        &__subtitle {
            color: rgb(167, 167, 167);
            font-size: 1.4em;
        }

        &__title {
            font-weight: bold;
            color: #4c4c4c;
            font-size: 1.4em;
            text-transform: uppercase;
            padding-bottom: 20px;
        }
    }
}
</style>
