<template>
	<b-carousel
		id="carousel-fade"
		indicators
		:controls="true"
		:interval="10000"
	>
		<b-carousel-slide
			v-for="photo in photos"
			:key="photo.idgaleria"
			caption
		>
			<template #img>
				<img
					class="image-carousel"
					:src="externalReference ? photo.url : url + photo.url"
					alt="image slot"
					:id="photo.idgaleria"
				/>
			</template>
		</b-carousel-slide>
	</b-carousel>
</template>

<script>
export default {
	name: "CarouselAdmin",
    props: ["photos", "idAlojamiento", "externalReference"],
    data() {
        return {
            url: process.env.VUE_APP_S3_URL
        }
    }
};
</script>

<style lang="scss" scoped>
.image-carousel {
	width: 100%;
    height: 100%;
}

#carousel-fade {
	width: 100%;
	height: 100%;
}
</style>