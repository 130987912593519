var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[(_vm.pendiente)?_c('div',{staticClass:"row content p-3 mt-5 shadow",staticStyle:{"margin-top":"200px !important"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"col-12 text-center mt-2 mb-5"},[_c('button',{staticClass:"mb-5 btn-close",on:{"click":_vm.goHome}},[_vm._v("TERMINAR")])])]):_vm._e(),(_vm.exito)?_c('div',{staticClass:"row content p-3 mt-5 shadow",staticStyle:{"margin-top":"200px !important"}},[_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"col-12 text-center mt-2 mb-5"},[_c('button',{staticClass:"mb-5 btn-close",on:{"click":_vm.goHome}},[_vm._v("TERMINAR")])])]):_vm._e(),(_vm.fallido)?_c('div',{staticClass:"row content p-3 mt-5 shadow",staticStyle:{"margin-top":"200px !important"}},[_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"col-12 text-center mt-2 mb-5"},[_c('button',{staticClass:"mb-5 btn-close",on:{"click":_vm.goDashboard}},[_vm._v("DASHBOARD")])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 logo-desktop"},[_c('img',{attrs:{"src":"/img/logo.f4ba0fd0.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mt-3 icon text-center"},[_c('i',{staticClass:"fas fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 text-center mt-3 mb-5"},[_c('h3',[_vm._v("Operación pendiente!")]),_c('h5',[_vm._v("Una vez que se confirme su pago, se le informará por correo electrónico.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 logo-desktop"},[_c('img',{attrs:{"src":"/img/logo.f4ba0fd0.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mt-3 icon text-center"},[_c('i',{staticClass:"fas fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 text-center mt-3 mb-5"},[_c('h3',[_vm._v("Operación realizada con éxito")]),_c('h5',[_vm._v("La confirmación será enviada al correo ingresado")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 logo-desktop"},[_c('img',{attrs:{"src":"/img/logo.f4ba0fd0.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mt-3 icon text-center"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 text-center mt-3 mb-5"},[_c('h3',[_vm._v("Operación fallida")]),_c('h5',[_vm._v("Lamentablemente, no pudimos completar su pago. Puede volver a intentarlo accediendo a su reserva a través del panel haciendo clic en el botón a continuación.")])])
}]

export { render, staticRenderFns }