<template>
    <div class="progress-container">
        <div :class="['progress-item user-data', { 'active-step': step === 1, 'completed': step > 1 }]">
            <span>{{ $t('register-new.user-data') }}</span>
        </div>
        <div :class="['progress-item user-type', { 'active-step': step === 2, 'completed': step > 2 }]">
            <span>{{ $t('register-new.user-type') }}</span>
        </div>
        <div :class="['progress-item user-country', { 'active-step': step === 3, 'completed': step > 3 }]">
            <span>{{ $t('register-new.country') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Progress",
    props: {
        step: {
            type: Number,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>

.user-data,
.user-type,
.user-country {
    @media (min-width: $md) {
        width: 150px;
    }
    
}

.progress-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 50px;
    max-width: 600px;
    width: 100%;
}

.progress-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 15px;
    height: 40px;
    border: 1px solid #C0C0C0;
    border-radius: $border-radius-new;
    background-color: #fff;
    color: $input-border-color;
    font-size: 13px;
}

.progress-item::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -100%;
    width: 100%;
    height: 1px;
    background-color: $input-border-color;
    z-index: -1;
    transform: translateY(-50%);
}

.active-step {
    color: #000000;
    border: 1px solid #000000;
    &::before {
        background-color: $grey-75;
    }
}

.completed {
    border: 1px solid $grey-75;
    color: $grey-75;
    &::before {
        background-color: $grey-75;
    }
}

// Delete lines before first step
.progress-item:first-child::before {
    display: none;
}

</style>