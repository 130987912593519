<template>
    <div class="detalle-video-container" v-if="videos.length > 0">
        <div class="video-title">
            <img class="img-video" src="@/assets/video-accommodation.svg" alt="video"> Videos
        </div>
        <div class="links">
            <a v-for="(video, i) in videos" :key="video.id" :href="video.url" class="url-videos" target="_blank">Ver Video {{i + 1 }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "DetalleVideo",
    props: ['videos'],
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
    .detalle-video-container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    .img-video{
        width: 50px;
    }

    .links{
        padding-top: 25px;

        a {
            color: #3e3e3e;
            font-weight: bolder;
        }
    }
    

    .video-title {
        font-size: 1.4em;
        color: #7e7e7e;
        font-weight: bold;
    }

    .detalle-alojamiento-item {
        text-align: center;
        &__icon {
            color: $hf-sec-pine;
            font-size: 2em;
        }

        &__data {
            font-weight: bold;
        }

        @media (max-width: 767px) {
            padding: 20px;
        }
    }
    @media (max-width: 767px) {
        flex-direction: column;
    }
}
</style>