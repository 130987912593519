import axios from 'axios';
import * as types from './types';

export default {

    async fetchCurrencyConversionTable({ commit, dispatch }) {
        try {
            const response = await axios.get(process.env.VUE_APP_API_URL + 'exchange');
            
            // Set the currency conversion table in the store
            const currencyConversionTable = response.data.currencyConvetionTable;
            commit(types.SET_CURRENCY_CONVERSION_TABLE, currencyConversionTable);

        } catch (error) {
            console.error(error);
        }
    },

    setVisitorCurrency({ commit }, payload) {
        commit(types.SET_VISITOR_CURRENCY, payload);
    },

};
