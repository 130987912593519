<template>
    <div>
        <div v-if="datesDefined && !available">
            <div class="title">{{ $t("simulator.date-range-not-available") }}</div>
        </div>
        <div v-else-if="datesDefined" >
            <div class="title">
                {{ $t("simulator.title") }} {{ { 'amount': total, 'currency': accommodation.moneda } | convertAndFormatPrice }}
            </div>
            
            <div class="subtitle">
                (
                <span v-if="simulation.commission > 0" >{{ $t("simulator.service-charge") }}</span>
                <span v-if="simulation.stay > 0" class="ml-1"> + {{ $t("simulator.stay") }}</span>
                <span v-if="simulation.warranty > 0" class="ml-1"> + {{ $t("simulator.warranty") }}</span>
                )
            </div>
            
            <div class="payment-details" @click="showModal = true">
                <span>{{ $t("simulator.payment-details") }}</span>
            </div>
            
        </div>
        <div v-else="!datesDefined">
            <div class="title">{{ $t("simulator.ask-for-dates") }}</div>
        </div>
        
        <!-- TODO: send the accommodation currency in the simulation data-->
        <simulationDetailsModal
            :showModal="showModal" 
            :simulation="simulation"
            :accommodationCurrency="accommodation.moneda"
            @hide="handleHide"
        />
        
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import accommodation from "../../../store/modules/accommodation";
import simulationDetailsModal from "@/components/DetalleAlojamiento/subcomponents/SimulationDetailsModal.vue";
import accommodationPopUp from "@/components/PopUps/AccommodationPopUp.vue";

export default {
	
    name: "Simulator",
    
    components: {
        simulationDetailsModal: simulationDetailsModal,
    },
    
    data() {
        return {
            showModal: false,
        };
    },

    created() {
        this.setAccommodationId(this.$route.params.idalojamiento);
        // initDate and endDate are set in datePicker component
    },
    
	methods: {
        
        ...mapActions("simulator", [
            'getSimulation',
            'setAccommodationId',
        ]),

        handleHide() {
            this.showModal = false;
        },

        updateSimulation() {
            if (this.datesDefined) {
                this.getSimulation();
            }
        },
        
	},
    
	computed: {
        accommodationPopUp() {
            return accommodationPopUp
        },
        accommodation() {
            return accommodation
        },

        ...mapState('simulator', [
            'available',
        ]),

        ...mapState("accommodation", [
            'accommodation',
        ]),
        
        ...mapState("simulator", [
            'accommodationId',
            'initDate',
            'endDate',
            'simulation'
        ]),

        ...mapState("exchange", [
            'visitorCurrency',
        ]),

        total() {
            return this.simulation.stay + this.simulation.commission + this.simulation.warranty;
        },

        datesDefined() {
            return this.initDate != null && this.endDate != null;
        },
        
	},
    
    watch: {
        'accommodationId': function(newVal, oldVal) {
            if (this.datesDefined) {
                this.updateSimulation();
            }
        },
        'initDate': function(newVal, oldVal) {
            if (newVal && newVal < this.endDate) {
                this.updateSimulation();
            }
        },
        'endDate': function(newVal, oldVal) {
            if (newVal && newVal > this.initDate) {
                this.updateSimulation();
            }
        },
        'visitorCurrency': function(newVal, oldVal) {
            if (this.datesDefined) {
                this.updateSimulation();
            }
        },
    },
};
</script>

<style lang="scss" scoped>

.title {
    display: flex;
    justify-content: center;
}

.subtitle {
    display: flex;
    justify-content: center;
    color: $grey-50;
    font-size: 0.8rem;
}

.payment-details {
    text-align: center;
    font-size: 0.8rem;
    cursor: pointer;
    text-decoration: underline;
    color: $hf-sec-pine;
}

</style>