import * as types from './types';
import {SET_APP_OUT_OF_DATE} from "./types";

export default {
    [types.SET_APP_VERSION_CHECKED](state, versionChecked) {
        state.versionChecked = versionChecked;
    },
    [types.SET_APP_OUT_OF_DATE](state, appOutOfDate) {
        state.appOutOfDate = appOutOfDate;
    },
};