<template>
	<div class="input-group mb-3">
		<input
			type="text"
			class="form-control"
			:placeholder="$t('coupon.placeholder')"
			aria-label="Coupon"
			aria-describedby="check-coupon"
			v-model="cupon"
		/>
		<div class="input-group-append">
			<button
				class="btn btn-outline-secondary redem-btn"
				type="button"
				id="check-coupon"
                @click="checkCoupon"
			>
				{{$t('coupon.btn')}}
			</button>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
    props: ['reservation'],
	data() {
		return {
			cupon: "",
		};
	},
	methods: {
		checkCoupon() {
			axios
				.post(`${process.env.VUE_APP_API_URL}validate/${this.reservation.alojamiento_idalojamiento}`, { cupon: this.cupon })
				.then((response) => {
                    this.$toasted.show(this.$t('coupon.success'), {
                        theme: "outline", 
                        position: "top-right", 
                        duration : 5000
                    })
                    this.$emit('coupon', response.data.cupon)
                })
				.catch(({response}) => {
                    console.log(response)
                    this.$toasted.show(this.$t('coupon.error'), {
                        theme: "bubble", 
                        position: "top-right", 
                        duration : 5000
                    })
                });
		},
	},
};
</script>

<style lang="scss" scoped>
button {
	margin: 0;
	color: #ffffff;
    background-color: $hf-sec-pine;
    &:hover {
        margin: 0;
    }
}
</style>