// src/mixins/deviceMixin.js
export default {
    data() {
        return {
            isMobile: null,
        };
    },
    methods: {
        checkDeviceType() {
            this.isMobile = window.innerWidth < 768; // Bootstrap "md" is 768px
        },
    },
    created() {
        this.checkDeviceType();
        window.addEventListener("resize", this.checkDeviceType);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkDeviceType);
    },
};
