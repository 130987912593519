<template>
	<div class="facebook-container">
		<Loader v-if="loading" />
		<div class="content" v-if="!loading">
			<p>Hola {{ name }},</p>
			<p>
				Tu solicitud a Facebook fue exitosa. Si tiene alguna pregunta o
				sugerencia, comuníquese con nosotros. Su opinión es muy
				importante.
			</p>
			<p>House and Flats</p>
		</div>
	</div>
</template>

<script>
import Loader from "../Loader/Loader";
import axios from "axios";
export default {
	components: {
		Loader,
	},
	data() {
		return {
			name: "",
			loading: true,
			api: process.env.VUE_APP_API_URL,
		};
	},
	created() {
		const token = this.$route.params.token;
		const url = `${this.api}facebook/delete_request/${token}`;
		axios.get(url).then((response) => {
			const { data } = response;
			const { facebook } = data;
			if(facebook !== null && facebook.user !== null) {
                this.name = facebook.user.nombre;
            }
            this.loading = false
		});
	},
};
</script>

<style scoped lang="scss">
.facebook-container {
	width: 100%;
	height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5em;

	@media (max-width: 500px) {
		font-size: 1em;
	}

	.content {
		border: 3px solid purple;
		padding: 30px 30px;
		border-radius: 5px;
		max-width: 60%;
		background-color: rgba(128, 0, 128, 0.035);

		@media (max-width: 500px) {
			max-width: 90%;
		}
	}
}
</style>