<template>
    <div>
        <div class="container">
            <div
                v-if="pendiente"
                class="row content p-3 mt-5 shadow"
                style="margin-top:200px !important"
            >
                <div class="col-12 logo-desktop">
                    <img src="/img/logo.f4ba0fd0.svg" alt />
                </div>

                <div class="col-12 mt-3 icon text-center">
                    <i class="fas fa-check-circle" />
                </div>

                <div class="col-12 text-center mt-3 mb-5">
                    <h3>Operación pendiente!</h3>
                    <h5>Una vez que se confirme su pago, se le informará por correo electrónico.</h5>
                </div>

                <div class="col-12 text-center mt-2 mb-5">
                    <button class="mb-5 btn-close" @click="goHome">TERMINAR</button>
                </div>
            </div>

            <div
                v-if="exito"
                class="row content p-3 mt-5 shadow"
                style="margin-top:200px !important"
            >
                <div class="col-12 logo-desktop">
                    <img src="/img/logo.f4ba0fd0.svg" alt />
                </div>

                <div class="col-12 mt-3 icon text-center">
                    <i class="fas fa-check-circle" />
                </div>

                <div class="col-12 text-center mt-3 mb-5">
                    <h3>Operación realizada con éxito</h3>
                    <h5>La confirmación será enviada al correo ingresado</h5>
                </div>

                <div class="col-12 text-center mt-2 mb-5">
                    <button class="mb-5 btn-close" @click="goHome">TERMINAR</button>
                </div>
            </div>

            <div
                v-if="fallido"
                class="row content p-3 mt-5 shadow"
                style="margin-top:200px !important"
            >
                <div class="col-12 logo-desktop">
                    <img src="/img/logo.f4ba0fd0.svg" alt />
                </div>

                <div class="col-12 mt-3 icon text-center">
                    <i class="fas fa-exclamation-triangle" />
                </div>

                <div class="col-12 text-center mt-3 mb-5">
                    <h3>Operación fallida</h3>
                    <h5>Lamentablemente, no pudimos completar su pago. Puede volver a intentarlo accediendo a su reserva a través del panel haciendo clic en el botón a continuación.</h5>
                </div>

                <div class="col-12 text-center mt-2 mb-5">
                    <button class="mb-5 btn-close" @click="goDashboard">DASHBOARD</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
export default {
    props: [],
    components: {
        Loader
    },
    data() {
        return {
            exito: false,
            pendiente: false,
            fallido: false
        };
    },
    methods: {
        goHome() {
            this.$router.history.push("/");
        },
        goDashboard() {
            this.$router.history.push("/dashboard");
        }
    },
    created() {
        if (this.$route.params.status === "success") {
            this.exito = true;
        } else if (this.$route.params.status === "fail") {
            this.fallido = true;
        } else if (this.$route.params.status === "pending") {
            this.pendiente = true;
        } else {
            this.$router.history.push("/");
        }
    }
};
</script>

<style scoped>
.content {
    background: #fff;
    border-radius: 5px;
    color: $hf-sec-pine;
}

.content .logo-desktop img {
    width: 150px;
}

.content .icon svg {
    font-size: 150px;
}

.content .btn-close {
    border: 0;
    padding: 20px 90px;
    color: #fff;
    font-weight: bolder;
    background-color: $hf-sec-pine;
}

.logo-desktop {
    width: 100px;
    height: 100px;
}
</style>