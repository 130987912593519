import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router'
import modules from './store/index.js';

Vue.use(Vuex)

export default new Vuex.Store({
    modules,
	state: {
        loader: false,
        loadLocations: false,
		status: '',
		token: localStorage.getItem('token') || '',
		user: {
			idusuario: localStorage.getItem('idusuario'),
			correo: localStorage.getItem('correo'),
			nombre: localStorage.getItem('nombre'),
			apellido: localStorage.getItem('apellido'),
			imagen: localStorage.getItem('imagen'),
			is_admin: localStorage.getItem('is_admin'),
			telefono: localStorage.getItem('telefono'),
			dia: localStorage.getItem('dia'),
			mes: localStorage.getItem('mes'),
			ano: localStorage.getItem('ano'),
			pais_idpais: localStorage.getItem('pais_idpais'),
			sexo: localStorage.getItem('sexo'),
			biografia: localStorage.getItem('biografia'),
			moneda: localStorage.getItem('moneda'),
			pref_moneda: localStorage.getItem('pref_moneda')
		},
		alojamientos: {

		},
		/**
		 * Almacena los resultados de las busquedas del filtro principal del home.
		 */
		alojamientos_encontrados: [],
		alojamientos_encontrados_map: [],
		/**
		 * La ubicación buscada.
		 */
		selectedLocationText: '',
		selectedLat: null,
		selectedLong: null,
		filtros_opcionales: {

		},
		currency: {
			currency_code: "clp",
			prev: 0,
			current: 1
		},
		accomodation: {},
		language: localStorage.getItem('language'),
		iso639_1: localStorage.getItem('iso639_1'),
		pais: localStorage.getItem('pais'),
		ciudad: localStorage.getItem('ciudad'),
		accomodations: [],
		articles: [],
		articlesblog: [],
		article: {},
		blockdates: {
			checkin: {},
			checkout: {}
		},
		unavailable_dates: [],
		reservation: {},
		webPay: {},
		webPayCL: {},
		payPal: {},
		mercadoPago: {},
		hyfPago: {},
		userReservation: {},
		reservas: {},
		totalDays: 0,
		bankData: null,
		block_dates: [],
	},
	mutations: {
        setLoadLocation(state, payload) {
            state.loadLocations = payload
        },
		setLoading(state, payload) {
			state.loader = payload
		},
		setUsuario(state, payload) {
			state.user = payload
		},
        setUserImage(state, imagen) {
            state.user.imagen = imagen;
        },
		setAlojamientos(state, payload) {
			state.alojamientos = payload
		},
		setAccomodation(state, payload) {
			state.accomodation = payload
		},
		setLanguage(state, payload) {
			state.language = payload
		},
		setIso639_1(state, payload) {
			state.iso639_1 = payload
		},
		setPais(state, payload) {
			state.pais = payload
		},
		setCiudad(state, payload) {
			state.ciudad = payload
		},
		setMoneda(state, payload) {
			state.user.moneda = payload
		},
		setCurrency(state, payload) {
			state.currency = payload
		},
        // borrar
		setMonedaCambio(state, payload) {
			state.accomodation.tipo_moneda = payload
		},
		setSuccess(state, payload) {
			state.status = payload
		},
		setToken(state, payload) {
			state.token = payload
		},
		logout(state) {
			state.status = '',
				state.token = '',
				state.user = '',
				state.alojamientos = '',
				state.language = '',
				state.pais = '',
				state.ciudad = ''
		},
		setAccomodations(state, payload) {
			state.accomodations = payload
		},
		setArticles(state, payload) {
			state.articles = payload
		},
		setArticlesBlog(state, payload) {
			state.articlesblog = payload
		},
		setArticle(state, payload) {
			state.article = payload
		},
		setBlockdates(state, payload) {
			state.blockdates = payload
		},
		setBlock_dates(state, payload) {
			state.block_dates = payload
		},
		setUnavailabledates(state, payload) {
			state.unavailable_dates = payload
		},
		setReservation(state, payload) {
			state.reservation = payload
		},
		setUserReservation(state, payload) {
			state.userReservation = payload
		},
		setWebPay(state, payload) {
			state.webPay = payload
		},
		setWebPayCL(state, payload) {
			state.webPayCL = payload
		},
		setPayPal(state, payload) {
			state.payPal = payload
		},
		setMercadoPago(state, payload) {
			state.mercadoPago = payload
		},
		setHyfPago(state, payload) {
			state.hyfPago = payload
		},
		setSearchResults(state, response) {
			state.alojamientos_encontrados = response
		},
		setSearchResultsMap(state, response) {
			state.alojamientos_encontrados_map = response
		},
		setSelectedLocation(state, text) {
			state.selectedLocationText = text
		},
		setSelectedLat(state, text) {
			state.selectedLat = text
		},
		setSelectedLong(state, text) {
			state.selectedLong = text
		},
		setReservas(state, payload) {
			state.reservas = payload
		},
		setTotalDays(state, payload) {
			state.totalDays = payload
		},
		setBankData(state, payload) {
			state.bankData = payload
		}
	},
	actions: {
		login({ commit }, user) {
			return axios.post(process.env.VUE_APP_API_URL + 'users/login', user);
		},
		register({ commit }, user) {
			return axios.post(process.env.VUE_APP_API_URL + 'users/register', user);
		},
		checkUserData({ dispatch }) {
			return dispatch('getSession').then((response) => {
				if (
					response.correo != null &&
					response.telefono != null && response.telefono.length >= 9 &&
					response.biografia != null &&
					response.fecha_nacimiento != null &&
					response.sexo != null &&
					response.pais_idpais != null
					// response.imagen != null
				) {
					return true;
				} else {
					return false;
				}
			})
		},
		createSession({ commit, dispatch }, resp) {
			let sex = ''
			if (resp.data.user.sexo == 'f') {
				sex = 'Femenino'
			} else {
				sex = 'Masculino'
			}

			localStorage.setItem('usuario', JSON.stringify(resp.data.user));
			localStorage.setItem('idusuario', resp.data.user.idusuario)
			localStorage.setItem('nombre', resp.data.user.nombre)
			localStorage.setItem('apellido', resp.data.user.apellido)
			localStorage.setItem('imagen', resp.data.user.imagen)
			localStorage.setItem('is_admin', resp.data.user.is_admin)
			localStorage.setItem('telefono', resp.data.user.telefono)
			localStorage.setItem('pais_idpais', resp.data.user.pais_idpais)
			localStorage.setItem('sexo', sex)
			localStorage.setItem('biografia', resp.data.user.biografia)
			localStorage.setItem('token', resp.data.user.api_token)
			localStorage.setItem('pref_moneda', resp.data.user.pref_moneda)
			localStorage.setItem('moneda', '')
			localStorage.setItem('tipo_moneda', '')

			axios.defaults.headers.common['Authorization'] = resp.data.user.api_token
			commit('setUsuario', {
				email: resp.data.user.correo,
				idusuario: resp.data.user.idusuario,
				nombre: resp.data.user.nombre,
				apellido: resp.data.user.apellido,
				imagen: resp.data.user.imagen,
				is_admin: resp.data.user.is_admin,
				pais_idpais: resp.data.user.pais_idpais,
				sexo: sex,
				biografia: resp.data.user.biografia,
				telefono: resp.data.user.telefono,
				pref_moneda: resp.data.user.pref_moneda,
				moneda: '',
				tipo_moneda: ''
			})
			commit('setToken', resp.data.user.api_token)
			commit('setSuccess', 'success')
            if(resp.data.user.pais != null && resp.data.user.tipo != null) {
                commit('userNew/SET_USER_COUNTRY', resp.data.user.pais.nombre);
                commit('userNew/SET_USER_TYPE', resp.data.user.tipo);
            }
            
		},
		getSession() {
			return JSON.parse(localStorage.getItem('usuario'));
		},
		logout({ commit, dispatch }) {
			return new Promise(() => {
				commit('logout')
				localStorage.removeItem('correo')
				localStorage.removeItem('nombre')
				localStorage.removeItem('apellido')
				localStorage.removeItem('imagen')
				localStorage.removeItem('is_admin')
				localStorage.removeItem('telefono')
				localStorage.removeItem('dia')
				localStorage.removeItem('mes')
				localStorage.removeItem('ano')
				localStorage.removeItem('fecha_nacimiento')
				localStorage.removeItem('sexo')
				localStorage.removeItem('pais_idpais')
				localStorage.removeItem('biografia')
				localStorage.removeItem('token')
				localStorage.removeItem('language')
				localStorage.removeItem('pais')
				localStorage.removeItem('ciudad')
				localStorage.removeItem('pref_moneda')
				localStorage.removeItem('tipo_moneda')
				localStorage.removeItem('usuario')

				// dispatch('getAccomodationFeatured')

				delete axios.defaults.headers.common['Authorization']
				router.push({ name: 'home' })
				location.reload();

			})
		},
		changePreferLanguage({ commit }, language) {
			return axios.post(process.env.VUE_APP_API_URL + 'users/settings/setLanguage', language);
		},
		// getAlojamientos({ commit }) {
		// 	return new Promise(() => {
		// 		axios({ url: process.env.VUE_APP_API_URL + 'accommodations/searchByUser', method: 'GET' })
		// 			.then(resp => {
		// 				commit('setAlojamientos', resp.data.accomodations)
		// 			})
		// 	})
		// },
		createAccommodation({ commit }, accommodation) {
			return axios.post(process.env.VUE_APP_API_URL + 'accommodations/create', accommodation);
		},

		updateAccommodation({ commit }, accommodation) {
			return axios.post(process.env.VUE_APP_API_URL + 'accommodations/update', accommodation);
		},

		facebookLogin({ commit }, data) {
			return axios.post(process.env.VUE_APP_API_URL + 'users/login/facebook', data)
		},

		googleLogin({ commit }, data) {
			return axios.post(process.env.VUE_APP_API_URL + 'users/login/google', data)
		},

		registerAcommodation({ commit }, accomodation) {
			commit('setLoading', true)

			accomodation.gastoinmueble = accomodation.gastoinmueble == null ? 0 : accomodation.gastoinmueble;
			accomodation.serviciosb = accomodation.serviciosb == null ? 0 : accomodation.serviciosb;
			accomodation.gastos_comunes = accomodation.gastos_comunes == null ? 0 : accomodation.gastos_comunes;
			accomodation.limpieza = accomodation.limpieza == null ? 0 : accomodation.limpieza;
			accomodation.vdiario = accomodation.vdiario == null ? 0 : accomodation.vdiario;

			let accomodationdata = new FormData()
			accomodationdata.append('m2', accomodation.m2)
			accomodationdata.append('tiempo_min', accomodation.tiempo_min)
			accomodationdata.append('maximo_huespedes', accomodation.maximo_huespedes)
			accomodationdata.append('direccion', accomodation.full_name)
			accomodationdata.append('latitud', accomodation.direccion.latitude)
			accomodationdata.append('longitud', accomodation.direccion.longitude)
			accomodationdata.append('tipo', accomodation.tipo)
			accomodationdata.append('modalidad_tiempo', accomodation.modalidad_tiempo)
			accomodationdata.append('arriendo', accomodation.arriendo)
			accomodationdata.append('arriendo_diario', accomodation.vdiario)
			accomodationdata.append('gastos_comunes', accomodation.gastos_comunes)
			accomodationdata.append('reglas', accomodation.caracteristicas.rgenerales)
			accomodationdata.append('espacios_comunes', accomodation.caracteristicas.ecomunes)
			accomodationdata.append('uso_cocina', accomodation.caracteristicas.ucocina)
			accomodationdata.append('aseo_limpieza', accomodation.caracteristicas.alimpieza)
			accomodationdata.append('conserje', accomodation.caracteristicas.conserje)
			accomodationdata.append('timbre', accomodation.caracteristicas.timbre)
			accomodationdata.append('bodega', accomodation.caracteristicas.bodega)
			accomodationdata.append('estacionamiento_privado', accomodation.caracteristicas.eprivado)
			accomodationdata.append('estacionamiento_visita', accomodation.caracteristicas.evisita)
			accomodationdata.append('estacionamiento_bicicleta', accomodation.caracteristicas.ebicicleta)
			accomodationdata.append('sala_estar', accomodation.caracteristicas.sestar)
			accomodationdata.append('sala_eventos', accomodation.caracteristicas.seventos)
			accomodationdata.append('jardines', accomodation.caracteristicas.jardines)
			accomodationdata.append('piscina', accomodation.caracteristicas.piscina)
			accomodationdata.append('gimnasio_interno', accomodation.caracteristicas.gimnasio)
			accomodationdata.append('desayuno', accomodation.caracteristicas.desayuno)
			accomodationdata.append('refrigerador', accomodation.caracteristicas.refrigerador)
			accomodationdata.append('cocina', accomodation.caracteristicas.cocina)
			accomodationdata.append('microondas', accomodation.caracteristicas.microondas)
			accomodationdata.append('parrilla', accomodation.caracteristicas.parrilla)
			accomodationdata.append('horno', accomodation.caracteristicas.horno)
			accomodationdata.append('lavadora', accomodation.caracteristicas.lavadora)
			accomodationdata.append('secadora', accomodation.caracteristicas.secadora)
			accomodationdata.append('plancha', accomodation.caracteristicas.plancha)
			accomodationdata.append('limpieza', accomodation.caracteristicas.limpieza)
			accomodationdata.append('mascotas', accomodation.caracteristicas.mascotas)
			accomodationdata.append('paradero_micro', accomodation.caracteristicas.paradero)
			accomodationdata.append('estacion_metro', accomodation.caracteristicas.metro)
			accomodationdata.append('hospital', accomodation.caracteristicas.csalud)
			accomodationdata.append('supermercado', accomodation.caracteristicas.supermercado)
			accomodationdata.append('banco', accomodation.caracteristicas.banco)
			accomodationdata.append('zona_nocturna', accomodation.caracteristicas.znocturna)
			accomodationdata.append('areas_verdes', accomodation.caracteristicas.averdes)
			accomodationdata.append('centro_comercial', accomodation.caracteristicas.comercial)
			accomodationdata.append('centro_civico', accomodation.caracteristicas.civico)
			accomodationdata.append('gimnasio_externo', accomodation.caracteristicas.gimnasioe)
			accomodationdata.append('hombres', accomodation.caracteristicas.hombres)
			accomodationdata.append('mujeres', accomodation.caracteristicas.mujeres)
			accomodationdata.append('ninos', accomodation.caracteristicas.ninos)
			accomodationdata.append('personal_servicio', accomodation.caracteristicas.pservicio)
			accomodationdata.append('tercera_edad', accomodation.caracteristicas.tedad)
			accomodationdata.append('perros', accomodation.caracteristicas.perros)
			accomodationdata.append('gatos', accomodation.caracteristicas.gatos)
			accomodationdata.append('otras_mascotas', accomodation.caracteristicas.omascotas)
			accomodationdata.append('sobre_nosotros', accomodation.caracteristicas.snosotros)
			accomodationdata.append('habitacion', accomodation.fotos.habitacion)
			accomodationdata.append('habitacion2', accomodation.fotos.habitacion2)
			accomodationdata.append('bano', accomodation.fotos.bano)
			accomodationdata.append('cocina1', accomodation.fotos.cocina1)
			accomodationdata.append('cocina2', accomodation.fotos.cocina2)
			accomodationdata.append('living', accomodation.fotos.living)
			accomodationdata.append('comedor', accomodation.fotos.comedor)
			accomodationdata.append('otro', accomodation.fotos.otro)
			accomodationdata.append('pieza', JSON.stringify(accomodation.pieza))
			accomodationdata.append('language', 'es')
			accomodationdata.append('tipo_moneda', accomodation.tipo_moneda)
			return new Promise(() => {
				axios({ url: process.env.VUE_APP_API_URL + 'accommodations/register', data: accomodationdata, method: 'POST' })
					.then(resp => {
						commit('setSuccess', 'success')
						commit('setLoading', false)
						router.push({ name: 'Success' })
					})
			})
		},
		getPais({ commit }) {
			fetch('https://api.ipgeolocation.io/ipgeo?apiKey=' + process.env.VUE_APP_GEOAPIKEY_URL)
				.then(function (response) {
					response.json().then(function (data) {
						let language = data.country_code2
						let pais = data.country_name
						let ciudad = data.country_capital
						localStorage.setItem('language', language)
						localStorage.setItem('pais', pais)
						localStorage.setItem('ciudad', ciudad)
						commit('setLanguage', language)
						commit('setPais', pais)
						commit('setCiudad', ciudad)
						commit('setSuccess', 'success')

						fetch('https://restcountries.eu/rest/v2/alpha/' + language)
							.then(function (response) {
								response.json().then(function (data) {
									let iso639_1 = data.languages[0].iso639_1
									localStorage.setItem('iso639_1', iso639_1)
									commit('setIso639_1', iso639_1)
									commit('setSuccess', 'success')
								});
							})

					});
				})
		},
		getCountries() {
			return axios.get(process.env.VUE_APP_API_URL + 'countries');
		},
		// cambioIdioma({ commit, dispatch }) {
		// 	// TODO: Refactorizar. Eliminar y definir un observer en los componentes
		// 	if (this.state.iso639_1 === 'es') {
		// 		commit('setIso639_1', 'en')
		// 		localStorage.setItem('iso639_1', 'en')
		// 		dispatch('getArticles', { cant: 3, lang: 'en' })
		// 		dispatch('getArticlesBlog', { cant: 6, lang: 'en' })
		// 		dispatch('getDetailArticle', { lang: 'en' })
		// 	} else {
		// 		commit('setIso639_1', 'es')
		// 		localStorage.setItem('iso639_1', 'es')
		// 		dispatch('getArticles', { cant: 3, lang: 'es' })
		// 		dispatch('getArticlesBlog', { cant: 6, lang: 'es' })
		// 		dispatch('getDetailArticle', { lang: 'es' })
		// 	}
		// },
		actualizarPerfil({ commit, dispatch }, user) {

			return new Promise((resolve, reject)  => {
				axios({ url: process.env.VUE_APP_API_URL + 'users/update', data: user, method: 'PUT' },
					{ headers: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then(resp => {

                    let sex = ''
                    if (resp.data.user.sexo == 'f') {
                        sex = 'Femenino'
                    } else {
                        sex = 'Masculino'
                    }

                    let fecha = ''

                    fecha = resp.data.user.fecha_nacimiento.split("-")

                    localStorage.setItem('correo', resp.data.user.correo)
                    localStorage.setItem('nombre', resp.data.user.nombre)
                    localStorage.setItem('apellido', resp.data.user.apellido)
                    localStorage.setItem('imagen', resp.data.user.imagen)
                    localStorage.setItem('is_admin', resp.data.user.is_admin)
                    localStorage.setItem('telefono', resp.data.user.telefono)
                    localStorage.setItem('dia', fecha[2])
                    localStorage.setItem('mes', fecha[1])
                    localStorage.setItem('ano', fecha[0])
                    localStorage.setItem('sexo', sex)
                    localStorage.setItem('pais_idpais', resp.data.user.pais_idpais)
                    localStorage.setItem('biografia', resp.data.user.biografia)

                    commit('setUsuario', {
                        correo: resp.data.user.correo,
                        nombre: resp.data.user.nombre,
                        apellido: resp.data.user.apellido,
                        imagen: resp.data.user.imagen,
                        is_admin: resp.data.user.is_admin,
                        telefono: resp.data.user.telefono,
                        dia: fecha[2], mes: fecha[1], ano: fecha[0],
                        pais_idpais: resp.data.user.pais_idpais,
                        sexo: sex,
                        biografia: resp.data.user.biografia
                    })

                    dispatch('createSession', resp);

                    commit('setSuccess', 'success')

                    resolve(resp);
                })
                .catch(error => {
                    reject(error);
                });
			})

		},
		actualizarPassword({ commit }, user) {

			let userdata = new FormData()
			userdata.append('contrasena', user.contrasena)
			userdata.append('contrasenan', user.contrasenan)
			userdata.append('contrasenar', user.contrasenar)

			return new Promise(() => {
				axios({ url: process.env.VUE_APP_API_URL + 'users/changePassword', data: userdata, method: 'POST' })
					.then(resp => {
						commit('setSuccess', 'success')
						alert('Contraseña actualizada correctamente')
					})
			})
		},
		eliminarAlojamiento({ commit }, id) {

			let mensaje = ''
			if (this.state.iso639_1 === 'es') {
				mensaje = 'Seguro que quieres eliminar tu alojamiento ' + id + '?'
			} else {
				mensaje = 'Surely you want to delete your accommodation ' + id + '?'
			}
			if (confirm(mensaje)) {
				commit('setLoading', true)
				let alojamientodata = {
					idalojamiento: id
				}

				return new Promise(() => {
					axios({ url: process.env.VUE_APP_API_URL + 'accommodations/deleteAccomodation', data: alojamientodata, method: 'DELETE' },
						{ headers: { "Content-Type": "application/x-www-form-urlencoded" } })
						.then(resp => {
							commit('setSuccess', 'success')
							return new Promise(() => {
								axios({ url: process.env.VUE_APP_API_URL + 'accommodations/searchByUser', method: 'GET' })
									.then(resp => {
										commit('setSuccess', 'success')
										commit('setLoading', false)
										commit('setAlojamientos', resp.data.accomodations)
									})
							})
						})
				})
			}
		},
		wishList({ commit }, id) {
			let alojamientodata = {
				alojamiento_idalojamiento: id
			}
			return new Promise(() => {
				axios({ url: process.env.VUE_APP_API_URL + 'wishList/change', data: alojamientodata, method: 'POST' },
					{ headers: { "Content-Type": "application/x-www-form-urlencoded" } })
					.then(resp => {
						return new Promise(() => {
							axios({ url: process.env.VUE_APP_API_URL + 'accommodations/searchByUser', method: 'GET' })
								.then(resp => {
									commit('setAlojamientos', resp.data.accomodations)
								})
						})
					})
			})
		},
		clonar({ commit }, id) {

			let mensaje = ''
			if (this.state.iso639_1 === 'es') {
				mensaje = 'Seguro que quieres clonar tu alojamiento ?'
			} else {
				mensaje = 'Are you sure you want to clone your accommodation?'
			}
			if (confirm(mensaje)) {
				commit('setLoading', true)
				let alojamientodata = new FormData()
				alojamientodata.append('idalojamiento', id)

				return new Promise(() => {
					axios({ url: process.env.VUE_APP_API_URL + 'accommodations/clone', data: alojamientodata, method: 'POST' })
						.then(resp => {
							commit('setSuccess', 'success')


							return new Promise(() => {
								axios({ url: process.env.VUE_APP_API_URL + 'accommodations/searchByUser', method: 'GET' })
									.then(resp => {
										commit('setAlojamientos', resp.data.accomodations)
										commit('setSuccess', 'success')
										commit('setLoading', false)
									})
							})
						})
				})
			}
		},
		// getAlojamiento({ commit, dispatch }) {
		// 	commit('setLoading', true)
		// 	axios({ url: process.env.VUE_APP_API_URL + 'accommodations/getAccomodation/' + router.currentRoute.params.idalojamiento, method: 'GET' })
		// 		.then(resp => {
		// 			if (resp.data.accomodation !== null) {
		// 				commit('setAccomodation', {
		// 					nombre: resp.data.accomodation.nombre,
		// 					m2: resp.data.accomodation.m2,
		// 					modalidad_tiempo: resp.data.accomodation.modalidad_tiempo,
		// 					arriendo_diario: resp.data.accomodation.arriendo_diario,
		// 					maximo_huespedes: resp.data.accomodation.maximo_huespedes,
		// 					direccion: resp.data.accomodation.direccion,
		// 					latitud: resp.data.accomodation.latitud,
		// 					longitud: resp.data.accomodation.longitud,
		// 					tipo: resp.data.accomodation.tipo,
		// 					arriendo: resp.data.accomodation.arriendo,
		// 					gastos_comunes: resp.data.accomodation.gastos_comunes,
		// 					reglas: resp.data.accomodation.reglas,
		// 					espacios_comunes: resp.data.accomodation.espacios_comunes,
		// 					uso_cocina: resp.data.accomodation.uso_cocina,
		// 					aseo_limpieza: resp.data.accomodation.aseo_limpieza,
		// 					conserje: resp.data.accomodation.conserje,
		// 					timbre: resp.data.accomodation.timbre,
		// 					bodega: resp.data.accomodation.bodega,
		// 					eprivado: resp.data.accomodation.estacionamiento_privado,
		// 					evisita: resp.data.accomodation.estacionamiento_visita,
		// 					ebicicleta: resp.data.accomodation.estacionamiento_bicicleta,
		// 					sestar: resp.data.accomodation.sala_estar,
		// 					seventos: resp.data.accomodation.sala_eventos,
		// 					jardines: resp.data.accomodation.jardines,
		// 					piscina: resp.data.accomodation.piscina,
		// 					gimnasio_interno: resp.data.accomodation.gimnasio_interno,
		// 					desayuno: resp.data.accomodation.desayuno,
		// 					refrigerador: resp.data.accomodation.refrigerador,
		// 					cocina: resp.data.accomodation.cocina,
		// 					horno: resp.data.accomodation.horno,
		// 					microondas: resp.data.accomodation.microondas,
		// 					parrilla: resp.data.accomodation.parrilla,
		// 					lavadora: resp.data.accomodation.lavadora,
		// 					secadora: resp.data.accomodation.secadora,
		// 					plancha: resp.data.accomodation.plancha,
		// 					limpieza: resp.data.accomodation.limpieza,
		// 					mascotas: resp.data.accomodation.mascotas,
		// 					paradero: resp.data.accomodation.paradero_micro,
		// 					metro: resp.data.accomodation.estacion_metro,
		// 					hospital: resp.data.accomodation.hospital,
		// 					supermercado: resp.data.accomodation.supermercado,
		// 					banco: resp.data.accomodation.banco,
		// 					zona_nocturna: resp.data.accomodation.zona_nocturna,
		// 					areas_verdes: resp.data.accomodation.areas_verdes,
		// 					centro_comercial: resp.data.accomodation.centro_comercial,
		// 					centro_civico: resp.data.accomodation.centro_civico,
		// 					gimnasio_externo: resp.data.accomodation.gimnasio_externo,
		// 					hombres: resp.data.accomodation.hombres,
		// 					mujeres: resp.data.accomodation.mujeres,
		// 					ninos: resp.data.accomodation.ninos,
		// 					personal_servicio: resp.data.accomodation.personal_servicio,
		// 					tercera_edad: resp.data.accomodation.tercera_edad,
		// 					perros: resp.data.accomodation.perros,
		// 					gatos: resp.data.accomodation.gatos,
		// 					otras_mascotas: resp.data.accomodation.otras_mascotas,
		// 					sobre_nosotros: resp.data.accomodation.sobre_nosotros,
		// 					piezas: resp.data.accomodation.piezas,
		// 					idalojamiento: resp.data.accomodation.idalojamiento,
		// 					rooms: resp.data.accomodation.room,
		// 					visitas: resp.data.accomodation.visitas,
		// 					eliminado: resp.data.accomodation.eliminado,
		// 					aprobado: resp.data.accomodation.aprobado,
		// 					verificado: resp.data.accomodation.verificado,
		// 					external_reference: resp.data.accomodation.external_reference
		// 				})
		// 				dispatch('getUnavailabledates');
		// 			} else {
		// 				commit('setAccomodation', {
		// 					status_code: resp.data.message,
		// 				})
		// 			}
		// 			commit('setLoading', false)
		// 		})
		// },
		setMoneda({ commit }, moneda) {
			localStorage.setItem('moneda', moneda.cambio)
			localStorage.setItem('currency_selected', moneda.moneda)
			commit('setMoneda', moneda.cambio)
			let current = this.state.currency.current;
			let currency = {
				currency_code: moneda.moneda,
				current: moneda.cambio,
				prev: current
            }
			commit('setCurrency', currency)
		},
        // borrar 
		setMonedaCambio({ commit }, tipo_moneda) {
			localStorage.setItem('tipo_moneda', tipo_moneda)
			commit('setMonedaCambio', tipo_moneda)
		},
		getAccomodationFeatured({ commit }) {
			return axios({ url: process.env.VUE_APP_API_URL + 'accommodations/getAccomodationFeatured', method: 'GET' });
		},
		getArticles({ commit, state }, { cant }) {
			let url = '';
			console.log('STORE GET ARTICLES')
			if (this.state.iso639_1 === 'es') {
				url = 'https://blog.houseandflats.com/es/wp-json/wp/v2/posts/?per_page=' + cant + '&orderby=date'
			} else {
				url = 'https://blog.houseandflats.com/wp-json/wp/v2/posts/?per_page=' + cant + '&orderby=date'
			}
			axios({ url: url, method: 'GET' })
				.then(resp => {
					commit('setArticles', resp.data)
				})
		},
		getArticlesBlog({ commit, state }, { cant }) {
			let url = '';
			if (this.state.iso639_1 === 'es') {
				url = 'https://blog.houseandflats.com/es/wp-json/wp/v2/posts/?per_page=' + cant + '&orderby=date'
			} else {
				url = 'https://blog.houseandflats.com/wp-json/wp/v2/posts/?per_page=' + cant + '&orderby=date'
			}
			axios({ url: url, method: 'GET' })
				.then(resp => {
					commit('setArticlesBlog', resp.data)
				})
		},
		async getDetailArticle({ commit, state }) {
			let url = '';
			if (this.state.iso639_1 === 'es') {
				url = 'https://blog.houseandflats.com/es/wp-json/wp/v2/posts/' + router.currentRoute.params.id + ''
			} else {
				url = 'https://blog.houseandflats.com/wp-json/wp/v2/posts/' + router.currentRoute.params.id + ''
			}
			await fetch(url)
				.then(function (response) {
					response.json().then(function (data) {
						commit('setArticle', data)
					});
				})
		},
		
        async getUrlMP({ commit }, data) {
			await axios({ url: process.env.VUE_APP_API_URL + 'mercadopago/pay', data: data, method: 'POST' })
				.then(resp => {
					commit('setMercadoPago', resp.data)
				}).catch((value) => {
				})
		},

		async getUrlPP({ commit }, data) {
			await axios({ url: process.env.VUE_APP_API_URL + 'paypal/pay', data: data, method: 'POST' })
				.then(resp => {
					commit('setPayPal', resp.data)
				})
		},
		async getReservaInitiated({ commit }, data) {
			await axios.get(process.env.VUE_APP_API_URL + 'reservations/initiated/' + data.idreserva + "/" + data.token)
				.then(resp => {
				})
		},
		async rejectReserva({ commit }, data) {
			await axios.put(process.env.VUE_APP_API_URL + 'reservations/reject/' + data.idreserva + "/" + data.token)
				.then(resp => {
				})
		},
		createRestriction({ commit, dispatch }, data) {
			data.idalojamiento = router.currentRoute.params.id;
			return axios.post(process.env.VUE_APP_API_URL + 'restrictions/store', data);
		},
		async createReservation({ commit }, data) {
			let reservation = new FormData();
			Object.keys(data).forEach((item) => {
				reservation.append(item, data[item]);
			});
			await axios({ url: process.env.VUE_APP_API_URL + 'reservations', data: reservation, method: 'POST' })
				.then((resp) => {
					commit('setReservation', resp.data.reservation)
					commit('setAccomodation', resp.data.accommodation)
					commit('setTotalDays', resp.data.totalDays)

				})
		},
		getUnavailabledates({ commit }) {
			axios({ url: process.env.VUE_APP_API_URL + 'accommodations/unavailable_dates/' + router.currentRoute.params.idalojamiento, method: 'GET' })
				.then(resp => {
					commit('setUnavailabledates', resp.data)
				})
		},
		async updateReservation({ commit }, data) {
			await axios.put(process.env.VUE_APP_API_URL + 'reservations/' + data.idreserva, data)
				.then((resp) => {

					commit('setReservation', resp.data.reservation)
					commit('setAccomodation', resp.data.accommodation)
				})
		},

		async updateTipoPago({ commit }, data) {
			await axios.put(process.env.VUE_APP_API_URL + 'reservations/' + data.idreserva + '/tipopago', data)
				.then((resp) => {

					commit('setReservation', resp.data.reservation)
					commit('setAccomodation', resp.data.accommodation)
				})
		},
		async createPaymentTransfer({ commit }, data) {
			await axios.post(process.env.VUE_APP_API_URL + 'pago/transfer', data)
				.then(resp => {
					commit('setHyfPago', resp.data)
				})
		},
		async cancelReservation({ commit }, idreserva) {
			await axios.delete(process.env.VUE_APP_API_URL + 'reservations/' + idreserva)
		},
		async initPaymentProccess({ commit }, payload) {
			await axios.put(process.env.VUE_APP_API_URL + 'reservations/initpayment/' + payload.idreserva, {coupon: payload.coupon }).then((value) => {
			});
		},
		async getReservation({ commit }, data) {
			await axios.get(process.env.VUE_APP_API_URL + 'reservations/' + data.idreserva + '/' + data.token).then((response) => {
				commit('setReservation', response.data.reservation)
				commit('setUserReservation', response.data.guest)
			});
		},
		// async getReservas({ commit }) {
		// 	await axios.get(process.env.VUE_APP_API_URL + 'reservations').then((response) => {
		// 		commit('setReservas', response.data.data)
		// 	});
		// },
		async paymentByTransfer({ commit }, data) {
			let url = process.env.VUE_APP_API_URL + 'pago/transfer/' + data.id + "/" + data.token;
			await axios.get(url).then((value) => {
				return value;
			})
		},
        async paymentByGlobal66({ commit }, data) {
            let url = process.env.VUE_APP_API_URL + 'pago/global66/' + data.id + "/" + data.token;
            await axios.get(url).then((value) => {
                return value;
            })
        },
		search({ commit }, params) {
			return axios({ url: process.env.VUE_APP_API_URL + 'search_alojamiento', params: params, method: 'GET' })
				.then(response => {
					commit('setSearchResults', response.data)
					localStorage.setItem("alojamientos_encontrados", JSON.stringify(response.data))
					const tipo_alojamiento = params.tipo_alojamiento.join(',')
					var direccion_completa = ''
					if (params.comuna) {
						direccion_completa = params.comuna + ',' + params.country
					} else {
						direccion_completa = params.comuna
					}

					// const numero_huespedes = params.numero_huespedes.join(','
					router.push({
						name: 'buscar',
						params: {
							latitud: params.latitud,
							longitud: params.longitud,
							lngNorthEast: params.lngNorthEast,
							latNorthEast: params.latNorthEast,
							lngSouthWest: params.lngSouthWest,
							latSouthWest: params.latSouthWest,
							type_route: params.type_route,
							country: params.country,
							fecha_inicio: params.fecha_inicio,
							fecha_termino: params.fecha_termino,
							precio_min: params.precio_min,
							precio_max: params.precio_max,
							comuna: params.comuna,
							tipo_alojamiento: tipo_alojamiento,
							direccion_completa: direccion_completa
						}
					})
				}).catch(error => {

				})
		},
		searchMapAll({ commit }, params = {}) {
			axios({ url: process.env.VUE_APP_API_URL + 'search_alojamiento_all', params: params, method: 'GET' })
				.then(response => {
					commit('setSearchResultsMap', response.data)
				})
		},
		setSelectedLocation({ commit }, locationText) {
			commit('setSelectedLocation', locationText)
			localStorage.setItem("selectedLocationText", locationText)
		},
		setSelectedLat({ commit }, lat) {
			commit('setSelectedLat', lat)
			localStorage.setItem("selectedLat", lat)
		},
		setSelectedLong({ commit }, long) {
			commit('setSelectedLong', long)
			localStorage.setItem("selectedLong", long)
		},
		getRestriction({ commit }) {
			return axios({ url: process.env.VUE_APP_API_URL + 'restrictions/' + router.currentRoute.params.id, method: 'GET' });

		},
		deleteRestriction({ commit, dispatch }, idalojamiento) {
			return axios({ url: process.env.VUE_APP_API_URL + 'restrictions/delete/' + idalojamiento, method: 'DELETE' });
		},
		getAlojamientoMap({ commit }, idalojamiento) {
			commit('setLoading', true)
			axios({ url: process.env.VUE_APP_API_URL + 'accommodations/getAccomodation/' + idalojamiento, method: 'GET' })
				.then(resp => {
					commit('setAccomodation', {
						nombre: resp.data.accomodation.nombre, m2: resp.data.accomodation.m2, modalidad_tiempo: resp.data.accomodation.modalidad_tiempo, arriendo_diario: resp.data.accomodation.arriendo_diario, maximo_huespedes: resp.data.accomodation.maximo_huespedes,
						direccion: resp.data.accomodation.direccion, latitud: resp.data.accomodation.latitud, longitud: resp.data.accomodation.longitud,
						tipo: resp.data.accomodation.tipo, arriendo: resp.data.accomodation.arriendo, gastos_comunes: resp.data.accomodation.gastos_comunes,
						reglas: resp.data.accomodation.reglas, espacios_comunes: resp.data.accomodation.espacios_comunes, uso_cocina: resp.data.accomodation.uso_cocina,
						aseo_limpieza: resp.data.accomodation.aseo_limpieza, conserje: resp.data.accomodation.conserje, timbre: resp.data.accomodation.timbre,
						bodega: resp.data.accomodation.bodega, estacionamiento_privado: resp.data.accomodation.estacionamiento_privado, estacionamiento_visita: resp.data.accomodation.estacionamiento_visita,
						estacionamiento_bicicleta: resp.data.accomodation.estacionamiento_bicicleta, sala_estar: resp.data.accomodation.sala_estar, sala_eventos: resp.data.accomodation.sala_eventos,
						jardines: resp.data.accomodation.jardines, piscina: resp.data.accomodation.piscina, gimnasio_interno: resp.data.accomodation.gimnasio_interno,
						desayuno: resp.data.accomodation.desayuno, refrigerador: resp.data.accomodation.refrigerador, cocina: resp.data.accomodation.cocina,
						horno: resp.data.accomodation.horno, microondas: resp.data.accomodation.microondas, parrilla: resp.data.accomodation.parrilla,
						lavadora: resp.data.accomodation.lavadora, secadora: resp.data.accomodation.secadora, plancha: resp.data.accomodation.plancha,
						limpieza: resp.data.accomodation.limpieza, mascotas: resp.data.accomodation.mascotas, paradero_micro: resp.data.accomodation.paradero_micro,
						estacion_metro: resp.data.accomodation.estacion_metro, hospital: resp.data.accomodation.hospital, supermercado: resp.data.accomodation.supermercado,
						banco: resp.data.accomodation.banco, zona_nocturna: resp.data.accomodation.zona_nocturna, areas_verdes: resp.data.accomodation.areas_verdes,
						centro_comercial: resp.data.accomodation.centro_comercial, centro_civico: resp.data.accomodation.centro_civico, gimnasio_externo: resp.data.accomodation.gimnasio_externo,
						hombres: resp.data.accomodation.hombres, mujeres: resp.data.accomodation.mujeres, ninos: resp.data.accomodation.ninos,
						personal_servicio: resp.data.accomodation.personal_servicio, tercera_edad: resp.data.accomodation.tercera_edad, perros: resp.data.accomodation.perros,
						gatos: resp.data.accomodation.gatos, otras_mascotas: resp.data.accomodation.otras_mascotas,
						sobre_nosotros: resp.data.accomodation.sobre_nosotros, piezas: resp.data.accomodation.piezas, idalojamiento: resp.data.accomodation.idalojamiento
					})
					commit('setLoading', false)
				})
		},
	},
	getters: {
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status,
        isAdmin: state => state.user.is_admin == "1",
	}
})
