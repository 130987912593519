<template>
    <div>
        
        <!-- Dropdown for desktop -->
        <div class="dropdown desktop-only">
           
            <!-- Dropdown button-->
            <button class="dropdown-toggle" data-toggle="dropdown">
                {{ currentLangText }}
            </button>

            <!-- Dropdown list -->
            <div class="dropdown-menu">
                <a v-for="(lang, index) in langs" 
                   :key="index" 
                   class="dropdown-item"
                   href="#"
                   @click.prevent="changeLang(lang.code)">
                    {{ lang.text }}
                </a>
            </div>
            
        </div>

        <!-- Select for mobile -->
        <select v-model="selectedLang" class="select form-control mobile-only">
            <option v-for="(lang, index) in langs" 
                    :key="index" 
                    :value="lang.code" >
                {{ lang.text }}
            </option>
        </select>
        
    </div>
</template>

<script>
export default {
    name: "LocaleChanger",
    props: {
        bgwhite: Boolean,
    },
    data() {
        return {
            langs: [
                { code: "es", text: "ESP" },
                { code: "en", text: "ENG" },
            ],
            selectedLang: "",
        };
    },
    computed: {
        currentLangText() {
            const lang = this.langs.find((l) => l.code === this.selectedLang);
            return lang ? lang.text : "";
        },
    },
    methods: {
        changeLang(langCode) {
            this.selectedLang = langCode;
            this.$i18n.locale = langCode;
            this.$store.state.iso639_1 = langCode;
            localStorage.setItem("iso639_1", langCode);
            if (this.$store.getters.isLoggedIn) {
                this.$store.dispatch("changePreferLanguage", { language: langCode });
            }
        },
    },
    created() {
        const storedLang = localStorage.getItem("iso639_1");
        this.selectedLang = storedLang ? storedLang : this.$i18n.locale;
    },
    
    watch: {
        selectedLang: function (newVal) {
            this.changeLang(newVal);
        },
    },
};
</script>

<style scoped lang="scss">

// Desktop (dropdown)
.dropdown {
    position: relative;
    .dropdown-toggle {
        height: $desktop-header-height;
        background-color: $hf-primary !important;
        box-shadow: none !important;
        border: none;
        cursor: pointer;
        color: #fff;
        
        &:hover {
            font-weight: bold;
        }
    }
    .dropdown-item {
        color: $hf-primary;
        &:hover,
        &:focus {
            color: #fff;
            background-color: $hf-primary;
        }
    }
}

// Mobile (select)
.select {
    appearance: none;
    background-color: #fff;
    color: $hf-primary;
    border: none;
    box-shadow: none !important;
    padding: 0 8px;
}
</style>
