<template>
    <div>
        
        <!-- Dropdown for desktop -->
        <div class="dropdown desktop-only">

            <!-- Dropdown button-->
            <button class="dropdown-toggle" data-toggle="dropdown" >
                $ {{ visitorCurrency }}
            </button>

            <!-- Dropdown list -->
            <div class="dropdown-menu">
                <a v-for="(currency, index) in currencyConversionTable"
                   :key="index"
                   class="dropdown-item"
                   href="#" 
                   @click.prevent="changeCurrency(currency.moneda)">
                    $ {{ currency.moneda }}
                </a>
            </div>
            
        </div>

        <!-- Select for mobile -->
        <select v-model="selectedCurrency" class="select form-control mobile-only">
            <option v-for="(currency, index) in currencyConversionTable"
                    :key="index"
                    :value="currency.moneda" >
                $ {{ currency.moneda }}
            </option>
        </select>
        
        <!-- Info banner -->
        <div v-if="showCurrencyBanner" class="row currency-banner shadow">
            <div class="close col-1" @click="closeCurrencyBanner">
                <i class="fas fa-times"></i>
            </div>
            <div class="col-11">
                <div class="title">
                    {{ $t('currency-switcher.banner-title') }}
                </div>
                <div class="message">
                    {{ $t('currency-switcher.banner-message') }}
                </div>    
            </div>
            
        </div>
        
    </div>
</template>

<script>

import { mapState, mapActions } from "vuex";

export default {

    name: "CurrencySwitcher",

    data() {
        return {
            defaultVisitorCurrency: "USD",
            selectedCurrency: null,
            showCurrencyBanner: false,
        };
    },

    computed: {
        ...mapState('exchange', [
            "currencyConversionTable",
            "visitorCurrency",
            "currencyRate",
        ]),
    },

    methods: {
        ...mapActions('exchange', [
            "fetchCurrencyConversionTable",
            "setVisitorCurrency",
        ]),
        
        changeCurrency(currency) {
            this.selectedCurrency = currency;
        },
        checkFirstVisit() {
            if (localStorage.getItem('hasSeenCurrencyBanner') !== 'true') {
                this.showCurrencyBanner = true;
            }
        },
        closeCurrencyBanner() {
            this.showCurrencyBanner = false;
            localStorage.setItem('hasSeenCurrencyBanner', 'true');
        }
    },    

    created() {

        this.fetchCurrencyConversionTable()

            .then(() => {

                let localStorageVisitorCurrency = localStorage.getItem("visitorCurrency");

                // If there is no visitor currency set, set it to default, otherwise set it to the one in local storage
                if (!localStorageVisitorCurrency) {
                    localStorageVisitorCurrency = this.defaultVisitorCurrency;
                    localStorage.setItem("visitorCurrency", localStorageVisitorCurrency);
                }

                // Set the visitor currency to the store
                this.setVisitorCurrency(localStorageVisitorCurrency);

                // Set the menu to the selected currency
                this.selectedCurrency = localStorageVisitorCurrency;

                // Check if it's the user's first visit
                this.checkFirstVisit();
            });

    },

    watch: {
        selectedCurrency: {
            handler(newCurrency) {
                this.setVisitorCurrency(newCurrency);
                localStorage.setItem("visitorCurrency", newCurrency);
            }
        }
    },

};
</script>


<style scoped lang="scss">

// Desktop (dropdown)
.dropdown {
    .dropdown-toggle {
        height: $desktop-header-height;
        background-color: $hf-primary !important;
        box-shadow: none !important;
        border: none;
        cursor: pointer;
        color: #fff;

        &:hover {
            font-weight: bold;
        }
    }
    .dropdown-item {
        color: $hf-primary;
        &:hover,
        &:focus {
            color: #fff;
            background-color: $hf-primary;
        }
    }
}

// Mobile (select)
.select {
    appearance: none;
    background-color: #fff;
    color: $hf-primary;
    border: none;
    box-shadow: none !important;
    padding: 0 8px;
}

.currency-banner {
    
    text-align: left;
    position: absolute;
    display: flex;
    align-items: center;
    color: #ffffff;
    background-color: $hf-sec-pine;
    width: 280px;
    padding: 20px 10px 20px 20px;
    font-size: 0.9rem;
    border-radius: $border-radius-new;
    
    .title {
        font-weight: bold;
    }
    .message {
        font-weight: normal;
    }
    .close {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        cursor: pointer;
        color: #ffffff;
    }
    
    transform: translate(10%, 3%);
    @media (min-width: $md) {
        transform: translate(-100%,-25%);
    }
}


</style>