<template>
    <div>
        <div class="container">
            <div class="row mt-5 p-5 shadow content-info" :style="estilo">
                <div class="col-12 spinners">
                    <b-spinner type="grow" label="Loading..."></b-spinner>
                    <b-spinner type="grow" label="Loading..."></b-spinner>
                    <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>

                <div class="col-12 informations mt-4">
                    <p>{{ mensaje[$i18n.locale] }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    props: ["item", "estilo", "mensaje"]
};
</script>

<style>
.content-info {
    background-color: #ffffff;
    border-radius: 3px;
    transition: max-height 4s;
    font: Bold 17px/17px Lato;
    line-height: 25px;
    margin: 3px;
}

.spinners {
    text-align: center;
    color: $hf-sec-pine;
}

.spinners span {
    width: 20px;
    height: 20px;
    margin: 0 20px;
}

.informations {
    text-align: center;
    color: #003D29;
}
</style>