const state = {
    madeReservations: {
        data: [],
        paginate: {
            total: 0,
            current_page: 1,
            per_page: 50,
        },
    },
    receivedReservations: {
        data: [],
        paginate: {
            total: 0,
            current_page: 1,
            per_page: 50,
        },
    },
};

export default state;