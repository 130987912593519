<template>
	<div class="popup-pictures" v-if="!hidePopUp">
		<div class="effect" />
		<div class="content">
			<div class="header">
                {{$t('popups.pictures-popup.welcome')}}
            </div>
			<div class="popup-body">
				<div>
					{{$t('popups.pictures-popup.text1')}}
				</div>
				<div>
                    {{$t('popups.pictures-popup.text2')}}
                </div>
				<div>
					{{$t('popups.pictures-popup.text3')}}
				</div>
				<div class="checkbox">
					<input
						type="checkbox"
						name="show"
						v-model="checkbox"
					/>
					{{$t('popups.pictures-popup.dont-show')}}
				</div>
			</div>
			<div class="controls">
				<button class="btn btn-confirmar" @click="handleConfirm">{{$t('popups.pictures-popup.confirm')}}</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			checkbox: false,
			hidePopUp: false,
		};
	},
	methods: {
		handleConfirm() {
			if (this.checkbox === true) {
				localStorage.setItem("popup_pictures", true);
			}
			this.hidePopUp = true;
		},
	},
	created() {
        this.hidePopUp = localStorage.getItem("popup_pictures");
	},
};
</script>

<style lang="scss" scoped>
.popup-pictures {
	position: fixed;
	z-index: 100000000000;
	width: 100%;
	height: 100vh;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	.effect {
		width: 100%;
		height: 100%;
		background: #000;
		opacity: 0.6;
		position: absolute;
		left: 0;
		top: 0;
	}

	.content {
		background: #fff;
		padding: 5px;
		border-radius: 5px;
		position: relative;
		z-index: 2;

		.header {
			border-bottom: 1px solid #e3e3e3;
			padding: 15px 25px 15px 25px;
			color: #6d6d6d;
			font-weight: 600;
			margin-bottom: 15px;
		}

		.popup-body {
			display: flex;
			justify-content: space-around;
			flex-direction: column;
			padding: 15px;
			div {
				color: #6d6d6d;
				padding: 5px;
			}

			.checkbox {
				font-weight: bold;
				padding-top: 20px;
				input {
					margin-right: 15px;
				}
			}
		}

		.controls {
			text-align: right;
			padding: 10px;

			.btn-confirmar {
				background: $hf-sec-pine;
				color: #fff;
				margin: 0 !important;
				margin-top: 0px !important;
			}
		}
	}
}

@media (max-width: 600px) {
    .popup-pictures {
        .content {
            max-width: 90%;
        }
    }
}
</style>