<template>
    <div class="blog-container container" data-view>
        <div class="blog-container__title">
            <img src="./../../assets/mundo.svg" class="blog-container__img" alt="">
            Blog House And Flats
        </div>
        <Owl
            v-if="articles.length"
            :responsive="{0:{items:1},900:{items:3}}"
            :autoplay="true"
            :nav="false"
            :dots="true"
        >
            <div :key="article.id" v-for="article in articles">
                <Article
                    v-if="article.better_featured_image"
                    :link="article.link"
                    :id="article.id"
                    :title="article.title.rendered"
                    :descrip="article.excerpt.rendered"
                    :image="article.better_featured_image.media_details.sizes.medium.source_url"
                />
            </div>
        </Owl>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Owl from "vue-owl-carousel";

import Article from "@/components/Blog/Article";

export default {
    components: {
        Owl,
        Article
    },
    methods: {
        ...mapActions(["getArticles"])
    },
    computed: {
        ...mapState(["articles"])
    },
    created() {
        window.scrollTo(0,0);
        this.getArticles({ cant: 3 });        
    }
};
</script>

<style scoped lang="scss">
.blog-container {
    margin-top: 50px;

    &__title {
        text-align: center;
        font-size: 2em;
    }

    &__img{
        width: 60px;
    }
}
</style>
