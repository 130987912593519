<template>
    <div class="container">

        <h1>Reservations</h1>

        <div class="row title text-center p-3">
            <div class="col-2">Reservation id</div>
            <div class="col-2">Accommodation id</div>
            <div class="col-2">Status</div>
            <div class="col-2">Paid</div>
            <div class="col-4">Actions</div>
        </div>

        <div class="row text-center p-3 line" v-for="reservation in reservations.data" :key="reservation.idreserva">
            
            <!-- reservation id -->
            <div class="col-2">
                <a href="#" @click="openReservationModal(reservation)">
                    {{ reservation.idreserva }}
                </a>
            </div>

            <!-- accommodation id -->
            <div class="col-2">
                <router-link :to="getAccommodationRoute(reservation)" target="_blank">
                    {{ reservation.alojamiento_idalojamiento }}
                </router-link>
            </div>

            <!-- status -->
            <div class="col-2">{{ reservation.status }}</div>

            <!-- paid -->
            <div class="col-2">{{ reservation.fecha_pago !== null ? 'Yes' : 'No' }}</div>

            <!-- actions -->
            <div class="col-4">
                
                <b-button @click="openPaidConfirmationModal(reservation)" class="set-paid-btn mr-1"
                    :disabled="reservation.fecha_pago !== null || reservation.status == 'canceled'">
                    {{ getPaidButtonText(reservation) }}
                </b-button>

                <b-button @click="openCancelConfirmationModal(reservation)" class="set-paid-btn"
                          :disabled="reservation.fecha_pago !== null || reservation.status == 'canceled'">
                    Cancel
                </b-button>
                
            </div>
        </div>

        <Paginator
            ref="paginator"
            :items="reservations" 
            :fetchMethod="fetchReservations" 
            :additionalParams="{ 'type': 'received' }" 
        />
        
        <b-modal v-model="showPaidConfirmationModal" title="Set as paid" @ok="confirmManualPayment">
            Are you sure you want to set the reservation as paid? This action cannot be undone.
        </b-modal>

        <b-modal v-model="showCancelConfirmationModal" title="Cancel reservation" @ok="confirmReservationCancel">
            Are you sure you want to cancel this reservation? This action cannot be undone.
        </b-modal>

        <b-modal v-if="selectedReservation" v-model="showReservationModal" title="Reservation Details">
            
            <p class="font-weight-bold">Datos de la reserva:</p>
            
            <p>Id reserva: {{ selectedReservation.idreserva }}</p>
            <p>Id alojamiento ID: {{ selectedReservation.alojamiento_idalojamiento }}</p>
            <p>Estado: {{ selectedReservation.status }}</p>
            <p>Inicio: {{ selectedReservation.fecha_inicio }}</p>
            <p>Fin: {{ selectedReservation.fecha_termino }}</p>
            <p>Moneda: {{ selectedReservation.moneda }}</p>
            <p>Monto: {{ selectedReservation.costo }}</p>
            <p>Garantía: {{ selectedReservation.garantia }}</p>
            <p>Comisión: {{ selectedReservation.comision }}</p>
            
            <hr>
            <p class="font-weight-bold">Datos del anfitrión:</p>
            
            <p>Nombre: {{ selectedReservation.accommodation.user.nombre }} {{ selectedReservation.accommodation.user.apellido }}</p>
            <p>Teléfono: {{ selectedReservation.accommodation.user.telefono }} </p>
            <p>Email: {{ selectedReservation.accommodation.user.correo }} </p>
            
            <hr>
            <p class="font-weight-bold">Datos del huesped:</p>
            <p>Nombre: {{ selectedReservation.user.nombre }} </p>
            <p>Teléfono: {{ selectedReservation.user.telefono }} </p>
            <p>Email: {{ selectedReservation.user.correo }} </p>
            

            <template #modal-footer="{ cancel, ok }">
                <b-button variant="primary" @click="showReservationModal = false">Cerrar</b-button>
            </template>
        </b-modal>

    </div>
</template>

  
<script>
import { mapState, mapActions } from "vuex";
import Paginator from "@/components/Paginator/newPaginator";

export default {
    name: "Reservations",
    components: {
        Paginator
    },
    data() {
        return {
            'showPaidConfirmationModal': false,
            'showCancelConfirmationModal': false,
            'showReservationModal': false,
            'selectedReservation': null,
        };
    },
    computed: {
        ...mapState("adminReservation", [
            "reservations"
        ]),
    },
    methods: {
        ...mapActions("adminReservation", [
            "fetchReservations",
            "setReservationAsPaid",
            "setReservationAsCanceled"
        ]),

        openPaidConfirmationModal(reservation) {
            if (reservation.fecha_pago === null) {
                this.reservation = reservation;
                this.showPaidConfirmationModal = true;
            }
        },

        openCancelConfirmationModal(reservation) {
            if (reservation.fecha_pago === null) {
                this.reservation = reservation;
                this.showCancelConfirmationModal = true;
            }
        },

        confirmManualPayment() {
            this.setReservationAsPaid(this.reservation);

            // Refetch reservations
            this.$refs.paginator.refetch();
            
            this.showPaidConfirmationModal = false;
        },

        confirmReservationCancel() {
            this.setReservationAsCanceled(this.reservation);
            
            // Refetch reservations
            this.$refs.paginator.refetch();
            
            this.showCancelConfirmationModal = false;
        },


        getPaidButtonText(reservation) {
            return reservation.fecha_pago === null ? 'Set as Paid' : 'Already paid';
        },
        
        getAccommodationRoute(reservation) {
            return { name: 'detalle-alojamiento', params: { idalojamiento: reservation.alojamiento_idalojamiento, start_date: null, end_date: null } };
        },

        openReservationModal(reservation) {
            this.selectedReservation = reservation;
            this.showReservationModal = true;
        }

    },
};
</script>
  
<style lang="scss" scoped>
.container {
    padding: 40px;

    .title {
        background: $hf-sec-pine;
        color: #fff;
        border-radius: 5px 5px 0 0;
        font-weight: bolder;
    }

    .line:nth-child(even) {
        background: #d6cae4a3;
    }

    .line:nth-child(odd) {
        background: rgb(235, 235, 235);
    }

    .line:last-child {
        border-radius: 0 0 5px 5px;
    }

    .fa-toggle-on {
        color: green;
    }

    .fa-toggle-off {
        color: red;
    }

    .set-paid-btn:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }

}
</style>