import * as types from './types';

export default {
    setVersionChecked({ commit }, versionChecked) {
        commit(types.SET_APP_VERSION_CHECKED, versionChecked);
    },
    setAppOutOfDate({ commit }, outOfDate) {
        commit(types.SET_APP_OUT_OF_DATE, outOfDate);
    },
};
