<template>
    <div class="container">

        <div v-if="place.tipo === 'cowork'" class="top-info">{{$t('wizard.s8.features-info-cowork')}}:</div>
        <div v-else-if="place.tipo === 'camper'" class="top-info">{{$t('wizard.s8.features-info-camper')}}:</div>
        <div v-else class="top-info">{{$t('wizard.s4.features-info')}}:</div>

        <hr />

        <b v-if="place.tipo === 'cowork'" >{{$t('wizard.s8.house-rules-cowork')}}</b>
        <b v-else-if="place.tipo === 'camper'" >{{$t('wizard.s8.house-rules-camper')}}</b>
        <b v-else >{{$t('wizard.s8.house-rules')}}</b>

        <div>{{$t('wizard.s8.rules-info')}}</div>

        <div class="mt-4" v-for="(acc, index) in accommodation.caracteristicas" :key="index">

            <span v-if="place.tipo === 'camper' && index === 'espacios_comunes'">{{$t('wizard.s8.common-spaces-cowork')}}</span>
            <span v-else-if="place.tipo === 'camper' && index === 'uso_cocina'">{{$t('wizard.s8.kitchen-cowork')}}</span>
            <span v-else>{{$t(acc.title)}}</span>

            <textarea
                v-model="accommodation.caracteristicas[index].value"
                :class="{'is-invalid': accommodation.caracteristicas[index].error}"
                :placeholder="
                    place.tipo === 'camper' && index === 'espacios_comunes' ?
                    $t('wizard.s8.common-spaces-cowork-placeholder') : place.tipo === 'camper' && index === 'uso_cocina' ?
                    $t('wizard.s8.kitchen-cowork-placeholder') :
                    $t(acc.placeholder)
                "
                class="mt-1 form-control"
            ></textarea>

            <div
                class="text-danger error"
                v-if="accommodation.caracteristicas[index].error"
            >{{$t('wizard.s8.field-required')}}</div>
        </div>  
        <hr/>
        <FooterWizard prev next v-if="place.aprobado === 0"/>      
        <FooterWizard prev finish v-else />      
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FooterWizard from './FooterWizard';
export default {
    name: "Step8Wizard",
    props: {
        data: {
            type: Object,
            default: null,
        },
        place: {
            Object,
            default: null
        }
    },
    components: {
        FooterWizard
    },
    data() {
        return {
            submitted: false,
            accommodation: {
                caracteristicas: {
                    reglas: {
                        value: "",
                        required: true,
                        error: false,
                        placeholder: "wizard.s8.general-rules-placeholder",
                        title: "wizard.s8.general-rules",
                    },
                    espacios_comunes: {
                        value: "",
                        required: true,
                        error: false,
                        placeholder: "wizard.s8.common-spaces-placeholder",
                        title: "wizard.s8.common-spaces",
                    },
                    uso_cocina: {
                        value: "",
                        required: true,
                        error: false,
                        placeholder: "wizard.s8.kitchen-placeholder",
                        title: "wizard.s8.kitchen",
                    },
                    aseo_limpieza: {
                        value: "",
                        required: true,
                        error: false,
                        placeholder: "wizard.s8.cleaning-placeholder",
                        title: "wizard.s8.cleaning",
                    }
                }
            }
        };
    },
    methods: {
        validate() {
            let error = 0;
            this.clearErrors();
            Object.keys(this.accommodation.caracteristicas).forEach((item) => {
                if(this.accommodation.caracteristicas[item].value === "" || this.accommodation.caracteristicas[item].value === null) {
                    this.accommodation.caracteristicas[item].error = true;
                    error++;
                }
            });
            return error;
        },
        clearErrors() {
            Object.keys(this.accommodation.caracteristicas).forEach((item) => {
                this.accommodation.caracteristicas[item].error = false;
            });
        },
        nextStep() {
            if(!this.validate()) {
                let step = {};
                Object.keys(this.accommodation.caracteristicas).forEach((item) => {
                    step[item]= this.accommodation.caracteristicas[item].value;
                });
                this.$emit('next', step);
            }            
        },

        save() { 
            if(!this.validate()) {
                let step = {};
                Object.keys(this.accommodation.caracteristicas).forEach((item) => {
                    step[item]= this.accommodation.caracteristicas[item].value;
                });
                this.$emit('save', step);
            }         
        },
        prevStep() {
            this.$emit('prev');
        }
    },
    created() {
        window.scrollTo(0, 0);
        if(this.data != null) {
            console.log('Step 08', this.data);
            Object.keys(this.accommodation.caracteristicas).forEach((item) => {
                let value = JSON.parse(this.data[item]);
                this.accommodation.caracteristicas[item].value = value.es;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.top-info {
    padding-top: 10px;
}

.container {
    textarea {
        height: 150px;
    }
}
</style>