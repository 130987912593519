<template>
    <div class="col-6 col-md-3">
        <div class="benefit">
            <div class="circle">
                <img :src="require(`@/assets/benefits/${image}`)" />
            </div>
            <div class="title">{{ $t(title) }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Benefit",
    props: {
        title: String,
        image: String
    }
};
</script>

<style lang="scss" scoped>

.benefit {
    text-align: center;
    padding: 20px 0px;
    border: 1px solid $hf-sec-pine;
    border-radius: 5px;
    height: 100%;
}

.circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $hf-sec-pine;;
    margin: auto;
    padding-top: 15px;

    img {
        width: 50px;
    }
}

.title {
    font-size: 0.8em;
    color: $hf-sec-pine;;
    padding: 20px 10px 0 10px;
}

</style>