<template>
    <div class="container">
        <h3>{{$t('accommodation.location')}}</h3>

        <GmapMap
            :center="{lat:Number(data.latitud), lng:Number(data.longitud)}"
            ref="mapRef"
            :zoom="16"
            map-type-id="roadmap"
            style="width: auto; height: 250px"
            :options="options"
        >
            <GmapMarker
                :position="{lat:Number(data.latitud), lng:Number(data.longitud)}"
            />
        </GmapMap>
    </div>
</template>

<script>

export default {
    name: "DetalleAlojamientoLocation",
    props: ["data"],
    data() {
        return {
            options: {
                backgroundColor: "#003D29",
                disableDefaultUI: false,
                scrollwheel: true,
                minZoom: 2
            },
            url: process.env.VUE_APP_S3_URL
        };
    }
};
</script>

<style lang="scss" scoped>
.container {
    margin-top: 25px;
    margin-bottom: 80px;
    padding-left: 0;
    padding-right: 0;
}
</style>
