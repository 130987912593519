<template>
    <b-card no-body class="mb-4">
        <b-card-header header-tag="header" class="p-0" role="tab">
            <b-button
                block
                href="#"
                :aria-expanded="visible ? 'true' : 'false'"
                :aria-controls="'collapse-' + num"
                @click="visible = !visible"
                variant="info"
                id="room-tab-button"
            >{{title}} N° {{num}}</b-button>
        </b-card-header>
        <b-collapse v-model="visible" :id="'collapse-' + num" accordion="my-accordion" role="tabpanel">
            <b-card-body class="my-4">
                <div class="row">
                    <div class="col-md-4" v-for="(item, i) in roomData" :key="`s7-${i}`">
                        <div class="item">
                            <img :src="require(`@/assets/alojamiento/${item.icon}.svg`)" />
                            <div>{{itens[item.text]}}</div>
                            <button :id="`${item.field}`" type="button" @click="validar">
                                <i class="fas fa-chevron-down" />
                            </button>
                            <input
                                type="number"
                                name="number"
                                min="0"
                                max="100"
                                v-model="room[item.field]"
                            />
                            <button type="button" @click="room[item.field]++" :id="`${item.field}-more`">
                                <i class="fas fa-chevron-up" />
                            </button>
                        </div>
                    </div>
                </div>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import roomData from "./room-data.json";

export default {
    props: {
        num: {
            default: 0,
        },
        title: {
            default: "",
        },
        itens: {
            default: 0,
        },
        data: {
            type: Object,
            default: null
        }
    },
    name: "Room",
    data() {
        return {
            roomData,
            visible: this.num == 1,
            room: {
                idpieza: null,
                cama_2_plazas: 0,
                bano_compartido: 0,
                escritorio: 0,
                ac: 0,
                cama_1_media_plaza: 0,
                cama_1_plaza: 0,
                bano_privado: 0,
                ropa_cama: 0,
                calefaccion	: 0,
                camarote: 0,
                velador: 0,
                wifi: 0,
                secador: 0,
                sofa_cama: 0,
                closet: 0,
                tv: 0,
                colgadores: 0,
                ventilador: 0
            }
        };
    },
    methods: {
        validar(event) {
            const id = event.target.id;
            if (!this.room[id] <= 0) {
                this.room[id]--;
            }
        }
    },
    created() {
        if(this.data != null) {
            Object.keys(this.room).forEach((item) => {
                this.room[item] = this.data[item];
            })
        }
    }
};
</script>

<style scoped lang="scss">
.top-info {
    padding-top: 10px;
}

.item {
    text-align: center;
    margin: 20px 0px;

    img {
        width: 40px;
    }
    button {
        width: 40px;
        border: none;
        background-color: white;
        outline: 0;
        margin: 0;
    }

    input {
        border: none;
        text-align: center;
        padding-left: 12px;
    }

    .fa-chevron-down {
        pointer-events: none;
    }
}

.btn.btn-info.btn-block {
    background-color: $hf-sec-pine;
    border: $hf-sec-pine;
}
</style>