async function getPolygonCoords(searchLocation) {

    const formattedLocation = formatLocationName(searchLocation);
    
    try {
        const url = `https://nominatim.openstreetmap.org/search.php?q=${encodeURIComponent(formattedLocation)}&polygon_geojson=1&format=jsonv2`;
        const response = await fetch(url).catch(error => console.error('Error with fetch:', error));
        const data = await response.json();

        if (data.length > 0) {
            const firstResult = data[0];  // Get the first result
            const coordinates = firstResult.geojson;
            
            let arrayOfCoords = [];
            if (coordinates.type === 'Polygon') {
                arrayOfCoords = coordinates.coordinates.map(coord => {
                    return coord;
                });
            } else if (coordinates.type === 'MultiPolygon') {
                arrayOfCoords = coordinates.coordinates.map(coord => {
                    return coord[0];
                });
            }

            return arrayOfCoords.map(coords => {
                return convertCoordinates(coords);
            });

        }
    } catch (error) {
        console.error("Error fetching the polygon:", error);
        return null;
    }
}

function convertCoordinates(coordsArray) {
    return coordsArray.map(coordinate => {
        return {
            lng: coordinate[0],
            lat: coordinate[1]
        };
    });
}

function formatLocationName (searchLocation) {

    const searchLocationString = searchLocation.comuna + ',' + searchLocation.country;
    
    const parts = searchLocationString.split(',');

    if (parts.length >= 3) {
        const trimmedParts = parts.map(part => part.trim());
        return `${trimmedParts[1]}, ${trimmedParts[0]}, ${trimmedParts[2]}`;
    } else {
        return searchLocationString;
    }
}

function createPolygons(map, polygonCoords) {

    return polygonCoords.map(coords => {

        let p = new google.maps.Polygon({
            paths: coords,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#ffbcbc",
            fillOpacity: 0.15,
        });

        p.setMap(map);

        return p;

    });
}

export async function drawPolygons(searchLocation, map) {
    try {
        const polygonCoords = await getPolygonCoords(searchLocation);
        return createPolygons(map, polygonCoords);
    } catch (error) {}
}

export function removePolygons(polygons) {
    try {
        if (polygons.length && polygons.length > 0) {
            polygons.map(polygon => {
                polygon.setMap(null);
            });
        }
    } catch (error) {}
}