<template>
        <a :href="whatsappUrl" target="_blank" class="whatsapp-chat">
            <img src="@/assets/whatsapp.png" />
        </a>
</template>

<script>
import { whatsappBot } from '@/config';

export default {
    name: 'WhatsappChat',
    computed: {
        whatsappUrl() {
            const phoneNumber = whatsappBot.phone;
            const message = encodeURIComponent('Hola, necesito ayuda con...'); // Reemplaza esto con tu mensaje predeterminado
            return `https://wa.me/${phoneNumber}?text=${message}`;
        },
    },
};
</script>

<style lang="scss" scoped>

.whatsapp-chat {
    position: fixed;
    bottom: 70px;
    right: 15px;
    width: 50px;
    height: 50px;
    z-index: 5;
}

@media (min-width: $md) {
    .whatsapp-chat {
        bottom: 40px;
        right: 50px;
    }
}

img {
    height: 100%;
    width: 100%;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4));
}

</style>