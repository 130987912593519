import { render, staticRenderFns } from "./DetalleAlojamientoTabs.vue?vue&type=template&id=7e745986&scoped=true"
import script from "./DetalleAlojamientoTabs.vue?vue&type=script&lang=js"
export * from "./DetalleAlojamientoTabs.vue?vue&type=script&lang=js"
import style0 from "./DetalleAlojamientoTabs.vue?vue&type=style&index=0&id=7e745986&prod&scoped=true&lang=scss"
import style1 from "./DetalleAlojamientoTabs.vue?vue&type=style&index=1&id=7e745986&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e745986",
  null
  
)

export default component.exports