<template>
    <div id="clusterInfoWindow">
        <div class="carousel">
            
            <ol class="carousel-indicators">
                <li v-for="(slide, index) in slideData" :class="index == currentIndex ? 'active' : ''" ></li>
            </ol>

            <div class="carousel-inner">
                <div v-for="(slide, index) in slideData" 
                     :key="index" 
                     class='carousel-item' 
                     :class="{ 'active' : index === currentIndex }"
                     @click="openAccommodation(slide.id)"
                >
                    
                    <img :src="imagePath + slide.photo.url" />
                    <div class="name">Alojamiento {{index + 1}}: {{ slide.name }}</div>
                    
                </div>
            </div>

            <button class="carousel-control-prev" type="button" @click="prevSlide">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </button>
            <button class="carousel-control-next" type="button" @click="nextSlide">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </button>

        </div>
    </div>
</template>
  
<script>
import axios from "axios"

export default {
    props: {
        accommodations: Array,
        locale: String,
        currentSlide: null,
    },
    data() {
        return {
            slideData: [],
            imagePath: 'https://s3-sa-east-1.amazonaws.com/' + 'static.houseandflats.com/',
            currentIndex: 0,
        };
    },
    methods: {

        openAccommodation(slideId) {
            window.open(`/alojamiento/${slideId}`, '_blank');
        },

        prevSlide() {

            const lastIndex = this.slideData.length - 1;

            // Calcula el índice del slide anterior
            this.currentIndex = this.currentIndex > 0 ? this.currentIndex - 1 : lastIndex;

            this.currentSlide = this.slideData[this.currentIndex];
        },

        nextSlide() {

            const lastIndex = this.slideData.length - 1;

            // Calcula el índice del próximo slide
            this.currentIndex = this.currentIndex < lastIndex ? this.currentIndex + 1 : 0;
            
            this.currentSlide = this.slideData[this.currentIndex];

        },
        
        getAccommodationName(accommodation) {
            return JSON.parse(accommodation.nombre)[this.locale];
        },

        getAccommodationMainPhoto(accommodation) {
            
            return axios({
                url:
                    process.env.VUE_APP_API_URL +
                    "galleries/searchByAccommodation/" +
                    accommodation.idalojamiento,
                method: "GET",
            }).then((resp) => {
                const accommodationGallery = resp.data.photos;
                return accommodationGallery[0];
            });
        },

        async getAccommodationsslideData() {

            const slideDataPromises = this.accommodations.map(async (accommodation) => {
                const id = accommodation.idalojamiento;
                const name = this.getAccommodationName(accommodation);
                const photo = await this.getAccommodationMainPhoto(accommodation);
                return { id, name, photo };
            });

            // Espera a que todas las promesas se completen
            this.slideData = await Promise.all(slideDataPromises);
        },
    },

    mounted() {
        this.getAccommodationsslideData();
    }
};
</script>
  

<style lang="scss" scoped>

    #clusterInfoWindow {
        color: #000000;
        text-align: center;
    }

    .carousel {
        max-width: 350px;
        max-height: 350px;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
    }

    .carousel-inner {
        display: flex;
        transition: transform 0.6s ease;
        width: 100%;
    }

    .carousel-item {
        cursor: pointer;
    }

    .carousel-item img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .carousel-item .name {
        margin-top: 10px;
        font-size: 1rem;
        text-align: center;
        color: $grey-75;
    }

    .carousel-control-prev,
    .carousel-control-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        border: none;
        padding: 10px;
        cursor: pointer;
    }

    .carousel-control-prev {
        left: 10px;
    }

    .carousel-control-next {
        right: 10px;
    }

    .carousel-indicators {
        top: 10px;
        bottom: auto;
    }

</style>