<template>
    <div class="validated-phone-input-main-div">

        <!-- Field Label -->
        <label :for="fieldName" class="form-label">{{ label }}</label>

        <div class="input-group">
            
            <!-- Country prefix -->
            <select
                v-model="selectedCountry"
                :class="['form-control', 'input', { 'is-invalid': hasErrors }]"
                @change="coutryChanged"
            >
                <option v-for="option in countryOptions" :key="option.value" :value="option.value">
                    {{ option.text }}
                </option>
            </select>

            <!-- Phone number input -->
            <input
                :type="type"
                :id="fieldName"
                v-model="localModel"
                :class="['form-control', 'input', { 'is-invalid': hasErrors }]"
                :placeholder="placeholder"
                @input="clearError"
            />
        </div>

        <!-- Error message -->
        <div class="invalid-feedback">
            <div v-if="apiError">{{ apiError }}</div>
            <div v-else-if="hasErrors" v-for="rule in errorRules" :key="rule">
                {{ getErrorMessage(rule) }}
            </div>
        </div>

    </div>
</template>

<script>
import countriesData from "@/assets/data/users-countries-data.json";

export default {
    props: {
        fieldName: {
            type: String,
            required: true
        },
        value: {
            required: true
        },
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        validationObject: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: 'text'
        },
        input: {
            type: String,
            default: 'input'
        },
        apiError: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            countries: countriesData,
            selectedCountry: null,
        };
    },
    computed: {
        localModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        countryOptions() {
            return this.countries.map(country => ({
                value: country.value,
                text: `${country.name}`
            }));
        },
        currentValidation() {
            return this.validationObject;
        },
        errorRules() {
            if (!this.currentValidation || !this.currentValidation.$params) return [];
            return Object.keys(this.currentValidation.$params).filter(rule => this.currentValidation.$error && !this.currentValidation[rule]);
        },
        hasErrors() {
            return this.errorRules.length > 0 || this.apiError;
        }
    },
    watch: {
        value(newVal) {
            this.localModel = newVal;
            this.findPrefix(newVal);
        }
    },
    methods: {
        getErrorMessage(rule) {
            const messagePath = `validations.${rule}`;
            const params = this.currentValidation.$params[rule] || {};
            return this.$t(messagePath, params);
        },
        clearError() {
            this.$emit('clear-error', this.fieldName);
        },
        findPrefix(phoneNumber) {
            const country = this.countries.find(c => phoneNumber.startsWith(`+${c.value}`));
            if (country) {
                this.selectedCountry = country.value;
            }
        },
        coutryChanged() {
            this.localModel = "+" + this.selectedCountry;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/new-design/styles.scss";

.validated-phone-input-main-div {
    margin-bottom: 10px;
}

</style>
