<template>
    <div data-view>
        <div class="container about-container">
            <h1 class="title">{{$t('about.about-us')}}</h1>
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div v-html="$t('about.content')" />
                </div>
                <div class="col-12 col-sm-6">
                    <div class="info-border">
                        <div class="info-border-top" v-html="$t('about.content-2')" />
                        <div style="font-size:15px">
                            <div>{{$t('about.business-name')}}</div>
                            <div>{{$t('about.line-business')}}</div>
                            <div>{{$t('about.address')}}</div>
                            <div>
                                {{$t('about.phone')}}:
                                <a href="tel:56976934003">+56 9 76934003</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row justify-content-center text-center py-5 mb-5">
                        <div class="col-6 col-md-3 mt-4">
                            <img
                                src="@/assets/about/img-hyf-5.png"
                                class="img-fluid rounded-circle"
                                alt
                            />
                            <h4>Cristián Senerman</h4>
                            <!-- <div class="about-role">{{$t('about.logistic-operations')}}</div> -->
                        </div>

                        <div class="col-6 col-md-3 mt-4">
                            <img
                                src="@/assets/about/img-hyf-3.png"
                                class="img-fluid rounded-circle"
                                alt
                            />
                            <h4>Emma Barra</h4>
                            <!-- <div class="about-role">{{$t('about.logistic-operations')}}</div> -->
                        </div>

                        <div class="col-6 col-md-3 mt-4">
                            <img
                                src="@/assets/about/img-hyf-6.png"
                                class="img-fluid rounded-circle"
                                alt
                            />
                            <h4>Martín Campos</h4>
                            <!-- <div class="about-role">{{$t('about.logistic-operations')}}</div> -->
                        </div>

                        <div class="col-6 col-md-3 mt-4">
                            <img
                                src="@/assets/about/img-hyf-2.png"
                                class="img-fluid rounded-circle"
                                alt
                            />
                            <h4>Alexaris Marin</h4>
                            <!-- <div class="about-role">{{$t('about.logistic-operations')}}</div> -->
                        </div>
                        
                    </div>

                    <div class="row justify-content-center text-center py-5 mb-5">
                        <div class="col-6 col-md-3 mt-4">
                            <img
                                src="@/assets/about/img-hyf-4.png"
                                class="img-fluid rounded-circle"
                                alt
                            />
                            <h4>Hernán Senerman</h4>
                            <!-- <div class="about-role">{{$t('about.logistic-operations')}}</div> -->
                        </div>

                        <div class="col-6 col-md-3 mt-4">
                            <img
                                src="@/assets/about/img-hyf-7.png"
                                class="img-fluid rounded-circle"
                                alt
                            />
                            <h4>Yann Zambrano</h4>
                            <!-- <div class="about-role">{{$t('about.logistic-operations')}}</div> -->
                        </div>

                        <div class="col-6 col-md-3 mt-4">
                            <img
                                src="@/assets/about/img-hyf-1.png"
                                class="img-fluid rounded-circle"
                                alt
                            />
                            <h4>Jorge Cisternas</h4>
                            <!-- <div class="about-role">{{$t('about.logistic-operations')}}</div> -->
                        </div>

                        <div class="col-6 col-md-3 mt-4">
                            <img
                                src="@/assets/about/img-hyf-8.png"
                                class="img-fluid rounded-circle"
                                alt
                            />
                            <h4>Alberto Mattos</h4>
                            <!-- <div class="about-role">{{$t('about.logistic-operations')}}</div> -->
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        window.scrollTo(0,0);
    }
}
</script>

<style scoped lang="scss">
.about-container {
    margin-top: 50px;

    .title {
        margin-bottom: 50px;
    }

    .info-border {
        border: 1px solid purple;
        padding: 10px;

        .info-border-top {
            border-bottom: 1px solid purple;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
    }
}

h1 {
    text-align: center;
    font-weight: 900;
    letter-spacing: 0;
    color: #003D29;
    text-transform: uppercase;
    opacity: 1;
}

h4 {
    padding-top: 20px;
}

.about-role {
    line-height: 1.2em;
    font-size: 0.7em;
}
</style>