<template>
    <b-modal
        v-model="showModal"
        class="price-details-modal"
        id="modal-price-details"
        size="md"
        ok-only
        hide-header
        @hidden="handleHidden"
    >
        
        <div class="price-details-modal__content">
            
            <h5 class="price-details-modal__title">{{ $t("simulator.payment-details-title") }}</h5>
            
            <div>

                <p v-if="simulation.totalDays >= 30">
                    {{
                        $t('simulator.payment-details-message-monthly', {
                            finalPrice: finalPrice,
                            rentalWarranty: rentalWarranty,
                            rentalCommission: rentalCommission,
                        })
                    }}
                </p>

                <p v-else>
                    {{
                        $t('simulator.payment-details-message-daily', {
                            finalPrice: finalPrice,
                            rentalStay: rentalStay,
                            rentalCommission: rentalCommission,
                        })
                    }}
                </p>

            </div>
        </div>

        <!-- Footer -->
        <template #modal-footer>
            <div class="w-100 text-center">
                <b-button class="ok-button" @click="handleHidden">{{ $t("simulator.accept")}}</b-button>
            </div>
        </template>
        
    </b-modal>
</template>

<script>
import { mapState } from "vuex";
import Vue from 'vue';
export default {
    name: "SimulationDetailsModal",
    props: {
        showModal: Boolean,
        simulation: Object,
        accommodationCurrency: String,
    },
    computed: {
        ...mapState("exchange", [
            'visitorCurrency',
        ]),
        rentalWarranty() {
            return this.formatPrice({ 'amount': this.simulation.warranty, 'currency': this.accommodationCurrency })
        },
        rentalCommission() {
            return  this.formatPrice({ 'amount': this.simulation.commission, 'currency': this.accommodationCurrency })
        },
        rentalStay() {
            return  this.formatPrice({ 'amount': this.simulation.stay, 'currency': this.accommodationCurrency })
        },
        finalPrice() {
            const total = this.simulation.warranty + this.simulation.commission + this.simulation.stay;
            return  this.formatPrice({'amount': total, 'currency': this.accommodationCurrency})
        },
    },
    methods: {
        handleHidden() {
            this.$emit("hide", false);
        },
        formatPrice(amount) {
            return Vue.filter('convertAndFormatPrice')(amount);
        },
    }
};
</script>

<style scoped lang="scss">

.price-details-modal__title {
    margin-bottom: 1rem;
}

.price-details-modal__content {
    padding: 1rem;
}


/* Custom variant for the button */
.ok-button {
    background-color: $hf-sec-pine !important;
    border: none;
    border-radius: $border-radius;
    padding: 10px 20px;
}
</style>
