<template>
    <div class="container">
            <!-- Not logged options -->
            <template v-if="!isLoggedIn">
                <div class="px-3">
                    <router-link :to="{ name: 'contact' }">{{$t('contact.contact-us')}}</router-link>
                </div>
<!--                <div class="px-3">-->
<!--                    <router-link :to="{ name: 'register', params: { step: 1 } }">{{$t('main-menu.be-host')}}</router-link>-->
<!--                </div>-->
                <div class="px-3">
                    <router-link :to="{ name: 'login' }">{{$t('main-menu.sign-in')}}</router-link>
                </div>
            </template>

            <!-- Logged in options -->
            <template v-else>
                <div class="px-3">
                    <router-link :to="{ name: 'contact' }">{{$t('contact.contact-us')}}</router-link>
                </div>
                <div class="px-3">
                    <router-link :to="'/dashboard'">{{$t('main-menu.my-profile')}}</router-link>
                </div>
                <div class="px-3">
                    <a @click="logout">{{$t('main-menu.sign-out')}}</a>
                </div>
            </template>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import register from "@/pages/Registration/Layout.vue";
export default {
    name: "MainMenu",
    methods: {
        // TODO: refactor to use the new store
        ...mapActions(["logout"]),
    },
    computed: {
        register() {
            return register
        },
        isLoggedIn: function() {
            return this.$store.getters.isLoggedIn;
        }
    }
};
</script>

<style scoped lang="scss">

.container {
    display: flex;
    div a {
        color: $hf-sec-pine;
        cursor: pointer;
        &:hover {
            color: $hf-sec-pine;
            text-decoration: underline !important;
        }
    }
}
</style>