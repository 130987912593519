import * as types from './types';

export default {
    [types.SET_CHECK_IN_DATE](state, date) {
        state.checkInDate = date;
    },
    [types.SET_CHECK_OUT_DATE](state, date) {
        state.checkOutDate = date;
    },
    [types.SET_SEARCH_RESULTS](state, searchResults) {
        state.searchResults = searchResults;
    },
    [types.SET_SEARCH_LOCATION](state, searchLocation) {
        state.searchLocation = searchLocation;
    },
    [types.SET_SHOW_MAP_ON_MOBILE](state, showMapOnMobile) {
        state.showMapOnMobile = showMapOnMobile;
    },
};