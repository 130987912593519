<template>
    <div>
        <router-link
            class="btn request-visit"
            id="request-visit-button"
            :to="{ name: 'request-visit', params: { accommodationId: accommodation.idalojamiento }}"
        >
            {{ $t("reservation.request-visit") }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: "Visit",
    props: {
        accommodation: Object
    }
};
</script>

<style lang="scss" scoped>
.btn.request-visit {
    width: 100%;
    font-size: 1.5em;
    background-color: $hf-sec-carbon;
    color: #ffffff;
}
</style>
