<template>
	<div class="accommodations">
		<Modal
			:message="modal.message"
			:show="modal.show"
			:id="modal.id"
			:noCancel="modal.noCancel"
			:justText="modal.jt"
			@cancel="closeModal"
			@confirm="submitCalendar"
		/>
		<div
			v-for="accommodation in accommodations"
			:key="accommodation.idalojamiento"
			class="acc-item"
		>
			<div class="highlight-carousel">
				<CarouselAdmin
					:photos="accommodation.gallery"
					:idAlojamiento="accommodation.idalojamiento"
					:externalReference="accommodation.external_reference"
				/>
			</div>

			<div class="accommodation-info">
				<div class="title">
					#{{ accommodation.idalojamiento }} -
					{{ getJsonData(accommodation.nombre) }}
				</div>
				<div>
					<label for="">URL Ical</label>
					<div class="url" v-if="accommodation.get_url_ical !== null">
						{{ accommodation.get_url_ical.url_ical }}
					</div>
					<div v-else>
						<button
							class="button-acccitem"
							@click="generateUrl(accommodation.idalojamiento)"
						>
							{{ $t("icalendar.create-ical") }}
						</button>
					</div>
				</div>
				<div>
					<label for="">URL Json</label>
					<div class="url" v-if="accommodation.get_url_json !== null">
						{{ accommodation.get_url_json.url_json }}
					</div>
					<div v-else>
						<button
							class="button-acccitem"
							@click="generateUrlJson"
						>
							{{ $t("icalendar.create-json") }}
						</button>
					</div>
				</div>
				<div
					style="
						text-align: right;
						display: flex;
						justify-content: flex-end;
					"
				>
					<UploaderIcs
						:accommodation="accommodation"
						@icsfile="handleModal"
						:ref="`accommodation_${accommodation.idalojamiento}`"
					/>
					<a
						target="_blank"
						:href="createUrlIcal(accommodation.idalojamiento)"
					>
						<button class="button-acccitem">Download ICS</button>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CarouselAdmin from "@/components/Carousel/CarouselAdmin";
import UploaderIcs from "../../UploaderIcs/UploaderIcs";
import Modal from "../../UploaderIcs/Modal";
import axios from "axios";

export default {
	name: "Highlight",
	props: ["accommodations"],
	components: {
		CarouselAdmin,
		UploaderIcs,
		Modal,
	},
	data() {
		return {
			api: process.env.VUE_APP_API_URL,
			modal: {
				message: "",
				show: false,
				id: 0,
				noCancel: false,
				jt: false,
			},
			icalendar: "",
		};
	},
	methods: {
		getJsonData(value) {
			return JSON.parse(value)[this.$i18n.locale];
		},
		generateUrl(id) {
			this.$emit("createicalurl", id);
		},
		generateUrlJson() {
			this.$emit("createjsonurl");
		},
		createUrlIcal(id) {
			let api = this.api.replace("api/v1/", "");
			let uri = `${api}ical/${id}`;
			return uri;
		},
		handleModal(value) {
			const message = {
				es: `Seguro que quiere agregar el calendario ${value.name} en tus restricciones?`,
				en: `Are you sure you want to add the calendar ${value.name} in your constraints?`,
			};

			this.icalendar = value.ical;
			this.modal.show = true;
			this.modal.message = message;
			this.modal.id = value.id;
		},
		closeModal(id) {
            this.modal.show = false;
            this.modal.message = '';
            this.modal.id = 0;
            this.modal.noCancel = false;
            this.modal.jt = false;
			this.$refs[`accommodation_${id}`][0].clearInput();
		},
		submitCalendar() {
			const params = {
				alojamiento_id: this.modal.id,
				ical: this.icalendar,
			};
			let api = this.api.replace("api/v1/", "");
			let uri = `${api}ical/lock-dates-from-ics-file`;
			this.modal.message = {
				es: `Agregando fechas. Añadiendo fechas. Espere un momento hasta que termine...`,
				en: `Adding dates. Wait a moment until it ends...`,
			};
			this.modal.jt = true;
			axios
				.post(uri, params)
				.then((response) => {
					this.modal.message = {
						es: `Fechas agregadas con exito!`,
						en: `Dates added successfully!`,
					};
					this.modal.noCancel = true;
					this.modal.jt = false;
				})
				.catch(({ response }) => {
					this.modal.message = {
						es: `Archivo ics invalido`,
						en: `Ivalid ics file`,
					};
					this.modal.noCancel = true;
					this.modal.jt = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.accommodations {
	width: 100%;

	.acc-item {
		font-size: 0.9em;
		width: 100%;
		display: flex;
		flex-direction: row;
		border: 1px solid #efefef;
		border-radius: 10px;
		overflow: hidden;
		margin: 10px 0;

		&:hover {
			background-color: #efefef;
		}

		.highlight-carousel {
			min-width: 30%;
			max-width: 30%;
			height: 270px;
		}

		.accommodation-info {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 20px;
			width: 65%;

			.title {
				font-weight: bolder;
				color: #5e5e5e;
				font-size: 1.1em;
			}

			.url {
				font-family: "Courier New", Courier, monospace;
				font-size: 0.8em;
				color: #5f5f5f;
				background-color: #dfdfdf;
				padding: 7px;
				border-radius: 5px;
				border: 1px solid #9e9e9e;
				overflow: auto;
				white-space: nowrap;
			}

			label {
				font-weight: bold;
				color: #636363;
			}
			.button-acccitem {
				border: 1px solid #9e9e9e;
				border-radius: 5px;
				padding: 3px 8px;
				margin: 0 5px !important;
			}

			button {
				margin: 0 !important;
			}
		}
	}
}

@media (max-width: 822px) {
	.accommodations .acc-item {
		flex-direction: column;
	}

	.accommodations .acc-item .accommodation-info {
		min-height: 300px;
	}

	.accommodations .acc-item .highlight-carousel,
	.accommodations .acc-item .accommodation-info {
		min-width: 100%;
	}
}
</style>

<style lang="css">
.carousel-item {
	height: 100%;
}

.carousel-inner {
	height: 100%;
}
</style>