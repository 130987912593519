const state = {
    simulation: {
        'totalDays': '',
        'stay': '',
        'commission': '',
        'warranty': '',
        'total': '',
    },
    accommodationId: '',
    initDate: '',
    endDate: '',
    guests: 1,
    available: false
};

export default state;