<template>
    <div>
        <div class="container">
            <div class="row" style="margin-top:200px">
                <div class="col-12">
                    <loader item="reserva" v-if="loadingReserva" />

                    <div v-if="success" class="row mt-5 p-5 shadow content-info">
                        <div class="col-12 svg-suc suc">
                            <i class="fas fa-check-circle" />
                            <span>{{ $t('reservations-info.congratulations') }}</span>
                        </div>
                        <div class="col-12 suc mt-4">
                            <p>{{ $t('reservations-info.success') }}</p>
                        </div>
                    </div>

                    <div v-if="rejectSuccess" class="row mt-5 p-5 shadow content-info">
                        <div class="col-12 svg-suc suc">
                            <i class="fas fa-exclamation-triangle" />
                            <span>{{ $t('reservations-info.reject-title') }}</span>
                        </div>
                        <div class="col-12 suc mt-4">
                            <p>{{ $t('reservations-info.reject-msg') }}</p>
                        </div>
                    </div>

                    <div v-if="error01" class="row mt-5 p-5 shadow content-info">
                        <div class="col-12 svg-danger hyf-theme">
                            <i class="fas fa-exclamation-triangle" />
                            <span>{{ $t('reservations-info.attention') }}</span>
                        </div>
                        <div class="col-12 hyf-theme mt-4">
                            <p>{{ $t('reservations-info.not-found') }}</p>
                        </div>
                    </div>

                    <div v-if="error02" class="row mt-5 p-5 shadow content-info">
                        <div class="col-12 svg-danger danger">
                            <i class="fas fa-exclamation-triangle" />
                            <span>{{ $t('reservations-info.attention') }}</span>
                        </div>
                        <div class="col-12 danger mt-4">
                            <p>{{ $t('reservations-info.reject-auto') }}</p>
                        </div>
                    </div>

                    <div v-if="error03" class="row mt-5 p-5 shadow content-info">
                        <div class="col-12 svg-danger danger">
                            <i class="fas fa-exclamation-triangle" />
                            <span>{{ $t('reservations-info.attention') }}</span>
                        </div>
                        <div class="col-12 danger mt-4">
                            <p>{{ $t('reservations-info.rejected') }}</p>
                        </div>
                    </div>

                    <div v-if="error04" class="row mt-5 p-5 shadow content-info">
                        <div class="col-12 svg-danger danger">
                            <i class="fas fa-exclamation-triangle" />
                            <span>{{ $t('reservations-info.attention') }}</span>
                        </div>
                        <div class="col-12 danger mt-4">
                            <p>{{ $t('reservations-info.accepted') }}</p>
                        </div>
                    </div>

                    <div v-if="error05" class="row mt-5 p-5 shadow content-info">
                        <div class="col-12 svg-danger hyf-theme">
                            <i class="fas fa-exclamation-triangle" />
                            <span>{{ $t('reservations-info.attention') }}</span>
                        </div>
                        <div class="col-12 hyf-theme mt-4">
                            <p>{{ $t('reservations-info.error') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapActions, mapState } from "vuex";
export default {
    components: {
        Loader
    },
    data() {
        return {
            loadingReserva: true,
            error01: false,
            error02: false,
            error03: false,
            error04: false,
            error05: false,
            success: false,
            rejectSuccess: false
        };
    },
    methods: {
        ...mapActions(["getReservaInitiated", "rejectReserva"]),
        aceptarReserva() {
            let data = {
                idreserva: this.$route.params.id,
                token: this.$route.params.token
            };
            this.getReservaInitiated(data)
                .then(response => {
                    this.loadingReserva = false;
                    this.success = true;
                })
                .catch(error => {
                    console.error("[ERROR]", error.response.data.code);
                    if (error.response.status === 400) {
                        this.loadingReserva = false;
                        switch (error.response.data.code) {
                            case "E01":
                                this.error01 = true;
                                break;
                            case "E02":
                                this.error02 = true;
                                break;
                            case "E03":
                                this.error03 = true;
                                break;
                            case "E04":
                                this.error04 = true;
                                break;
                            case "E05":
                                this.error05 = true;
                                break;
                            default:
                                this.error01 = true;
                        }
                    }
                });
        },
        rechazarReserva() {
            let data = {
                idreserva: this.$route.params.id,
                token: this.$route.params.token
            };
            this.rejectReserva(data)
                .then(response => {
                    this.loadingReserva = false;
                    this.rejectSuccess = true;
                })
                .catch(error => {
                    console.error("[ERROR]", error.response.data.code);
                    if (error.response.status === 400) {
                        this.loadingReserva = false;
                        switch (error.response.data.code) {
                            case "E01":
                                this.error01 = true;
                                break;
                            case "E02":
                                this.error02 = true;
                                break;
                            case "E03":
                                this.error03 = true;
                                break;
                            case "E04":
                                this.error04 = true;
                                break;
                            case "E05":
                                this.error05 = true;
                                break;
                            default:
                                this.error01 = true;
                        }
                    }
                });
        }
    },
    computed: {
        ...mapState([])
    },
    created() {
        if (this.$route.params.action === "aceptar") {
            this.aceptarReserva();
        } else if (this.$route.params.action === "rechazar") {
            this.rechazarReserva();
        } else {
            this.loadingReserva = false;
            this.error05 = true;
        }
    }
};
</script>

<style scoped>
.content-info {
    background-color: #ffffff;
    border-radius: 3px;
    transition: max-height 4s;
    font: Bold 17px/17px Lato;
    line-height: 25px;
    margin: 3px;
}

.spinners {
    text-align: center;
    color: $hf-sec-pine;
}

.spinners span {
    width: 20px;
    height: 20px;
    margin: 0 20px;
}

.informations {
    text-align: center;
    color: #003D29;
}

.danger {
    color: #e66465;
}
.svg-danger svg {
    font-size: 45px;
}

.svg-danger span {
    margin-left: 20px;
    font-size: 20px;
    position: absolute;
    top: 10px;
}

.suc {
    color: #2b7f3e;
}
.svg-suc svg {
    font-size: 45px;
}

.svg-suc span {
    margin-left: 20px;
    font-size: 20px;
    position: absolute;
    top: 10px;
}

.hyf-theme {
    color: #003D29;
}
</style>