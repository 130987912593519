import { render, staticRenderFns } from "./Step9Wizard.vue?vue&type=template&id=52dbad6e&scoped=true"
import script from "./Step9Wizard.vue?vue&type=script&lang=js"
export * from "./Step9Wizard.vue?vue&type=script&lang=js"
import style0 from "./Step9Wizard.vue?vue&type=style&index=0&id=52dbad6e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52dbad6e",
  null
  
)

export default component.exports