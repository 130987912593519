<template>
    <div class="topbar fixed-top desktop-only">
        <CurrencySwitcher class="mr-4" />
        <LanguageSwitcher />
    </div>
</template>

<script>
import LanguageSwitcher from "./LanguageSwitcher";
import CurrencySwitcher from "./CurrencySwitcher";

export default {
    name: "Topbar",
    components: {
        LanguageSwitcher,
        CurrencySwitcher,
    }
};
</script>

<style lang="scss" scoped>

.topbar {
    background: $hf-primary;
    position: fixed;
    z-index: 201;
    display: flex;
    justify-content: right;
    padding-right: 100px;
    height: $desktop-header-height;
}
</style>
