import Register from "@/pages/Registration/Layout.vue";
import Recuperar from '@/pages/Login/Recuperar'
import CambiarClave from "@/pages/Login/CambiarClave.vue";
import CambiarPassword from "@/pages/Dashboard/CambiarPassword.vue";


export default [
    {
        path: '/registro/step-:step',
        name: 'register',
        component: Register
    },
    {
        path: '/login',
        name: 'login',
        component: Register
    },
    {
        path: '/auth/recuperar',
        name: 'recuperar',
        component: Recuperar
    },
    {
        path: '/cambiar/clave/:token',
        name: 'Cambiar clave',
        component: CambiarClave
    },
    {
        path: '/cambiar-password',
        name: 'Cambiar Password',
        component: CambiarPassword,
        meta: {
            requiresAuth: true,
            layout: 'dashboard'
        }
    },
];