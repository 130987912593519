<template>
    <div class="login-modal-container">
        <h3>{{$t('login.login-continue')}}</h3>
        <form class="login" @submit.prevent="enter()">
            <div class="input-login">
                <input
                    required
                    v-model="correo"
                    type="text"
                    :placeholder="$t('login.email')"
                    class="form-control form-control-lg"
                />
                <img src="@/assets/person.svg" alt />
            </div>
            <div class="input-login">
                <input
                    required
                    v-model="password"
                    type="password"
                    :placeholder="$t('login.password')"
                    class="form-control form-control-lg"
                />
                <img src="@/assets/candado.svg" alt />
            </div>
            <div class="text-danger" v-if="loginIncorrect">{{$t('login.bad-credentials')}}</div>
            <button type="submit" class="btn-lg btn-login-new" id="login-button">{{$t('login.sign-in')}}</button>

            <router-link class="login-link" :to="'/auth/recuperar'">{{$t('login.forgot-password')}}</router-link>
            <router-link
                class="login-link"
                :to="'/registro'"
            >{{$t('login.no-account')}} {{$t('login.register')}}</router-link>
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {askMissingUserData} from "@/Utils/Utils";
import { redirectToSavedUrl } from "@/Utils/Utils";
export default {
    name: "Login",
    components: {},
    data() {
        return {
            url: process.env.VUE_APP_SHARE_URL,
            correo: "",
            password: "",
            loginIncorrect: false
        };
    },
    methods: {
        ...mapActions(['login', 'createSession']),

        enter() {
            let usuario = {
                correo: this.correo,
                password: this.password
            };
            this.loginIncorrect = false;

            this.login(usuario).then((response) => {
                this.createSession(response);
                this.$bvModal.hide('modal-login')
                if(!askMissingUserData(response, this.$router)) {
                    redirectToSavedUrl(this.$router);
                }
            }).catch((error) => {
                this.loginIncorrect = true;
            });
        }
    },
    computed: {
        ...mapState(["error"])
    },
};
</script>

<style scoped lang="scss">
$primaryColor: $hf-sec-pine;

.login-modal-container {
    padding: 20px;

    h3 {
        text-align: center;
        padding-bottom: 20px;
    }

    .input-login {
        position: relative;
        input {
            border: 0.5px solid $primaryColor;
            border-radius: 3px;
            width: 100%;
            text-align: left;
            //font-weight: 100;
            margin-bottom: 20px;
        }

        img {
            position: absolute;
            z-index: 10;
            top: 8px;
            right: 8px;
        }
    }

    .btn-login-new {
        font-weight: bold;
        width: 100%;
        margin-bottom: 30px;
    }

    .login-link {
        display: block;
        text-align: center;
        color: #484848;
        text-decoration: underline;
        margin-top: 15px;
    }
}
</style>