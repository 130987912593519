<template>
    <div>
        <Topbar />
        <Navbar />
        <div class="dashboard-container">
            <div class="sidebar d-none d-md-block">
                <DashboardSidebar />
            </div>
            <div class="main-container">
                <div class="main-content">
                    <slot />
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import DashboardSidebar from "@/components/DashboardSidebar/DashboardSidebar";
import Topbar from "@/components/Topbar/Topbar";
import Navbar from "@/components/Navbar/Navbar";
import Footer from "@/components/Footer/Footer";

export default {
    name: "Dashboard",
    components: {
        DashboardSidebar,
        Topbar,
        Navbar,
        Footer
    },

    data() {
        return {
        };
    },
};
</script>

<style scoped lang="scss">
.dashboard-container {
    min-height: calc(100vh - 48px);
    padding-top: 145px;
    display: flex;

    @media (max-width: 767px) {
        min-height: calc(100vh - 60px);
        padding-top: 60px;
    }

    .sidebar {
        z-index: 1039;
        width: 250px;
        @media (max-width: 450px) {
            margin-bottom: 60px;
        }
    }

    .main-container {
        padding-bottom: 50px;
        width: 100%;
    }

    .main-content {
        padding-top: 20px;
    }

    .open-button {
        background-color: $hf-sec-pine;
        border: none;
        border-radius: 0 20px 20px 0;
        color: white;
        font-size: 1.6em;
        padding: 10px;
        margin-top: 10px;
        position: fixed;
        left: 0;
        z-index: 100;
        outline: 0;
        transition: all 0.25s ease;

        &:hover {
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
            background-color: $hf-sec-pine;
        }
    }
}
</style>


