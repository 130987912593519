<template>
    <div class="detalle-alojamiento-items-container">
        
        <!-- Visits -->
        <div class="detalle-alojamiento-item">
            <div class="detalle-alojamiento-item__icon">
                <i class="fas fa-eye" />
            </div>
            <div class="detalle-alojamiento-item__data">{{accommodation.visitas}}</div>
            <div>{{ $t('accommodation.visits') }}</div>
        </div>

        <!-- Monthly price -->
        <div class="detalle-alojamiento-item" v-if="accommodationMonthlyRentalMode || dualReaccommodationDualRentalModentalMode">
            <div class="detalle-alojamiento-item__icon">
                <i class="fas fa-tag" />
            </div>
            <div class="detalle-alojamiento-item__data">
                {{ { 'amount': accommodation.arriendo, 'currency': accommodation.moneda } | convertAndFormatPrice }}
            </div>
            <div>{{ $t('accommodation.per-month') }}</div>
        </div>

        <!-- Daily price -->
        <div class="detalle-alojamiento-item" v-if="accommodationDailyRentalMode || accommodationDualRentalMode">
            <div class="detalle-alojamiento-item__icon">
                <i class="fas fa-tag" />
            </div>
            <div class="detalle-alojamiento-item__data">
                {{ { 'amount': accommodation.arriendo_diario, 'currency': accommodation.moneda } | convertAndFormatPrice }}
            </div>
            <div>{{ $t('accommodation.per-day') }}</div>
        </div>

        <!-- Expenses -->
        <div class="detalle-alojamiento-item">
            <div class="detalle-alojamiento-item__icon">
                <i class="fas fa-money-bill-alt" />
            </div>
            <div class="detalle-alojamiento-item__data">
                {{ { 'amount': accommodation.gastos_comunes, 'currency': accommodation.moneda } | convertAndFormatPrice }}
            </div>
            <div>{{ $t('accommodation.common-expenses') }}</div>
        </div>

        <!-- m2 -->
        <div class="detalle-alojamiento-item">
            <div class="detalle-alojamiento-item__icon">
                <i class="fas fa-home" />
            </div>
            <div class="detalle-alojamiento-item__data">{{accommodation.m2}} m²</div>
            <div>{{ $t('accommodation.surface') }}</div>
        </div>

        <!-- <div class="detalle-alojamiento-item">
            <div class="detalle-alojamiento-item__icon">
                <i class="fas fa-subway" />
            </div>
            <div class="detalle-alojamiento-item__data">{{}} m²</div>
            <div>{{ $t('accommodation.close-to') }}:</div>
        </div> -->

        <!-- Brekfast -->
        <div class="detalle-alojamiento-item">
            <div class="detalle-alojamiento-item__icon">
                <i class="fas fa-coffee" />
            </div>
            <div class="detalle-alojamiento-item__data">{{accommodation.desayuno !== 0 ? $t('wizard.s1.yes') : $t('wizard.s1.no')}}</div>
            <div>{{ $t('accommodation.breakfast') }}</div>
        </div>

        <!--  TODO:
          <strong v-if="accomodation.metro == 1">{{ getTextTrans('metro') }}</strong>
          <strong v-else-if="accomodation.paradero == 1">{{ getTextTrans('paradero') }}</strong>
          <strong v-else-if="accomodation.hospital == 1">{{ getTextTrans('hospital') }}</strong>
          <strong v-else-if="accomodation.supermercado == 1">{{ getTextTrans('supermercado') }}</strong>
          <strong v-else-if="accomodation.banco == 1">{{ getTextTrans('banco') }}</strong>
          <strong v-else-if="accomodation.zona_nocturna == 1">{{ getTextTrans('zona_nocturna') }}</strong>
          <strong v-else-if="accomodation.areas_verdes == 1">{{ getTextTrans('areas_verdes') }}</strong>
          <strong
            v-else-if="accomodation.centro_comercial == 1"
          >{{ getTextTrans('centro_comercial') }}</strong>
          <strong v-else-if="accomodation.centro_civico == 1">{{ getTextTrans('centro_civico') }}</strong>
          <strong v-else>{{ getTextTrans('gimnasio') }}</strong>
        -->
    </div>
</template>

<script>
import { mapState } from "vuex";
import priceMixins from "@/mixins/priceMixin";

export default {
    name: "DetalleAlojamientoItems",
    props: [ "accommodation" ],
    mixins: [ priceMixins ],
    computed: {
        ...mapState(["user"])
    },
    data() {
        return {
            timeMode: ''
        }
    },

    methods: {
    },
    
};
</script>

<style scoped lang="scss">
.detalle-alojamiento-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    @media(min-width: $md) {
        margin-top: 20px;
        border: 1px solid $grey-10;
        padding: 10px;
    }

    .detalle-alojamiento-item {
        text-align: center;
        &__icon {
            color: $hf-sec-pine;
            font-size: 2em;
        }

        &__data {
            font-weight: bold;
        }

        @media (max-width: 767px) {
            padding: 20px;
            width: 33.3%;
        }
    }
    // @media (max-width: 767px) {
    //     flex-direction: column;
    // }
}
</style>