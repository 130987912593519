<template>
    <div class="container footer-wizard-container">
        <button class="mr-4" 
                @click="prevStep" 
                :class="{ 'invisible': prev===false}"
                id="wizard-prev-step-button">
            {{$t('wizard.footer.prev')}}
        </button>
        <button @click="nextStep" 
                :class="{ 'invisible': next===false, 'removed' : finish===true}" 
                :disabled="validate"
                id="wizard-next-step-button">
            {{$t('wizard.footer.next')}}
        </button>
        <button @click="save" 
                :class="{ 'removed': finish===false}" 
                :disabled="validate"
                id="wizard-finish-step-button">
            {{$t('wizard.footer.finish')}}
        </button>
    </div>
</template>

<script>
export default {
    name: "FooterWizard",
    props:{
        prev: {
            type: Boolean,
            default: false
        },
        next: {
            type: Boolean,
            default: false
        },
        finish: {
            type: Boolean,
            default: false
        },
        validate: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        nextStep() {
            this.$parent.nextStep();
        },
        prevStep() {
            this.$parent.prevStep();
        },
        save() {
            this.$parent.save();
        }
    }
};
</script>

<style scoped lang="scss">
.footer-wizard-container {
    display: flex;
    margin: 30px 0;
    justify-content: space-between;
    button {
        background-color: $hf-sec-pine;
        border: none;
        border-radius: 3px;
        color: white;
        height: 50px;
        width: 150px;
        outline: 0;

        &:hover {
            box-shadow: 5px 5px 5px 0px rgba(150, 150, 150, 0.23);
            background-color: $hf-sec-pine;
        }

        &:disabled {
            background-color: #999;
        }
    }
    .invisible {
        visibility: hidden;
    }

    .removed {
        display: none;
    }
}
</style>