export default {
    paginator: {
        perPage: 10,
    },
    gatewaysCommissions: {
        mercadopago: 6,
        paypal: 6,
        global66: 6,
        bankTransfer: 3
    },
}