<template>
    
    <div>
        
        <div class="d-flex flex-row">
            <div class="p-2 border border-right-0">
                <span>Eliminado: {{ data.eliminado === 1 ? "SI" : "NO" }}</span>
            </div>
            <div class="p-2 border border-right-0">
                <span>Aprobado: {{ data.aprobado === 1 ? "SI" : "NO" }}</span>
            </div>
            <div class="p-2 border">
                <span>Verificado: {{ data.verificado === 1 ? "SI" : "NO" }}</span>
            </div>
        </div>
        
        <div class="admin-bar-container d-flex">
            <div class="admin-bar-item" @click="goto(`/editar-alojamiento/${data.idalojamiento}`)">
                <div class="admin-bar-item__icon">
                    <i class="fas fa-edit" />
                </div>
                <div class="admin-bar-item__data" id="admin-edit-button">{{ $t('admin.edit') }}</div>
            </div>
            <div class="admin-bar-item" @click="goto(`/bloquear/${data.idalojamiento}`)">
                <div class="admin-bar-item__icon">
                    <i class="far fa-calendar-alt" />
                </div>
                <div class="admin-bar-item__data" id="admin-block-button">{{ $t('admin.block') }}</div>
            </div>
            <div class="admin-bar-item" @click="showModal('verify', data.idalojamiento)">
                <div class="admin-bar-item__icon">
                    <i class="far fa-check-square"></i>
                </div>
                <div class="admin-bar-item__data" id="admin-verified-button">{{ $t(verifiedTitle) }}</div>
            </div>
            <div class="admin-bar-item" @click="showModal('approve', data.idalojamiento)">
                <div class="admin-bar-item__icon">
                    <i class="far fa-thumbs-up"></i>
                </div>
                <div class="admin-bar-item__data" id="admin-approved-button">{{ $t(approvedTitle) }}</div>
            </div>
            <div class="admin-bar-item" @click="showModal('erase', data.idalojamiento)">
                <div class="admin-bar-item__icon">
                    <i class="far fa-trash-alt"></i>
                </div>
                <div class="admin-bar-item__data" id="admin-delete-button">{{ $t(removedTitle) }}</div>
            </div>
            <b-modal id="admin-modal" size="lg" ref="admin-modal" :title="$t(modal_data.title)" hide-footer centered>
                <p class="my-4">{{$t(modal_data.message)}}</p>
                <hr>
                <div class="row">
                    <div class="col-12 text-right">
                        <button class="mx-2 btn btn-outline-primary" id="confirm-admin-action-button" @click="actionModal(modal_data.name, modal_data.id)">
                            <b-spinner variant="light" label="Spinning" v-if="modal_data.loader" style="width:25px; height:25px" />
                            {{$t('admin.btn-ok')}}
                        </button>
                        <button v-if="modal_data.btnConfirm" class="mx-2 btn btn-outline-primary" @click="closeModal" id="cancel-admin-action-button">{{$t(modal_data.btnCancel)}}</button>
                    </div>
                </div>
            </b-modal>
        </div>
        
    </div>
    
</template>

<script>
import { mapState } from "vuex";
import axios from 'axios';

export default {
    name: "AdminBar",
    props: ["data"],
    data() {
        return {
            modal_data: {
                name: '',
                id: '',
                title: 'admin.title',
                message: 'admin.message',
                btnCancel: 'admin.btn-cancel',
                btnConfirm: true,
                loader: false,
            },
            approvedTitle: 'admin.approved-status',
            verifiedTitle: 'admin.verified-status',
            removedTitle: 'admin.removed-status',
        }
    },
    methods: {
        goto(url) {
            console.log('click')
            this.$router.push(url);
        },
        actionModal(value, id) {
            this.modal_data.loader = true;
            switch(value) {
                case 'verify':
                    this.verify(id);
                    break;
                case 'approve':
                    this.approve(id);
                    break;
                case 'erase':
                    this.erase(id);
                    break;
                default:
                    this.closeModal();
            }
        },
        verify(id) {
            axios.put(process.env.VUE_APP_API_URL + `accommodations/verify/${id}`, { verificado: 1 })
                .then((response) => {
                    this.verifiedTitle = 'admin.verified-status-ok',
                    this.closeModal();
                    this.successCase();                    
                })
                .catch((error) => {
                    console.log(error.response);
                    this.failCase();
                });
        },
        approve(id) {
            axios.put(process.env.VUE_APP_API_URL + `accommodations/approve/${id}`, { aprobado: 1 })
                .then((response) => {
                    this.approvedTitle = 'admin.approved-status-ok';            
                    this.closeModal();
                    this.successCase();
                })
                .catch((error) => {
                    console.log(error.response);
                    this.failCase();
                });
        },
        erase(id) {
            axios.delete(process.env.VUE_APP_API_URL + `accommodations/deleteAccomodation`, {params: { idalojamiento: id } })
                .then((response) => {
                    this.removedTitle = 'admin.removed-status-ok',
                    this.closeModal();
                    this.successCase();
                })
                .catch((error) => {
                    console.log(error.response);
                    this.failCase();
                });
        },
        closeModal() {
            this.modal_data.title = 'admin.title';          
            this.$refs['admin-modal'].hide();
        },
        showModal(name, id) {            
            this.modal_data = {
                name: name,
                id: id,
                title: `admin.${name}`,
                message: 'admin.message',
                btnCancel: 'admin.btn-cancel',
                btnConfirm: true,
                loader: false,
            };
            this.$refs['admin-modal'].show();
        },
        successCase() {
            this.modal_data.name = '',
            this.modal_data.title = 'admin.success-title';
            this.modal_data.message = 'admin.success';
            this.modal_data.btnConfirm = false;
            this.modal_data.loader = false;
            this.$refs['admin-modal'].show();
        },
        failCase() {
            this.modal_data.title = 'admin.fail-title';
            this.modal_data.message = 'admin.fail';
            this.modal_data.btnConfirm = false;
            this.modal_data.loader = false;
            this.$refs['admin-modal'].show();
        }
    }, created() {
        if(this.data.eliminado) {
            this.removedTitle = "admin.removed-status-ok"
        }
        if(this.data.aprobado) {
            this.approvedTitle = "admin.approved-status-ok"
        }
        if(this.data.verificado) {
            this.verifiedTitle = "admin.verified-status-ok"
        }
    }
};
</script>

<style scoped lang="scss">
.btn-outline-primary {
    background-color: #FFF;
    border-color: $hf-sec-pine !important;
    color: $hf-sec-pine;
    &:hover, &:active, &:focus{
        color: #fff;
        background-color: $hf-sec-pine !important;
        border-color: $hf-sec-pine;
    }
}
.admin-bar-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between !important;
    padding: 10px 100px;
    

    .admin-bar-item {
        text-align: center;
        &__icon {
            color: $hf-sec-pine;
            font-size: 2em;
            cursor: pointer;
        }

        &__data {
            font-weight: bold;
        }
    }

    @media (max-width: 767px) {
        font-size: 8pt !important;
        -webkit-box-pack: justify !important;
        padding: 10px 20px;
    }

    @media (max-width: 360px) {
        font-size: 6pt !important;
        -webkit-box-pack: justify !important;
        padding: 10px 20px;
    }

    @media (max-width: 320px) {
        font-size: 5pt !important;
        -webkit-box-pack: justify !important;
        padding: 10px 20px;
    }
}
</style>