import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';

export const validations = () => ({
    formData: {
        first_name: { required, min_length: minLength(2), max_length: maxLength(30) },
        last_name: { required, min_length: minLength(2), max_length: maxLength(40) },
        government_id: { required, min_length: minLength(5), max_length: maxLength(15) },
        phone: { min_length: minLength(6), max_length: maxLength(16) },
        email: { email },
        bank_account_number: { required },
        bank_code: { required },
        bank_name: { required },
        bank_address: { max_length: maxLength(150) },
        branch_code: { max_length: maxLength(50) },
        message: { max_length: maxLength(255) }
    }
});