<template>
    
    <div class="profile-selection">
        
        <div class="help">
            <p>
                Tu elección importa. Haz click en <b>"Ser Huesped"</b> si estás buscando alojamiento, o selecciona
                <b>"Ser Anfitrión"</b> si deseas alquilar tu propiedad
            </p>
        </div>

        <!-- Botones para seleccionar el tipo de perfil -->
        <div class="profile-buttons">
            <Button @click="selectProfileType('anfitrion')" class="new-btn profile-btn btn--thick" variant="sec-pine">
                {{ $t('register-new.be-host') }}
            </Button>
            <Button @click="selectProfileType('huesped')" class="new-btn profile-btn btn--thick" variant="sec-pine">
                {{ $t('register-new.be-guest') }}
            </Button>
        </div>
    </div>
    
</template>

<script>

import { mapActions } from "vuex";
import Button from "@/components/Ui/Button.vue";

export default {
    name: "Step2",
    components: {
        Button
    },
    props: {
    },
    data() {
        return {
            clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        };
    },
    methods: {
        
        ...mapActions("userNew", [
            "setUserType"
        ]),
        
        async selectProfileType(profileType) {
            const data = { 'type': profileType }
            try {
                await this.setUserType(data);
                this.$emit('next-step');
            } catch (error) {
                console.error('Error actualizando el tipo de perfil:', error);
            }
        }
    }
}

</script>

<style lang="scss" scoped>
@import "@/assets/new-design/styles";

.profile-selection {
    margin-top: 70px;
}

.profile-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding-top: 50px;
}

.help {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
}

</style>
