import store from '@/store'

export function checkAppVersion(res) {

    // TODO: remove log
    const debug = false;
    
    const versionChecked = store.state.appVersionCheck.versionChecked
    
    if(!versionChecked) {

        // TODO: remove log
        if(debug) console.log('checking version...')
        
        const remoteVersion = res.headers['app-version'];
        
        if (remoteVersion !== undefined) {
            
            const localVersion = store.state.appVersionCheck.localVersion
            const newVersionAvailable = isRemoteVersionGreaterThanLocal(localVersion, remoteVersion)

            // TODO: remove log
            if(debug) console.log('remote version: ' + remoteVersion);
            if(debug) console.log('local version: ' + localVersion);

            if (newVersionAvailable) {
                store.dispatch('appVersionCheck/setAppOutOfDate', true)
                // TODO: remove log
                if(debug) console.log('new version available')
            } else {
                // TODO: remove log
                if(debug) console.log('version up to date')
            }
            store.dispatch('appVersionCheck/setVersionChecked', true)
        } else {
            // TODO: remove log
            if(debug) console.log('no version header found')
        }
    } else {
        // TODO: remove log
        if(debug) console.log('version already checked')
    }
}

function isRemoteVersionGreaterThanLocal(localVersion, remoteVersion) {
    const partsLocal = localVersion.split('.').map(Number); // Split localVersion into parts and convert to numbers
    const partsRemote = remoteVersion.split('.').map(Number); // Split remoteVersion into parts and convert to numbers

    // Compare component by component
    for (let i = 0; i < Math.max(partsLocal.length, partsRemote.length); i++) {
        const partLocal = partsLocal[i] || 0; // If no more parts in localVersion, consider 0
        const partRemote = partsRemote[i] || 0; // If no more parts in remoteVersion, consider 0

        if (partRemote > partLocal) {
            return true; // RemoteVersion is greater than LocalVersion
        }
        if (partRemote < partLocal) {
            return false; // RemoteVersion is less than LocalVersion
        }
        // If equal in this position, continue to the next part
    }

    // If we reach here, versions are equal or remoteVersion has fewer parts but isn't greater
    return false;
}