<template>
	<div class="app-container" @click="resetVersionCheck">
		<component :is="layout">
			<router-view />
		</component>
        <Bot v-if="!hideBot" />
        <AppVersionCheck />
        <CookiesConsent />
	</div>
</template>

<script>
import CookiesConsent from "@/components/Cookies/Cookies";
import WebSitePopUp from "@/components/PopUps/WebSitePopUp";
import Bot from "@/components/Bot/Bot.vue";
import AppVersionCheck from "@/components/AppVersionCheck/AppVersionCheck.vue";
import store from '@/store'

const defaultLayout = "default";
export default {
	components: {
        CookiesConsent,
        WebSitePopUp,
        Bot,
        AppVersionCheck
	},
	computed: {
		layout() {
			return (this.$route.meta.layout || defaultLayout) + "-layout";
		},
        hideBot() {
            return this.$route.meta.hideBot;
        }
	},
    methods: {
        resetVersionCheck() {
            store.dispatch('appVersionCheck/setVersionChecked', false);
        },
    }
};
</script>

<style>

@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
    //font-weight: 300;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $hf-sec-pine;
}

.app-container {
	font-family: "Lato", sans-serif;
}


</style>

