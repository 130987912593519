<template>
	<div v-if="!cookies" class="cookie-footer">
		<div class="text">
			{{ $t("cookies.text") }}
		</div>
		<a :href="tyc[$i18n.locale]">
			<div class="learn">{{ $t("cookies.learn") }}</div>
		</a>
		<div class="control">
			<button class="btn btn-primary" @click="setCookies">
				{{ $t("cookies.btn-ok") }}
			</button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
            cookies: false,
            tyc: {
                es: 'tyc-es',
                en: 'tyc-en'
            }
		};
	},
	methods: {
		setCookies() {
			localStorage.setItem("cookies-bar", true);
			this.cookies = true;
		},
	},
	mounted() {
        this.cookies = localStorage.getItem("cookies-bar");
	},
};
</script>

<style scoped lang="scss">
.cookie-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #3c4043;
	z-index: 21474836492 !important;
	padding: 10px 100px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: #fff;
	font-weight: bold;
	font-size: 1em;

	.text {
		min-width: 80%;
	}
	a {
		color: #fff !important;
		font-weight: 400;

		&:hover {
			text-decoration: underline !important;
		}

		.learn {
			font-size: 1em;
		}
	}
}

@media (max-width: 600px) {
	.cookie-footer {
		padding: 10px 20px;
		display: flex;
		justify-content: space-around;
        font-weight: 500;
		.text {
			min-width: 60%;
		}

		a {
			min-width: 20%;
            text-align: center;
			.learn {
				font-size: 1em;
				text-decoration: underline;
			}
		}
	}
}
</style>
