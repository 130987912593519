const API_BASE_URL = process.env.VUE_APP_API_URL;

export default {
    getAccommodation: `${API_BASE_URL}accommodations/getAccomodation/`,
    reservations: `${API_BASE_URL}reservations`,
    getReceivedReservations: `${API_BASE_URL}reservations/received`,
    setReservationAsPaid: `${API_BASE_URL}pago/manualpayment`,
    searchAccommodations: `${API_BASE_URL}search_alojamiento`,
    icalUrl: `${API_BASE_URL}ical/url`,
    syncWithExternalIcal: `${API_BASE_URL}ical/sync-with-external-ical`,
    bankData: `${API_BASE_URL}bank-data`,
    contact: `${API_BASE_URL}contact`,
    recaptcha: `${API_BASE_URL}recaptcha/contact`,
    simulation: `${API_BASE_URL}simulation`,
    available: `${API_BASE_URL}available`,
    userType: `${API_BASE_URL}users/type`,
    userCountry: `${API_BASE_URL}users/country`,
}