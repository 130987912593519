<template>
    <div>

        <button class="navbar-toggler burger-button" @click="toggleNav">
            <span class="dark-blue-text">
                <i class="fas fa-bars fa-1x"></i>
            </span>
        </button>

        <div class="sidebar">
            
            <div
                class="sidebar-backdrop"
                @click="toggleNav"
                :class="isNavOpen ? 'show' : 'hide'"
            />
            
            <div class="sidebar-panel" :class="isNavOpen ? 'active' : 'inactive'">
                <DashboardSidebar />
            </div>
        </div>

    </div>
</template>

<script>
import DashboardSidebar from "@/components/DashboardSidebar/DashboardSidebar.vue";

export default {
    data() {
        return {
            isNavOpen: false
        };
    },
    methods: {
        toggleNav() {
            this.isNavOpen = !this.isNavOpen;
        },
        closeNav() {
            this.isNavOpen = false;
        }
    },
    components: {
        DashboardSidebar
    },
    mounted() {
        // FIXME: The sidebar menu it's being opened unintentionally when the component is created
        this.closeNav();
    }
};
</script>

<style lang="scss">

.burger-button {
    color: $hf-sec-pine;
    padding: 18px;;
}

.close-button {
    border: 0;
    background-color: transparent;
    font-size: 2em;
    &:focus {
        outline: 0;
    }
}

.sidebar-panel {
    top: 0;
    overflow-y: auto;
    background-color: white;
    position: fixed;
    height: 100vh;
    z-index: 2000;
    padding: 10px 20px;
    width: 300px;
    transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);

    @media (max-width: 767px) {
        padding: 0 20px 80px 20px;
    }
}

.show {
    opacity: 1 !important;
}

.hide {
    opacity: 0 !important;
    display: none;
}

.active {
    right: 0;
}

.inactive {
    right: -300px;
}

.sidebar {
    z-index: 2000;
}

.sidebar-backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    cursor: pointer;
    opacity: 0;
    transition: ease 0.2s;
    transition-delay: 0.5s;
}
</style>
