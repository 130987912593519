<template>
	<div class="container bugs">
		<form class="w-100">
			<div class="row justify-content-center mt-5">

				<div class="col-12 col-md-8">
					<select name="errors" v-model="errors" class="form-control">
						<option value>Elija una opcion...</option>
						<option value="accommodation">Error al crear alojamiento</option>
						<option value="other">Otros...</option>
					</select>
				</div>

                <div class="col-12 col-md-8 mt-3">
                    <label for="txt-area">Describe el error</label>
					<textarea id="txt-area" class="form-control"></textarea>
				</div>

			</div>
		</form>
	</div>
</template>

<script>
export default {
    data() {
        return {
            errors: ''
        }
    }
}
</script>