<template>
    <div class="navbar-hyf">
        <div class="navbar-container container-fluid">

            <div class="mobile-items mobile-only">
                
                <div class="col-4 selectors">
                    <CurrencySwitcher />
                    <LanguageSwitcher />
                </div>

                <div class="col-4 mobile-logo">
                    <router-link :to="'/'">
                        <img src="@/assets/new-design/logo-reduction.svg" />
                    </router-link>
                </div>
                
                <!-- Mobile menu -->
                <Sidebar class="col-4 text-right" />

            </div>
            
            <!-- Logo desktop -->
            <div class="desktop-items desktop-only">

                <router-link :to="'/'" class="logo-desktop">
                    <img src="@/assets/new-design/logo-horizontal.svg" />
                </router-link>

                <!-- Desktop user menu -->
                <MainMenu class="user-menu"/>
                
            </div>

        </div>
    </div>
</template>

<script>
import LanguageSwitcher from "@/components/Topbar/LanguageSwitcher";
import CurrencySwitcher from "@/components/Topbar/CurrencySwitcher";

import Sidebar from "../Sidebar/Sidebar";
import MainMenu from "./MainMenu";
export default {
    name: "Navbar",
    components: {
        Sidebar,
        MainMenu,
        LanguageSwitcher,
        CurrencySwitcher
    }
};
</script>

<style lang="scss" scoped>

.navbar-hyf {

    position: fixed;
    width: 100%;
    z-index: 200;
    top: $desktop-header-height;
    background: #fff;
    border-bottom: 1px solid rgba($hf-sec-pine, 0.2);
    
    @media (max-width: $md) {
        top: 0;
    }

    .navbar-container {
        
        padding: 0;
        .mobile-items {
            align-items: center;
            z-index: 101;
            img {
                width: 50px;
            }

            .selectors {
                padding: 0;
                text-align: center;
                justify-content: space-around;
                display: inline-flex;
            }

            .mobile-logo {
                text-align: center;
            }
        }
        
        .desktop-items {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            
            .logo-desktop {
                margin-left: 25px;
                img {
                    height: 50px;
                    padding: 10px 0;
                }
            }
            
            .user-menu {
                display: flex;
                justify-content: right;
                margin-right: 20px;
            }
        }
    }
}

</style>
