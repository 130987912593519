<template>
	<div class="content-paginator">
		<div class="paginator">
			<button class="paginator-control" @click="minusTen">-10</button>
			<button class="paginator-control" @click="back">{{ buttons[0] }}</button>

			<button class="paginator-control">{{ page }}</button>

			<button class="paginator-control" @click="next">{{ buttons[2] }}</button>
			<button class="paginator-control" @click="plusTen">+10</button>
		</div>
		<div class="index-info">{{ page }} de {{ pages || 1 }}</div>
	</div>
</template>

<script>
export default {
	name: "Paginator",
	props: ["pages"],
	data() {
		return {
			buttons: ["<", "<<", ">", ">>"],
			maxButtons: 4,
			totalPages: this.pages < 1 ? 1 : this.pages,
			page: 1,
		};
	},
	methods: {
		setPages() {
            const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.has('page')) {
                this.page = urlParams.get('page');
            }
			this.totalPages = this.pages;
		},
		back() {
            this.page = parseInt(this.page)
			if((this.page - 1) < 1) {
                this.$emit("setPage", this.page);
            } else {
                this.page -= 1;
                this.$emit("setPage", this.page);
            }
		},
		next() {
            this.page = parseInt(this.page)
			if((this.page + 1) > this.totalPages) {
                this.$emit("setPage", this.page);
            } else {
                this.page += 1;
                this.$emit("setPage", this.page);
            }
		},
		minusTen() {
            this.page = parseInt(this.page)
            if((this.page - 10) < 1) {
                this.page = 1;
                this.$emit("setPage", this.page);
            } else {
                this.page -= 10;
                this.$emit("setPage", this.page);
            }
		},
		plusTen() {
            this.page = parseInt(this.page)
			if((this.page + 10) > this.totalPages) {
                this.$emit("setPage", this.totalPages);
                this.page = this.totalPages;
            } else {
                this.page += 10;
                this.$emit("setPage", this.page);
            }
		},
	},
	created() {
        this.setPages();
	},
};
</script>

<style lang="scss" scoped>
.content-paginator {
	width: 100%;
	margin: 20px 0;
}
.paginator {
	width: 100%;
	display: flex;
	justify-content: center;
}
.paginator-control {
	background: transparent;
	border-left: 1px solid #b5b5b5 !important;
	border-top: 1px solid #b5b5b5 !important;
	border-bottom: 1px solid #b5b5b5 !important;
    border-right: hidden !important;
	min-width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 10px;
	color: #6a6a6a;
	font-weight: 500;
}

.paginator-control:hover {
	background-color: $hf-sec-pine;
	color: #efefef;
}

.paginator-control:first-child {
	border-radius: 5px 0 0 5px;
	border-left: 1px solid #b5b5b5 !important;
	border-top: 1px solid #b5b5b5 !important;
	border-bottom: 1px solid #b5b5b5 !important;
}

.paginator-control:last-child {
	border-radius: 0px 5px 5px 0px;
	border-right: 1px solid #b5b5b5 !important;
	border-top: 1px solid #b5b5b5 !important;
	border-bottom: 1px solid #b5b5b5 !important;
}

.index-info {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 5px;
	font-size: 0.7em;
}
</style>