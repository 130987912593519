<template>
    <div class="container">
        <div class="featured-title">{{$t('featured.featured-accommodations')}}</div>

        <Owl
            class="owl"
            v-if="accomodations.length"
            :responsive="{0:{items:1},600:{items:2},1000:{items:4}}"
            :autoplay="true"
            :autoplayTimeout="10000"
            :nav="false"
            ref="owl"
        >
            <div 
                :key="accomodation.idalojamiento" 
                v-for="accomodation in accomodations"
                class="item-inner"
            >
                <AccommodationItem
                    :fav="accomodation.alojamiento_idalojamiento"
                    :id="accomodation.idalojamiento"
                    :nombre="accomodation.nombre"
                    :direccion="accomodation.direccion"
                    :accommodation="accomodation"
                    :from="'home'"
                />
            </div>
        </Owl>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Owl from "vue-owl-carousel";
import AccommodationItem from "@/components/AccommodationItem/AccommodationItem";

export default {
    name: "Featured",
    components: {
        Owl,
        AccommodationItem
    },
    methods: {
        ...mapActions(["getAccomodationFeatured"])
    },
    computed: {
        ...mapState(["accomodations"])
    },
    created() {
        this.getAccomodationFeatured().then((response) => {
            this.$store.commit('setAccomodations', response.data.accomodations);
        })
    },
};
</script>

<style lang="scss">

.owl-stage {
    display: flex !important;
}

.owl-item {
    display: flex !important;
    flex: 1 !important;
}

.item-inner {
    width: 100%;
}

</style>

<style scoped lang="scss">

.featured-title {
    font-size: 1.8em;
    //font-weight: 100;
    padding-left: 12px;
    padding-top: 20px;
}
</style>