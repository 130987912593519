<template>
	<div>
		<Topbar />
		<Navbar />
		<div class="main-content">
			<slot />
		</div>
		<Footer />
	</div>
</template>

<script>
import Topbar from "@/components/Topbar/Topbar";
import Navbar from "@/components/Navbar/Navbar";
import Footer from "@/components/Footer/Footer";

export default {
	components: {
		Topbar,
		Navbar,
		Footer,
	}
};
</script>

<style lang="scss" scoped>
.main-content {
	min-height: calc(100vh - 90px);
	margin-top: 90px;

	@media (max-width: 767px) {
		min-height: calc(100vh - 60px);
        margin-top: 60px;
	}
}
</style>