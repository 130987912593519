export default [
    {
        path: '/request-accommodation-visit/:accommodationId',
        name: 'request-visit',
        component: () => import('@/pages/AccommodationVisit/RequestAccommodationVisit.vue'),
    },
    {
        path: '/request-accommodation-visit/:accommodationId/success',
        name: 'request-visit-success',
        component: () => import('@/pages/AccommodationVisit/RequestAccommodationVisitSuccess.vue'),
    },
    
]