<template>
    <div class="container">
        
        <div v-if="accommodationData.tipo === 'cowork'" class="top-info">{{$t('wizard.s8.features-info-cowork')}}:</div>
        <div v-else-if="accommodationData.tipo === 'camper'" class="top-info">{{$t('wizard.s8.features-info-camper')}}:</div>
        <div v-else class="top-info">{{$t('wizard.s4.features-info')}}:</div>
        
        <hr />
        <TopS4S7 :selected="3" :accommodationData="accommodationData" />
        <hr />
        <div class="row">
            <div
                class="col-sm-12 col-md-6 col-xl-4 select-item"
                v-for="(item, i) in s6Data"
                :key="`s6-${i}`"
            >
                <img :src="require(`@/assets/alojamiento/${item.icon}.svg`)" />
                <label class="toggleS">
                    <input
                        class="toggleS__input"
                        type="checkbox"
                        v-model="accommodation.caracteristicas[item.field]"
                    />
                    <span class="toggleS__label">
                        <span class="toggleS__text">{{$t(item.text)}}</span>
                    </span>
                </label>
            </div>
        </div>
        <hr />
        <FooterWizard prev next />
    </div>
</template>


<script>
import s6Data from "./step6-data.json";
import TopS4S7 from "./TopS4S7";
import FooterWizard from './FooterWizard';

export default {
    name: "Step6Wizard",
    props: {
        data: {
            type: Object,
            default: null
        },
        accommodationData: {
            type: Object,
            default: () => { 
                return {
                    tipo: ''
                }
             }
        }
    },
    data() {
        return {
            s6Data,
            accommodation: {
                caracteristicas: {
                    paradero_micro: 0,
                    estacion_metro: 0,
                    hospital: 0,
                    supermercado: 0,
                    banco: 0,
                    zona_nocturna: 0,
                    areas_verdes: 0,
                    centro_comercial: 0,
                    centro_civico: 0,
                    gimnasio_externo: 0
                }
            }
        };
    },

    components: {
        TopS4S7,
        FooterWizard
    },
    methods: {
        nextStep() {   
            Object.keys(this.accommodation.caracteristicas).forEach((item) => {
                this.accommodation.caracteristicas[item] = this.accommodation.caracteristicas[item] ? 1 : 0;
            });         
            this.$emit('next', this.accommodation.caracteristicas);
        },
        prevStep() {
            this.$emit('prev');
        }
    },
    created() {
        window.scrollTo(0, 0);
        if(this.data != null) {
            Object.keys(this.data).forEach((item) => {
                this.accommodation.caracteristicas[item] = this.data[item];
            })
        }
    }
};
</script>

<style scoped lang="scss">
.top-info {
    padding-top: 10px;
}

.select-item {
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    img {
        margin-right: 10px;
        width: 40px;
    }
}
</style>

