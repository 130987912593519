<template>
    <Bugs />
</template>

<script>
import Bugs from '../../components/Bugs/Bugs';
export default {
    components: {
        Bugs
    }
}
</script>