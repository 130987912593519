<template>
	<div class="container admin">
		<div class="row justify-content-center">
			<div
				class="col-12 col-md-3 admin__item d-flex justify-content-center"
				v-for="mod in data"
				:key="mod.title"
			>
				<router-link :to="mod.url">
					<i :class="mod.icon"></i>
					{{ $t(mod.title)}}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import modules from "../../components/Admin/modules.json";
export default {
	data() {
		return {
			data: modules
		};
	}
};
</script>

<style lang="scss">
.admin {
	padding: 40px !important;
	&__item {
		max-width: 100%;
		margin: 15px;
		border: 1px solid $hf-sec-pine;
		border-radius: 5px;
        padding: 0 !important;
		text-align: center;
		font-weight: 600;
		color: $hf-sec-pine;
		line-height: 40px;
		cursor: pointer;

		svg {
			font-size: 40px !important;
			margin-right: 20px;
		}
		&:hover {
			background: $hf-sec-pine;
			a {
				color: #fff;
			}
		}

		a {
			color: $hf-sec-pine;
			padding: 20px 10px;
            width: 100%;
		}
	}
}
</style>