<template>
    <div class="container">
        
        <div v-if="accommodationData.tipo === 'cowork'" class="top-info">{{$t('wizard.s8.features-info-cowork')}}:</div>
        <div v-else-if="accommodationData.tipo === 'camper'" class="top-info">{{$t('wizard.s8.features-info-camper')}}:</div>
        <div v-else class="top-info">{{$t('wizard.s4.features-info')}}:</div>

        <hr />
        <TopS4S7 :selected="1" :accommodationData="accommodationData" />
        <hr />

        <div class="row">
            <div
                class="col-sm-12 col-md-6 col-xl-4 select-item"
                v-for="(item, i) in fields"
                :key="`s4-${i}`"
            >
                <img :src="require(`@/assets/alojamiento/${item.icon}.svg`)" />
                <label class="toggleS">
                    <input
                        class="toggleS__input"
                        type="checkbox"
                        v-model="accommodation.caracteristicas[item.field]"
                    />
                    <span class="toggleS__label">
                        <span class="toggleS__text">{{$t(item.text)}}</span>
                    </span>
                </label>
            </div>
        </div>
        <FooterWizard next prev />
    </div>
</template>

<script>
import s4Data from "./step4-data.json";
import TopS4S7 from "./TopS4S7";
import FooterWizard from './FooterWizard'

export default {
    name: "Step4Wizard",
    props: {
        data: {
            type: Object,
            default: null
        },
        accommodationData: {
            type: Object,
            default: () => { 
                return {
                    tipo: ''
                }
             }
        }
    },
    data() {
        return {
            s4Data,
            fields: [],
            accommodation: {
                caracteristicas: {
                    conserje: 0,
                    timbre: 0,
                    bodega: 0,
                    estacionamiento_privado: 0,
                    estacionamiento_visita: 0,
                    estacionamiento_bicicleta: 0,
                    sala_estar: 0,
                    sala_eventos: 0,
                    jardines: 0,
                    piscina: 0,
                    gimnasio_interno: 0,
                    desayuno: 0,
                    refrigerador: 0,
                    cocina: 0,
                    horno: 0,
                    microondas: 0,
                    parrilla: 0,                    
                    lavadora: 0,
                    secadora: 0,
                    plancha: 0,
                    limpieza: 0,
                    mascotas: 0
                }
            }
        };
    },

    components: {
        TopS4S7,
        FooterWizard
    },
    watch: {
        accommodation: function(val) {
        }
    },
    methods: {
        nextStep() {
            Object.keys(this.accommodation.caracteristicas).forEach((item) => {
                this.accommodation.caracteristicas[item] = this.accommodation.caracteristicas[item] ? 1 : 0;
            });
            this.$emit('next', this.accommodation.caracteristicas);
        },
        prevStep() {
            this.$emit('prev');
        },
        checkType(type) {
            const fields = [];
            s4Data.forEach((item) => {
                const find = item.area.find((i) => i === type)
                if(find) {
                    fields.push(item);
                }
            })
            this.fields = fields;
        }
    },
    created() {
        window.scrollTo(0, 0);
        if(this.data != null) {
            Object.keys(this.data).forEach((item) => {
                this.accommodation.caracteristicas[item] = this.data[item];
            })
        }   
        this.checkType(this.accommodationData.tipo)
    }
};
</script>

<style scoped lang="scss">
.top-info {
    padding-top: 10px;
}

.select-item {
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    img {
        margin-right: 10px;
        width: 40px;
    }
}
</style>

