<template>
	<div class="coupon-form">
		<div class="effect" />
		<div class="content">
			<div class="coupon-header">Coupons</div>
			<div class="coupon-body">
				<label for="code">Codigo</label>
				<input
					class="form-control"
					type="text"
					id="code"
					v-model="coupon.code"
				/>

				<label for="percentage">Percentage</label>
				<input
					class="form-control"
					type="text"
					id="percentage"
					v-model="coupon.percentage"
				/>

                <label for="percentage">ID's Alojamientos</label>
				<multiselect
					v-model="coupon.selectedAccommodations"
					:options="options"
					:multiple="true"
					track-by="idalojamiento"
					:placeholder="$t('multiselect.placeholder')"
					label="idalojamiento"
					:selectLabel="$t('multiselect.pick')"
					:hide-selected="true"
				>
				</multiselect>
			</div>

			<div class="coupon-controls">
				<button class="btn btn-coupons" @click="close">Cancelar</button>
				<button class="btn btn-coupons" @click="createCoupon">
					Registrar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
	data() {
		return {
			coupon: {
				code: "",
				percentage: 0,
				status: 1,
				days_of_week: [7],
				quantity: -1,
           		selectedAccommodations: [],
            },
            options: [],
			url: process.env.VUE_APP_API_URL.substring(
				0,
				process.env.VUE_APP_API_URL.indexOf("/api/v1")
			),
		};
	},
	methods: {
		close() {
			this.$emit("close", "create");
		},
		getAccommodationIds() {
			axios.get(`${process.env.VUE_APP_API_URL}admin/get-accommodations/ids`).then((response) => {
				this.options = response.data;
			});
		},
		createCoupon() {
			axios
				.post(`${this.url}/cupon`, this.coupon)
				.then(() => {
					this.$emit("close", "create");
				})
				.catch(({ response }) => {
					console.log(response);
					if (
						response.data.code[0] ===
						"The code has already been taken."
					) {
						this.$toasted.show("El codigo no esta disponible", {
							theme: "bubble",
							position: "top-right",
							duration: 5000,
						});
					} else if (response.status === 422) {
						this.$toasted.show(
							"Rellena los campos antes de seguir",
							{
								theme: "bubble",
								position: "top-right",
								duration: 5000,
							}
						);
					} else {
						this.$toasted.show("Escolha outro codigo", {
							theme: "bubble",
							position: "top-right",
							duration: 5000,
						});
					}
				});
		},
	},
	created() {
		this.getAccommodationIds();
	}
};
</script>

<style lang="scss" scoped>
.coupon-form {
	position: fixed;
	width: 100%;
	height: 100vh;
	left: 0;
	top: 0;
	display: flex;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;

	.effect {
		position: absolute;
		width: 100%;
		height: 100%;
		background: #000;
		z-index: 1;
		opacity: 0.5;
	}

	.content {
		position: relative;
		z-index: 2;
		background: #fff;
		border-radius: 5px;
		padding: 20px;
		min-width: 30%;
		@media (max-width: 600px) {
			min-width: 90%;
		}

		.coupon-header {
			border-bottom: 1px solid #e3e3e3;
			margin-bottom: 15px;
		}

		.coupon-body {
			display: flex;
			flex-direction: column;

			label {
				color: #838383;
				margin-left: 5px;
				font-size: 0.8em;
			}

			input {
				margin-bottom: 5px;
			}
		}

		.coupon-controls {
			display: flex;
			padding: 15px;
			display: flex;
			justify-content: flex-end;
			.btn-coupons {
                margin: 0 10px;
                border: solid 1px rgb(206, 212, 218);
                color: #525053;
            }
		}
	}
}
</style>