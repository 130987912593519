import * as moment from "moment";

export default {
    
    computed: {
        currency() {
            return this.accommodation.moneda;
        },
        dailyPrice() {
            return { 'amount': this.accommodation.arriendo_diario, 'currency': this.currency };
        },
        monthlyPrice() {
            return { 'amount': this.accommodation.arriendo, 'currency': this.currency };
        },

        // Rental mode and period requested by the visitor
        totalDaysRequested() {
            const startDate = moment(this.$route.query.fecha_inicio);
            const finalDate = moment(this.$route.query.fecha_termino);
            return finalDate.diff(startDate, 'days');
        },
        rentalModeRequested() {
            if (this.totalDaysRequested === 0) {
                return 'any';
            } else {
                return this.totalDaysRequested < 30 ? 'daily' : 'monthly';
            }
        },
        dailyRentalModeRequested() {
            return this.rentalModeRequested === 'daily' || this.rentalModeRequested === 'any';
        },
        monthlyRentalModeRequested() {
            return this.rentalModeRequested === 'monthly' || this.rentalModeRequested === 'any';
        },
    },

    methods: {

        // Rental mode and period requested by the host
        accommodationDailyRentalMode(accommodation) {
            accommodation = accommodation ? accommodation : this.accommodation;
            return accommodation.modalidad_tiempo === 'diario';
        },
        accommodationMonthlyRentalMode(accommodation) {
            accommodation = accommodation ? accommodation : this.accommodation;
            return accommodation.modalidad_tiempo === 'mensual';
        },
        accommodationDualRentalMode(accommodation) {
            accommodation = accommodation ? accommodation : this.accommodation;
            return accommodation.modalidad_tiempo === 'ambos';
        }
    }

};